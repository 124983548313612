import Layout from "../../../Layout";
import Content from "../../../Content";

import React, {useContext, useState} from "react";
import {
    getCompanionAge,
    getCompanionImagename,
    getCompanionName,
    getCompanionVariantName
} from "../../../../helpers";
import {intervalToDuration} from "date-fns";
import CompanionDetail from "./CompanionDetail";
import {CompanionContext} from "../../../../../App/utils/Contexts/CompanionContext";


function getHatchtime(egg){
    var endTime = new Date(egg.hatchdate)
    var t = egg.hatchtime.split(":")
    endTime.setHours(t[0])
    endTime.setMinutes(t[1])
    return intervalToDuration({start: new Date(),end:endTime});
}
function format_time(diff){
   if(diff.days !== undefined){
       return diff.days + " days";
   }
    else if(diff.hours !== undefined){
        return diff.hours + " hours";
    }
   else {
       return (diff.minutes !== undefined ? diff.minutes + " minutes, ":"") + (diff.seconds===undefined?"0":diff.seconds) + " seconds";
   }
}
function getHatchCloseness(egg){
    var time = getHatchtime(egg);
    if(time.days !== undefined){
        return "This doesn't seem close to hatching."
    }
    else if(time.hours !== undefined){
        return "The egg moves occasionally, it may hatch soon."
    }
    else {
        return "Sounds can be heard from the egg, it's almost ready to hatch."
    }
}


export default function Companions(){
    const {companions,eggs} = useContext(CompanionContext)
    const [modalOpen,setModalOpen] = useState(false);
    const [modalCompanion,setModalCompanion] = useState({});
    const closeModal = ()=>{
        setModalOpen(false)
        setModalCompanion({})
    }
    const openModal = (companion) =>{
        setModalCompanion(companion);
        setModalOpen(true)
    }
    return (
        <Layout>
            <Content>
                <div className="column-container p-8 lg:p-10  gap-x-8 gap-y-5 flex flex-wrap flex-col w-full justify-center items-center ">
                    <h2 tabIndex="0" className="focus:outline-none font-semibold leading-normal pt-8 text-text-bg pb-2">Eggs</h2>
                    <div className="egg_container flex flex-1 flex-row gap-x-8 flex-wrap justify-evenly w-full">
                        {eggs.length >0 &&
                            eggs.map((egg) => (
                                <div className="justify-center items-center flex-1 cursor-pointer flex flex-col text-text-bg bg-card-bg p-4  rounded-2xl shadow-md ring-1 ring-inset ring-card-ring overflow-x-hidden mb-5 p-2 min-h-20">
                                    <div className="flex items-center">
                                        <div className="w-24 h-24 rounded-full bg-text-faded flex items-center"  data-tooltip-target={"tooltip-eggID"+egg.id}>
                                            <img src={"/assets/game/companions/"+getCompanionName(egg.species).toLowerCase()+"_egg.png"} alt="scroll" className="h-20 m-auto"/>
                                        </div>
                                        <div id={"tooltip-eggID"+egg.id} role="tooltip"
                                             className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-text-bg transition-opacity duration-300 bg-disabled-bg rounded-lg shadow-sm opacity-0 tooltip">
                                            {getHatchCloseness(egg)}
                                            <div className="tooltip-arrow" data-popper-arrow=""></div>
                                        </div>
                                    </div>
                                    <div className="flex justify-center text-text-bg font-semibold">{getCompanionName(egg.species)} Egg</div>
                                    <div className="flex justify-center text-text-bg">Hatches over {format_time(getHatchtime(egg))}</div>

                                </div>
                            ))
                        }
                        {eggs.length === 0 &&
                            <div className="justify-center items-center flex-1 cursor-pointer flex flex-col text-text-bg bg-card-bg p-4 rounded-2xl shadow-md ring-1 ring-inset ring-card-ring overflow-x-hidden mb-5 p-2 min-h-20">
                                <div className="flex items-center">
                                    <img src="/assets/game/loottable_icons/companion_egg.png" alt="egg_disabled" className="w-14 h-14 m-auto"/>
                                    You don't have any eggs, complete quests for a chance to get them.
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="column-container p-8 lg:p-10  gap-x-8 gap-y-5 flex flex-wrap flex-col w-full justify-center items-center ">
                    <h2 tabIndex="0" className="focus:outline-none font-semibold leading-normal pt-8 text-text-bg pb-2">Companions</h2>
                    <div className="companion_container flex flex-1 flex-row gap-x-8 flex-wrap justify-evenly w-full">
                        {companions.length >0 &&
                            companions.map((companion) => (
                                <div className="justify-center items-center flex-1 cursor-pointer flex flex-col text-text-bg bg-card-bg p-4  rounded-2xl shadow-md ring-1 ring-inset ring-card-ring overflow-x-hidden mb-5 p-2 min-h-20" onClick={()=>openModal(companion)}>
                                    <div className="flex justify-center text-text-bg my-2 font-semibold">{companion.name}</div>
                                    <div className="flex items-center">
                                        <div className="w-24 h-24 rounded-full bg-text-faded flex items-center"  >
                                            <img src={"/assets/game/companions/"+getCompanionImagename(companion)} alt="scroll" className="h-20 m-auto"/>
                                        </div>

                                    </div>
                                    <div className="flex justify-center text-text-bg font-semibold">{getCompanionVariantName(companion.type,companion.variant)} </div>
                                    <div className="flex justify-center text-text-bg text-xs">({getCompanionAge(companion.experience)})</div>

                                </div>
                            ))
                        }
                        {companions.length === 0 &&
                            <div className="justify-center items-center flex-1 cursor-pointer flex flex-col text-text-bg bg-card-bg p-4 rounded-2xl shadow-md ring-1 ring-inset ring-card-ring overflow-x-hidden mb-5 p-2 min-h-20">
                                <div className="flex items-center">
                                    <img src="/assets/game/loottable_icons/companion_egg.png" alt="egg_disabled" className="w-14 h-14 m-auto"/>
                                    You don't have any companions, complete quests for a chance to get eggs.
                                </div>
                            </div>
                        }
                    </div>
                </div>
                 {modalOpen && <CompanionDetail companion={modalCompanion} closeCompanionModal={closeModal} />}
            </Content>
        </Layout>
    )
}


