import {getUserID} from "./ApiConfig";


export async function apiGetCharacter(supabase) {

    const {data: character, error} = await supabase.from('Characters').select('*')
    if(!error){
        if(character.length === 0){
            const id = await getUserID(supabase)
            if (!id) return {}
            const {character, error} = await supabase.from('Characters').insert([{user: id}]).select()
            return character[0]
        }
        return character[0]
    }
    return {
        // TODO: error handling
    }
}

export async function apiUpdateGold(supabase,gold,char){

    const {data:character, error} = await supabase.from('Characters').update({gold: gold}).eq('id',char).select()
    if(!error){
        return character
    }
    return {}
}

export async function apiUpdateGemstones(supabase,gemstones,char){

    const {data:character, error} = await supabase.from('Characters').update({gemstone: gemstones}).eq('id',char).select()
    if(!error){
        return character
    }
    return {}
}

export async function apiAddExp(supabase,newExp,char){

    const {data:character, error} = await supabase.from('Characters').update({experience: newExp}).eq('id',char).select()
    if(!error){
        return character
    }
    return {}
}
