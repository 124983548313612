import {getUserID} from "./ApiConfig";

export async function apiGetNotifications(supabase) {

    const {data: tasks, error} = await supabase.from('Notifications').select('*')
    if(!error){
        return tasks
    }
    return {
        // TODO: error handling
    }
}

export async function apiCreateNotification(supabase,notification) {
    const id = await getUserID(supabase)
    if (!id) return {}

    notification.user = id
    const {res, error} = await supabase.from('Notifications').insert(notification).select()
    if (!error) {
        if(res){
            return res
        }
        return {}
    }
    return {
        // TODO: error handling
    }
}
export async function apiSetReadGame(supabase){

    const {res, error} = await supabase.from('Notifications').update({status:true}).eq('type',"game").select()
    if (!error) {
        return res
    }
    return {}
}
export async function apiSetReadApp(supabase){

    const {res, error} = await supabase.from('Notifications').update({status:true}).neq('type',"game").select()
    if (!error) {
        return res
    }
    return {}
}
export async function apiArchiveNotification(supabase,notification){

    const {res, error} = await supabase.from('Notifications').update({archived:true}).eq('id',notification).select()
    if (!error) {
        return res
    }
    return {}
}
export async function apiDeleteNotification(supabase,notification) {

    const {error} = await supabase.from('Notifications').delete.eq('id', notification.id)
    if (!error) {
        return true
    }
    return {
        // TODO: error handling
    }
}


