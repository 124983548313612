

import AsyncStorage from '@react-native-async-storage/async-storage'
import { createClient } from '@supabase/supabase-js'
import {addHours, isAfter} from "date-fns";

export const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY, {
    auth: {
        storage: AsyncStorage,
        autoRefreshToken: true,
        persistSession: true,
        detectSessionInUrl: false,
    },
})
supabase.auth.onAuthStateChange(async (event, session) => {
    if (session && session.provider_token) {
        window.localStorage.setItem('oauth_provider_token', session.provider_token)
    }
    if (session && session.provider_refresh_token) {
        window.localStorage.setItem('oauth_provider_refresh_token', session.provider_refresh_token)
    }
    if (event === 'INITIAL_SESSION') {
        window.localStorage.setItem('taskz_google_sync', false)
    }
    if (event === 'SIGNED_IN') {
        var previous = window.localStorage.getItem('taskz_google_sync')
        if (isAfter(addHours(previous, 2), new Date())) {
            window.localStorage.setItem('taskz_google_sync', false)
        }
    }
    if (event === 'SIGNED_OUT') {
        window.localStorage.removeItem('oauth_provider_token')
        window.localStorage.removeItem('oauth_provider_refresh_token')
        window.localStorage.removeItem('taskz_google_sync')
    }
})
