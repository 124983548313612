import {setTime} from "../../App/utils/CalendarUtils";
import {format} from "date-fns";

export default function Scheduled_time_fragment({timeformat,time}){
    var timing = new Date()
    let split = time.split(':')
    timing = setTime(timing,split[0],split[1],0)


    return (
        <>
            <div className="flex items-center py-1">
                <div className="time-container">
                    <div className="text-chip-text bg-chip-bg/30 ring-chip-bg rounded-full flex-none py-1 px-2 text-xs font-medium ring-1 ring-inset">
                        {format(timing,timeformat==="HH"?"HH:mm":"HH:mm a")}
                    </div>
                </div>
            </div>
        </>

    )
}
