import {getRandom, getRandomBetween} from "./LoottableScrollsUtils";

export function getLootDescription(pools){
    var items = []
    pools.map(p=> {
        p.pool.map(i=>{
            if(!items.includes(i.item.id)){
                items.push(i.item.id)
            }
        })
    })
    return items

}

export function getWeakness(pools){

    var poolRollFound = false
    var checkStartRoll = 0
    var roll = getRandom(100)

    pools.map((item) => {
        if (roll <= checkStartRoll + item.weight && poolRollFound === false) {
            return item.type
        }
        checkStartRoll += item.weight
    })
    return "completion"
}

export function rollLoottable(loottable,min,max,type){
    var table = loottable
    var result = []

    for(var i = 0; i < min; i++) {
        var rolls = getRandom(100)
        var pool = []
        var poolfound = false
        var checkStart = 0

        table.map((poolgroup) => {
            if (rolls <= (checkStart + poolgroup.weight) && poolfound === false) {
                // this pool
                pool = poolgroup.pool
                poolfound = true
            }
            checkStart += poolgroup.weight
        })
        var poolRoll = {}
        var poolRollFound = false
        var checkStartRoll = 0
        var roll = getRandom(100)
        pool.map((item) => {
            if (roll <= checkStartRoll + item.weight && poolRollFound === false) {
                poolRoll = item.item
                poolRollFound = true
            }
            checkStartRoll += item.weight
        })
        var amount = getRandomBetween(poolRoll.count.min,poolRoll.count.max)
        result.push({item:poolRoll,amount:amount})
    }
    if(max>min){
        for(var i = min-1; i<max;i++){
            var coinflip = getRandom(100)
            if(coinflip >50){
                var rolls = getRandom(100)
                var pool = []
                var poolfound = false
                var checkStart = 0

                table.map((poolgroup) => {
                    if (rolls <= checkStart + poolgroup.weight && poolfound === false) {
                        // this pool
                        pool = poolgroup.pool
                        poolfound = true
                    }
                    checkStart += poolgroup.weight
                })
                var poolRoll = {}
                var poolRollFound = false
                var checkStartRoll = 0
                var roll = getRandom(100)
                pool.map((item) => {
                    if (roll <= checkStartRoll + item.weight && poolRollFound === false) {
                        poolRoll = item.item
                        poolRollFound = true
                    }
                    checkStartRoll += item.weight
                })
                var amount = getRandomBetween(poolRoll.count.min,poolRoll.count.max)
                result.push({item:poolRoll,amount:amount})
            }
        }
    }

    return result
}
export function rollLoottableCompanion(loottable,type){
    var table = loottable

    var rolls = getRandom(100)
    var pool = []
    var poolfound = false
    var checkStart = 0

    var companion = ""

    table.map((poolgroup) => {
        if (rolls <= (checkStart + poolgroup.weight) && poolfound === false) {
            // this pool
            if(poolgroup.pool === undefined){
               companion = poolgroup.companion
                poolfound = true
            }
            else{
                pool = poolgroup.pool
                poolfound = true
            }

        }
        checkStart += poolgroup.weight
    })
    if(companion === ""){
        var poolRollFound = false
        var checkStartRoll = 0
        var roll = getRandom(100)
        pool.map((item) => {
            if (roll <= checkStartRoll + item.weight && poolRollFound === false) {
                companion = item.companion
            }
            checkStartRoll += item.weight
        })
    }

    return companion
}

export function decideVariant(loottable){
    var rolls = getRandom(100)
    var variantName = ""

    var found =false
    var checkStart = 0

    console.log(rolls)
    loottable.map((variant) => {

        if (rolls <= (checkStart + variant.weight) && found === false) {
            console.log(variant)
            variantName = variant.name
            found = true
        }
        checkStart += variant.weight

    })
    return variantName
}



export function sortLoot(loot){
   var list = []
    loot.map(item=> {
        var id = item.item.id
        var types = id.split(":")
        if(types[0] === "currency" || types[0] === "mob_drops") {
            var finder = list.find((stackable) => stackable.item.id === id)
            if(finder){
                list.find((stackable) => stackable.item.id === id).amount += item.amount
            }
            else{
                var newitem = {item:item.item,amount:item.amount}
                list.push(newitem)
            }
        }
        else{
            list.push(item)
        }
    })
    return list
}

