

import {
  addDays,
  subDays,
  addMonths,

  subHours,isSameDay,
} from "date-fns";
import {companion_dummylist, mob_dummylist} from "./Constants";

export function calcCurrentLevel(exp){
  // (level/0.07)^2
  return Math.floor(0.07 *(Math.sqrt(exp)))
}
export function calcExpNeeded(level){
  return Math.floor(Math.pow(((level)/0.07),2));
}

export function calcExpCurrent(exp){
  const level = calcCurrentLevel(exp);
  // const expNeededTotal = calcExpNeeded(level+1);
  const expNeededCurrent = calcExpNeeded(level);
  return  exp - expNeededCurrent;

}

export function calcExpNeededCurrent(exp){
  const level = calcCurrentLevel(exp);
  const expNeededTotal = calcExpNeeded(level+1);
  const expNeededCurrent = calcExpNeeded(level);
  return  expNeededTotal - expNeededCurrent;
}

/*
Date manipulation and calendar calculation
 */


export function getEstimateHours(est){
  if(!est){
    return 0
  }
  let split = est.split(':')
  return Number(split[0])
}
export function getEstimateMinutes(est){
  if(!est){
    return 0
  }
  let split = est.split(':')
  return Number(split[1])
}

export function compareDates(date1, date2) {
  return isSameDay(date1,date2)
}

export function getMobname(machine_name){
  var mob = mob_dummylist.find((mob) => mob.machine_name === machine_name);
  if(!mob ) return ""
  return  mob.mob_name;
}

export function getCompanionName(machine_name){
  var companion = companion_dummylist.find((companion) => companion.machine_name === machine_name);
  return companion.name;
}

export function getCompanionVariantName(machine_name,variant_name){
  var companion = companion_dummylist.find((c) => c.machine_name === machine_name);
  var variant = companion.companion_variants.find((variant) => variant.name === variant_name);
  return variant.description
}
export function getCompanionAge(companion_exp){
  return "Baby" // todo actual age based on exp
}

export function getCompanionImagename(comp){
 var name = getCompanionName(comp.type)
  var variant = companion_dummylist.find((c) => c.machine_name === comp.type)
      .companion_variants.find((variant) => variant.name === comp.variant);
  return name.toLowerCase() +"_"+ variant.machine_name + "_baby" + ".png"
}



export function getDays(m, monday_firstday) {
  // m is now 10th of the month, in a date object
  var first_of_month = new Date(m.getFullYear(), m.getMonth(), 1);
  var nextmonth = addMonths(first_of_month, 1);
  var firstDayOfNextMonth = new Date(nextmonth.setDate(1));
  var lastDayOfThisMonth = subDays(firstDayOfNextMonth, 1);

  var days = [];
  var days_month_before = getDaysPreviousMonth(first_of_month, monday_firstday);
  days.push(...days_month_before);
  var days_current_month = getDaysCurrentMonth(lastDayOfThisMonth);
  days.push(...days_current_month);
  var days_month_after = getDaysNextMonth(lastDayOfThisMonth, monday_firstday);
  days.push(...days_month_after);

  return days;
}
export function getDaysPreviousMonth(date, monday_firstday) {
  var weekday = date.getDay();

  var prevMonth = subHours(date, 1);

  if (weekday === 0) {
    weekday = 7;
  }
  var monthdays = [];
  var num_days_to_show = weekday - monday_firstday;
  var num_countdown = num_days_to_show;
  while (num_days_to_show !== 7 && num_countdown !== 0) {
    num_countdown = num_countdown - 1;
    var tempdate = subDays(prevMonth, num_countdown);
    var day = { date: tempdate, isCurrentMonth: false, events: [] };

    monthdays.push(day);
  }
  return monthdays;
}
export function getDaysNextMonth(date, monday_firstday) {
  var nextMonth = addDays(date, 1);
  if (monday_firstday === 1) {
    monday_firstday = 0;
  } else if (monday_firstday === 0) {
    monday_firstday = 1;
  }
  var weekday = date.getDay();
  var monthdays = [];
  var num_days_to_show = 7 - weekday - monday_firstday;
  var num_counter = 1;
  while (
    num_days_to_show !== 7 &&
    num_days_to_show !== 0 &&
    num_counter <= num_days_to_show
  ) {
    var tempdate = new Date(
      nextMonth.getFullYear(),
      nextMonth.getMonth(),
      num_counter,
    );
    var day = { date: tempdate, isCurrentMonth: false, events: [] };

    monthdays.push(day);
    num_counter = num_counter + 1;
  }

  return monthdays;
}
export function getDaysCurrentMonth(last_of_month) {
  var today = new Date();
  var last_day = last_of_month.getDate();
  var daycounter = 1;
  var monthdays = [];
  while (daycounter <= last_day) {
    var tempdate = new Date(
      last_of_month.getFullYear(),
      last_of_month.getMonth(),
      daycounter,
    );
    if (compareDates(today, tempdate)) {
      var day = {
        date: tempdate,
        isCurrentMonth: true,
        isToday: true,
        events: [],
      };
    } else {
      var day = { date: tempdate, isCurrentMonth: true, events: [] };
    }

    monthdays.push(day);
    daycounter = daycounter + 1;
  }
  return monthdays;
}

/*
Temporary date manipulation function to simulate events
 */

/*
 utility functions
 */

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function joinClasses(...classes) {
  return classes.join(" ");
}
export function joinUrl(...urls) {
  return urls.join("");
}
