import React, {Fragment, useContext, useEffect, useState} from 'react'
import {Dialog, Menu, Transition} from '@headlessui/react'
import {LuCheckCircle, LuClock, LuMoreHorizontal} from "react-icons/lu";
import {classNames, getEstimateHours, getEstimateMinutes, getMobname} from "../../../../helpers";
import { TbExclamationMark } from "react-icons/tb";
import { LuAlarmClock } from "react-icons/lu";
import {format, isDate} from "date-fns";
import {toast} from "react-toastify";
import {useSession, useSupabaseClient} from "@supabase/auth-helpers-react";
import {checkToken, deletegCalEvent} from "../../../../../App/utils/CalendarUtils";
import {updateEventFromTask} from "../../../../../App/utils/SyncUtils";
import {apiDeleteTask, apiUpdateTask} from "../../../../../App/api/tasksApi";
import {battleVictory, calcDamage} from "../../../../../App/utils/CombatUtils";
import {apiEndFight, apiUpdateMobHealth} from "../../../../../App/api/Quest/QuestApi";
import {CombatContext} from "../../../../../App/utils/Contexts/CombatContext";
import {QuestContext} from "../../../../../App/utils/Contexts/QuestContext";
import {toastOptions} from "../../../../Constants";
import {LevelContext} from "../../../../../App/utils/Contexts/LevelContext";
import {NotificationContext} from "../../../../../App/utils/Contexts/NotificationContext";


export default function TaskDetail({task,refe,closeTaskModal}){
    const {combat,hasActiveCombat,character,setUpdated,updated} = useContext(CombatContext)
    const session = useSession()
    const focusActual = (refe === "actual" && (task.timeEstimate !== null && task.timeEstimate !== "00:00:00"))
    const focusEstimate = (refe === "actual" && !(task.timeEstimate !== null && task.timeEstimate !== "00:00:00"))
    const [title, setTitle] = useState(task.title)
    const [description, setDescription] = useState(task.description)
    const [due, setDue] = useState(task.due)
    const [scheduled, setScheduled] = useState(task.scheduled)
    const [scheduledTime, setScheduledTime] = useState(task.scheduled_time)
    const [done,setDone] = useState(task.done);
    const [important,setImportant] = useState(task.important);
    const [urgent,setUrgent] = useState(task.urgent);
    const [category, setCategory] = useState(task.category)
    const [hoursEst,setHoursEst] = useState(getEstimateHours(task.timeEstimate));
    const [minutesEst,setMinutesEst] = useState(getEstimateMinutes(task.timeEstimate))
    const [hoursAc,setHoursAc] = useState(getEstimateHours(task.timeActual))
    const [minutesAc,setMinutesAc] = useState(getEstimateMinutes(task.timeActual))
    const supabase = useSupabaseClient()
    const {updateQuests} = useContext(QuestContext)
    const {addExp} = useContext(LevelContext)
    const {addGameNotification} = useContext(NotificationContext)

    async function validate(){
        var continueing = true;

        if(task.title === ''){
            toast('Title cannot be empty')
            continueing = false;
        }
        if (due !== null && isDate(new Date(due)) === false) {
            toast("Due date is not valid")
            continueing = false
        }
        if (scheduled !== null && isDate(new Date(scheduled)) === false) {
            toast("Scheduled date is not valid")
            continueing = false
        }
        let est = format(new Date(0, 0, 0, hoursEst, minutesEst, 0), 'HH:mm')
        let act = format(new Date(0, 0, 0, hoursAc, minutesAc, 0), 'HH:mm')

        var checkBattle = false
        if(continueing){

            var checkGoogleUp = false
            if(task.title !== title){
                checkGoogleUp = true
            }
            if(task.description !== description){
                checkGoogleUp = true
            }
            if(task.scheduled !== scheduled){
                checkGoogleUp = true
            }
            if(task.scheduled_time !== scheduledTime){
                checkGoogleUp = true
            }
            if(task.timeEstimate !== est){
                checkGoogleUp = true
            }
            if(task.done !== done && done){
                checkBattle = true
                if(!task.completion_exp_given){
                    await addExp(10)
                    task.completion_exp_given = true
                }
            }
            if(hasActiveCombat && checkBattle && !task.combat_damage_dealt){
                if(combat.weakness === "completion"){
                    var damage = calcDamage({strength:character.strength,wisdom:character.wisdom,intelligence:character.intelligence})
                    var cHealth = combat.current_health - damage
                    if(cHealth <= 0){
                        cHealth = 0
                        var looti = await battleVictory(supabase,combat,character)
                        updateQuests("kill",combat.mob_name)
                        toast.success("You've defeated the "+combat.mob_name,toastOptions)
                        var items = []
                        looti.map((item)=>{
                            items.push({item:item.item.id,amount:item.amount})
                        })

                        var notification = {
                            title: "Battle won!",
                            content: "You've won the battle against the "+getMobname(combat.mob_name)+ "and received the following rewards",
                            linked_items: JSON.stringify(items),
                            icon: "combat"
                        }
                        addGameNotification(notification)
                        await apiEndFight(supabase,combat.id)
                        await addExp(20)

                    }
                    else{
                        await apiUpdateMobHealth(supabase,combat.id,cHealth)
                        task.combat_damage_dealt = true
                    }
                    setUpdated(!updated)
                }
            }

            task.due = due;
            task.scheduled= scheduled;
            task.scheduled_time = scheduledTime
            task.timeEstimate = est;
            task.timeActual = act;
            task.done = done;
            task.important = important;
            task.urgent = urgent;
            task.title = title;
            task.description = description;
            task.category = category;
            await apiUpdateTask(supabase,task)


            if(task.google_calendar_id !== null && checkGoogleUp){
                await checkToken(session)
                var token = session.provider_token
                if (session.provider_token){
                    updateEventFromTask(task,token)
                }
            }
            closeTaskModal(true,false)
        }
    }
    function handleOnChangeEst(value){
        if(Number.isNaN(value) || value<0) setMinutesEst(0)
        else{
            const newhours = Number(hoursEst) + Math.floor(value/60);
            const newminutes = value - (Math.floor(value/60) * 60);
            setHoursEst(newhours);
            setMinutesEst(newminutes);
        }
    }
    function handleOnChangeHourEst(value){
        if(Number.isNaN(value) || value<0) setHoursEst(0);
        else{
            setHoursEst(value);
        }
    }
    function handleOnChangeAc(value){
        if(Number.isNaN(value) || value<0) setMinutesAc(0)
        else{
            const newhours = Number(hoursAc) + Math.floor(value/60);
            const newminutes = value - (Math.floor(value/60) * 60);
            setHoursAc(newhours);
            setMinutesAc(newminutes);
        }
    }
    function handleOnChangeHourAc(value){
        if(Number.isNaN(value) || value<0) setHoursAc(0)
        else{
            setHoursAc(value);
        }
    }
    //TODO toggle done with api call dispatch thingie
     async function deleteTask() {
         if (task.google_calendar_id !== null) {
             await checkToken(session)
             var token = session.provider_token
             if (session.provider_token) {
                 deletegCalEvent('primary',task.google_calendar_id, token)
             }
         }
            await apiDeleteTask(supabase,task)
         closeTaskModal(false, true)
     }
    useEffect(() => {
        if(focusActual){
            document.getElementById('hoursAc').focus()
        }
        else if(focusEstimate){
            document.getElementById('hoursEst').focus()
        }
        else{
            document.getElementById('title').focus()
        }
    }, []);

     return (
         <>
             <Transition.Root show={true} as={Fragment}>
                 <Dialog as="div" className="relative z-10 " onClose={()=>closeTaskModal(false,false)}>
                     <Transition.Child
                         as={Fragment}
                         enter="ease-out duration-300"
                         enterFrom="opacity-0"
                         enterTo="opacity-100"
                         leave="ease-in duration-200"
                         leaveFrom="opacity-100"
                         leaveTo="opacity-0"
                     >
                         <div className="fixed inset-0 bg-dark-grey bg-opacity-75 transition-opacity" />
                     </Transition.Child>

                     <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                         <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                             <Transition.Child
                                 as={Fragment}
                                 enter="ease-out duration-300"
                                 enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                 enterTo="opacity-100 translate-y-0 sm:scale-100"
                                 leave="ease-in duration-200"
                                 leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                 leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                             >
                                 <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-card-bg px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                     <div>
                                         <Menu as="div" className="relative flex flex-1 justify-end outline-none">
                                             <Menu.Button className="-m-1.5 flex items-center p-1.5 outline-none">
                                                 <span className="sr-only">Open task menu</span>
                                                  <LuMoreHorizontal
                                                      className="ml-2 h-5 w-5 text-text-bg hover:text-chip-accent"
                                                      aria-hidden="true"
                                                  />
                                             </Menu.Button>
                                             <Transition
                                                 as={Fragment}
                                                 enter="transition ease-out duration-100"
                                                 enterFrom="transform opacity-0 scale-95"
                                                 enterTo="transform opacity-100 scale-100"
                                                 leave="transition ease-in duration-75"
                                                 leaveFrom="transform opacity-100 scale-100"
                                                 leaveTo="transform opacity-0 scale-95"
                                             >
                                                 <Menu.Items className="absolute right-0 z-10 mt-6 w-32 origin-top-right rounded-md bg-modal-menu-bg  py-2 shadow-lg ring-1 ring-text-bg/5 focus:outline-none">
                                                     <Menu.Item key="delete_task">

                                                             <button className="block px-3 py-1 text-sm leading-6 text-text-bg hover:text-color-danger"
                                                                     onClick={() =>
                                                                     { if (window.confirm('Are you sure you wish to delete this item?'))
                                                                         deleteTask() } }
                                                                     //TODO actual deletion of task
                                                             >
                                                                 Delete task
                                                             </button>

                                                     </Menu.Item>
                                                 </Menu.Items>
                                             </Transition>
                                         </Menu>
                                         <div className="mt-3 text-center sm:mt-5">
                                             <div className=" text-text-bg  font-semibold dialog-title items-center flex justify-between ">
                                                 <div className="mt-2 flex flex-1 ">
                                                     <div className="mt-2 flex flex-1 items-center justify-evenly">
                                                         <input
                                                             id="title"
                                                             name="title"
                                                             type="text"
                                                             placeholder="Task"
                                                             onChange={(e) => setTitle(e.target.value)}
                                                             value={title}
                                                             required
                                                             className="flex-1 mr-10 block rounded-md border-0 py-1.5 text-text-important shadow-sm ring-1 bg-input-bg ring-inset ring-card-ring placeholder:text-text-bg/50 focus:ring-inset focus:ring-chip-bg sm:text-sm sm:leading-6"
                                                         />
                                                         <div className="flex items-center justify-center">
                                                             <LuClock
                                                                 className="h-6 w-6 shrink-0 text-text-bg"
                                                                 aria-hidden="true"
                                                             />
                                                             <div>
                                                                 <div className="flex">
                                                                     <input
                                                                         id="hoursAc"
                                                                         name="hoursAc"
                                                                         type="text"
                                                                         value={hoursAc}

                                                                         onChange={(e) =>handleOnChangeHourAc(e.target.value)}
                                                                         className="block w-6 text-center px-0 py-1 bg-modal-bg placeholder:text-text-bg/50 border-0 focus:ring-0 text-sm"
                                                                     />
                                                                     <span className="">:</span>
                                                                     <input
                                                                         id="minutesAc"
                                                                         name="minutesAc"
                                                                         type="text"
                                                                         onChange={(e) =>handleOnChangeAc(e.target.value)}
                                                                         value={minutesAc}
                                                                         className="block w-6 text-center px-0 py-1 bg-modal-bg placeholder:text-text-bg/50 border-0 focus:ring-0 text-sm"
                                                                     />
                                                                 </div>
                                                                 <span className="leading-3 text-xs font-medium ">actual</span>
                                                             </div>

                                                             <div>
                                                                 <div className="flex">
                                                                     <input
                                                                         id="hoursEst"
                                                                         name="hoursEst"
                                                                         type="text"
                                                                         value={hoursEst}
                                                                         onChange={(e) =>handleOnChangeHourEst(e.target.value)}
                                                                         className="block w-6 text-center px-0 py-1 bg-modal-bg placeholder:text-text-bg/50 border-0 focus:ring-0 text-sm"
                                                                     />
                                                                     <span className="">:</span>
                                                                     <input
                                                                         id="minutesEst"
                                                                         name="minutesEst"
                                                                         type="text"
                                                                         onChange={(e) =>handleOnChangeEst(e.target.value)}
                                                                         value={minutesEst}
                                                                         className="block w-6 text-center px-0 py-1 bg-modal-bg placeholder:text-text-bg/50 border-0 focus:ring-0 text-sm"
                                                                     />
                                                                 </div>
                                                                 <span className="leading-3 text-xs font-medium ">estimate</span>
                                                             </div>

                                                         </div>
                                                     </div>
                                                 </div>
                                             </div>
                                             <div className="mt-4">
                                                 <div className="mt-2 flex flex-1 items-center justify-between">
                                                     <div className="flex min-w-40 items-center justify-between">
                                                         <div className="flex  items-center cursor-pointer" onClick={()=>setDone(!done)}>
                                                             <LuCheckCircle
                                                                 className={classNames(done ? "text-icon-task-done" : "text-text-bg", "h-5 w-5 shrink-0")}
                                                                 aria-hidden="true"
                                                             />
                                                         </div>
                                                         <div className="flex  items-center cursor-pointer" onClick={()=>setImportant(!important)}>
                                                             <TbExclamationMark
                                                                 className={classNames(important ? "text-color-danger" : "text-text-bg", "h-5 w-5 shrink-0")}
                                                                 aria-hidden="true"
                                                             />
                                                         </div>
                                                         <div className="flex items-center cursor-pointer" onClick={()=>setUrgent(!urgent)}>
                                                             <LuAlarmClock
                                                                 className={classNames(urgent ? "text-color-danger" : "text-text-bg", "h-5 w-5 shrink-0")}
                                                                 aria-hidden="true"
                                                             />
                                                         </div>

                                                     </div>
                                                     <div className="w-36 py-2 flex items-start justify-start">
                                                         <span className="text-sm font-normal px-0 mx-0 w-20 flex-1 items-start justify-start">category</span>
                                                     </div>

                                                 </div>
                                             </div>
                                             <div className="mt-2">
                                                 <input
                                                     id="description"
                                                     name="description"
                                                     type="text"
                                                     autoComplete="description"
                                                     placeholder="description"
                                                     onChange={(e) => setDescription(e.target.value)}
                                                     value={description}
                                                     className="block w-full rounded-md border-0 py-1.5 text-text-important shadow-sm ring-1 bg-input-bg ring-inset ring-card-ring placeholder:text-text-bg/50 focus:ring-inset focus:ring-chip-bg sm:text-sm sm:leading-6"
                                                 />
                                             </div>
                                         </div>
                                     </div>
                                     <div className="mt-2 flex flex-1 justify-evenly items-center">
                                         <div className="flex  items-center cursor-pointer" >
                                             <span>Due</span>
                                                 <input
                                                     id="due"
                                                     name="due"
                                                     type="date"
                                                     placeholder={due | "due?"}
                                                     onChange={(e) => setDue(e.target.value)}
                                                     value={due}
                                                     className="items-center block rounded-md border-0 py-1.5 shadow-sm ring-1 bg-input-bg ring-inset ring-card-ring placeholder:text-text-bg/50 focus:ring-inset focus:ring-chip-bg sm:text-sm sm:leading-6"
                                                 />

                                         </div>
                                         <div className="flex  items-center cursor-pointer " >
                                             <span>Scheduled</span>
                                                 <input
                                                     id="scheduled"
                                                     name="scheduled"
                                                     type="date"
                                                     placeholder={scheduled | "scheduled?"}
                                                     value={scheduled}
                                                     onChange={(e) => setScheduled(e.target.value)}
                                                     className="items-center block rounded-md border-0 py-1.5 shadow-sm ring-1 bg-input-bg ring-inset ring-card-ring placeholder:text-text-bg/50 focus:ring-inset focus:ring-chip-bg sm:text-sm sm:leading-6"
                                                 />
                                             <input
                                                 id="scheduled_time"
                                                 name="scheduled_time"
                                                 type="time"
                                                 placeholder={scheduledTime | "scheduled_time?"}
                                                 value={scheduledTime}
                                                 onChange={(e) => setScheduledTime(e.target.value)}
                                                 className="items-center block rounded-md border-0 py-1.5 shadow-sm ring-1 bg-input-bg ring-inset ring-card-ring placeholder:text-text-bg/50 focus:ring-inset focus:ring-chip-bg sm:text-sm sm:leading-6"
                                                 />
                                         </div>
                                     </div>
                                     <div className="mt-4 flex flex-1 justify-center items-center">
                                         <div>
                                             <button onClick={()=>validate()}
                                                     className="block flex-1 px-3 py-1.5 text-m font-semibold leading-6 bg-chip-bg text-chip-text hover:bg-chip-accent rounded-lg outline-none shadow-md hover:text-chip-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-chip-accent">
                                                 Save changes
                                             </button>
                                         </div>

                                     </div>
                                     <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">

                                     </div>
                                 </Dialog.Panel>
                             </Transition.Child>
                         </div>
                     </div>
                 </Dialog>
             </Transition.Root>
         </>
    )
 }
