import {getUserID} from "../ApiConfig";


export async function apiGetScrollRotation(supabase) {
    const id = await getUserID(supabase)
    if (!id) return {}

    const {data: rotation, error} = await supabase.from('Guild_quest_rotation').select('*').eq('user', id)
    if(!error){
        return rotation
    }
    return {
        // TODO: error handling
    }
}

export async function apiGetRotationLoottable(supabase,userlevel){

    const {data:loottable,error} = await supabase.from('Loottable_guild_rotation').select('*').lte('level_min',userlevel).gte('level_max',userlevel)
    if(!error){
        return loottable
    }
    return []
}

export async function apiGetQuests(supabase, ids){

    const {data:quests,error} = await supabase.from('Quests').select('*').in('id',ids)
    if(!error){
        return quests
    }
    return []
}

export async function apiCreateRotation(supabase,rotation){
    const id = await getUserID(supabase)
    if (!id) return {}

    rotation.user = id
    rotation.has_chosen = false
    const {data:roti, error} = await supabase.from('Guild_quest_rotation').insert(rotation).select()
    if(!error){
        return roti
    }
    return {}
}

export async function apiUpdateRotation(supabase,rotation){
    const id = await getUserID(supabase)
    if (!id) return {}

    rotation.has_chosen = false
    const {data:roti, error} = await supabase.from('Guild_quest_rotation').update(rotation).eq('user',id).select()
    if(!error){
        return roti
    }
    return {}
}

export async function apiChoosteRotationScroll(supabase){
    const id = await getUserID(supabase)
    if (!id) return {}

    const {data:roti, error} = await supabase.from('Guild_quest_rotation').update({has_chosen:true}).eq('user',id).select()
    if(!error){
        return roti
    }
    return {}
}
