
import {format, subDays} from "date-fns";
import {getUserID} from "./ApiConfig";

export async function apiGetTasks(supabase) {

    const {data: tasks, error} = await supabase.from('Tasks').select('*')
    if(!error){
        return tasks
    }
    return {
        // TODO: error handling
    }
}
export async function apiGetTasksByDay(supabase,date) {

    const {data: tasks, error} = await supabase.from('Tasks').select('*').eq('scheduled',format(date,'yyyy-MM-dd'))
    if(!error){
        return tasks
    }
    return []
    // todo error handling
}
export async function apiGetTasksBetween(supabase,dateStart,dateEnd) {

    const {data: tasks, error} = await supabase.from('Tasks').select('*')
        .gte('scheduled',format(dateStart,'yyyy-MM-dd'))
        .lte('scheduled',format(dateEnd,'yyyy-MM-dd'))
    if(!error){
        return tasks
    }
    return []
    // todo error handling
}
export async function apiGetActiveGoogleTasks(supabase){

    const {data: tasks, error} = await supabase.from('Tasks').select('*').not('google_calendar_id','is',null).eq('done',false)
    if(!error){
        return tasks
    }
    return []
    // todo error handling
}
export async function apiGetTask(supabase,task) {

    const {data: res, error} = await supabase.from('Tasks').select('*').eq('id',task)
    if (!error) {
        return res[0]
    }
    return {
        // TODO: error handling
    }
}
export async function apiCreateTask(supabase,task) {

    const {data:res, error} = await supabase.from('Tasks').insert(task).select()
    if (!error) {
        return res[0]
    }
    else{
        return error;
    }
    return {
        // TODO: error handling

    }
}
export async function apiUpdateTask(supabase,task) {

    const {res, error} = await supabase.from('Tasks').update(task).eq('id',task.id).select('*')
    if (!error) {
        if(res){
            return res[0]
        }
    }
    return {
        // TODO: error handling
    }
}
export async function apiUpdateTaskScheduled(supabase,task,date) {

    const {res, error} = await supabase.from('Tasks').update({scheduled:format(date,'yyyy-MM-dd')}).eq('id',task).select('*')
    if (!error && res) {
        return true;
    }
    return {
        // TODO: error handling
    }
}
export async function apiMassUpdateScheduled(supabase,tasks,date=new Date()) {
    const {res, error} = await supabase.from('Tasks').update({scheduled:format(date,'yyyy-MM-dd')}).in('id',tasks).select('*')
    if (!error && res) {
        return true;
    }
    return {
        // TODO: error handling
    }
}
export async function apiUpdateTaskToBacklog(supabase,task) {
    const {res, error} = await supabase.from('Tasks').update({scheduled:null,google_calendar_id:null,google_scheduled_time:null,scheduled_time:null,rollover:0}).eq('id',task).select('*')
    if (!error && res) {

        return true;
    }
    return {
        // TODO: error handling
    }
}
export async function apiUpdateTaskGID(supabase,task,date,scheduled,gid){

    const {res, error} = await supabase.from('Tasks').update({google_calendar_id:gid,scheduled:format(date,'yyyy-MM-dd'),google_scheduled_time:format(scheduled,'HH:mm:ss'),scheduled_time:format(scheduled,'HH:mm:ss')}).eq('id',task).select('*')
    if (!error && res) {
        return true;
    }
    return {
        // TODO: error handling
    }
}
export async function apiUpdateStatusTask(supabase,task) {
    const {res, error} = await supabase.from('Tasks').update({done:task.done,combat_damage_dealt:task.combat_damage_dealt}).eq('id',task.id).select('*')

    if (!error && res) {
        return res[0]
    }
    return {
        // TODO: error handling
    }
}
export async function apiUpdateImportantTask(supabase,task) {
    const {res, error} = await supabase.from('Tasks').update({important:task.important}).eq('id',task.id).select('*')
    if (!error && res) {
        return res[0]
    }
    return {
        // TODO: error handling
    }
}
export async function apiUpdateUrgentTask(supabase,task) {
    const {res, error} = await supabase.from('Tasks').update({urgent:task.urgent}).eq('id',task.id).select('*')
    if (!error && res) {
        return res[0]
    }
    return {
        // TODO: error handling
    }
}
export async function apiDeleteTask(supabase,task) {
    const {error} = await supabase.from('Tasks').delete().eq('id', task.id)
    if (!error) {
        return true
    }
    return {
        // TODO: error handling
    }
}
export async function apiMassDeleteTask(supabase,tasks) {

    const {error} = await supabase.from('Tasks').delete().in('id', tasks)
    if (!error) {
        return true
    }
    return {
        // TODO: error handling
    }
}

export async function apiRemoveGoogle(supabase,taskid){

    const {res, error} = await supabase.from('Tasks').update({google_calendar_id:null,google_scheduled_time:null,scheduled_time:null}).eq('id',taskid).select('*')
    if (!error) {
        if(res){
            return res[0]
        }
    }
    return {
        // TODO: error handling
    }
}

export async function apiGetBacklogTasks(supabase){

    const {data: tasks, error} = await supabase.from('Tasks').select('*').is('scheduled',null).eq('done',false)
    if(!error){
        return tasks
    }
    return []
    // todo error handling
}
export async function apiGetTimeboxedBetween(supabase,dateStart,dateEnd){

    const {data: tasks, error} = await supabase.from('Tasks').select('*').not('scheduled_time','is',null).is('done',false).is('google_calendar_id',null)
        .gte('scheduled',format(dateStart,'yyyy-MM-dd'))
        .lte('scheduled',format(dateEnd,'yyyy-MM-dd'))
    if(!error){
        return tasks
    }
    return []
    // todo error handling
}
export async function apiGetTimeboxedOn(supabase,date){

    const {data: tasks, error} = await supabase.from('Tasks').select('*').not('scheduled_time','is',null).neq('scheduled_time',"00:00:00").is('done',false).is('google_calendar_id',null).eq('scheduled',format(date,'yyyy-MM-dd'))
    if(!error){
        return tasks
    }
    return []
    // todo error handling
}
export async function apiUpdateTaskDailycalDrag(supabase,taskid,date){

    const {res, error} = await supabase.from('Tasks').update({scheduled:format(date,'yyyy-MM-dd'),scheduled_time:format(date,'HH:mm:ss')}).eq('id',taskid).select()
    if (!error) {
        if(!res){
           var task = await apiGetTask(supabase,taskid)
           return task
        }
    }
    return {}
}
export async function apiUpdateTaskDailycalResize(supabase,taskid,date,duration){

    const {res, error} = await supabase.from('Tasks').update({scheduled:format(date,'yyyy-MM-dd'),scheduled_time:format(date,'HH:mm:ss'),timeEstimate:duration}).eq('id',taskid).select('*')
    if (!error) {
        if(!res){
            var task = await apiGetTask(supabase,taskid)
            return task
        }
    }
    return {}
}
export async function apiUpdateTaskMonthcalDrag(supabase,taskid,date){

    const {res, error} = await supabase.from('Tasks').update({scheduled:format(date,'yyyy-MM-dd'),scheduled_time:format(date,'HH:mm:ss')}).eq('id',taskid).select('*')
    if (!error) {
        if(res){
            return res[0]
        }
    }
    return {}
}

export async function apiGetTasksToRollover(supabase){

    var date = subDays(new Date(),1)
    const {data:tasks, error} = await supabase.from('Tasks').select('*').is('scheduled',format(date,'yyyy-MM-dd')).eq('done',false)
    if(!error){
        return tasks
    }
    return []
}
export async function apiRollOverAllTasks(supabase){

    var yesterday = subDays(new Date(),1)
    var date = new Date()
    const {res, error} = await supabase.from('Tasks').update({scheduled:format(date,'yyyy-MM-dd'),scheduled_time:"00:00:00"}).eq('scheduled',format(yesterday,'yyyy-MM-dd')).eq('done',false).select('*')
    if(!error){
        return res
    }
    return []
}
export async function apiTaskRollover(supabase,taskid, rollovercount){

    var date = new Date()
    const {res, error} = await supabase.from('Tasks').update({scheduled:format(date,'yyyy-MM-dd'),scheduled_time:"00:00:00",rollover:rollovercount}).eq('id',taskid).select('*')
    if(!error){
        return res
    }
    return []
}
