import {useSupabaseClient} from "@supabase/auth-helpers-react";
import React, {useEffect, useState} from "react";
import {apiGetPlan, apiInsertPlan, apiUpdateReview} from "../../../../../App/api/Plan/DailyplanApi";
import {isArray} from "@craco/craco/dist/lib/utils";
import Content from "../../../Content";
import WorkloadTimeline from "../Components/WorkloadTimeline";
import Layout from "../../../Layout";
import {toast} from "react-toastify";
import {toastOptions} from "../../../../Constants";
import DailyGoal from "../Components/DailyGoal";
import DailyReviewnotes from "../Components/DailyReviewnotes";
import {apiGetTasksByDay} from "../../../../../App/api/tasksApi";
import {format, isSameDay} from "date-fns";
import {LuPlus} from "react-icons/lu";
import Task from "../../Main_page/Tasks view/Task";
import TaskDetail from "../../Main_page/Tasks view/TaskDetail";
import TaskCreate from "../../Main_page/Tasks view/TaskCreate";
import {useNavigate} from "react-router-dom";

export default function Dailyreview() {
    const supabase =useSupabaseClient()
    const [date, setDate] = useState(new Date())
    const [plan,setPlan] = useState({date:date})
    const [isLoaded,setIsLoaded] = useState(false)
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTask, setModalTask] = useState({});
    const [modalDay, setModalDay] = useState(null);
    const [createTaskModalOpen, setCreateTaskModalOpen] = useState(false);
    const [updateMade, setUpdateMade] = useState(false)
    const [tasks,setTasks] = useState([]);
    const [actualTime,setActualTime] = useState(0)
    const [planExists,setPlanExists] = useState(false)
    const navigate = useNavigate();

    function checkSnoozeOption(){
        if(window.localStorage.getItem('lastReview') !== null && isSameDay(new Date(window.localStorage.getItem('lastReview')),new Date())){
            return false
        }
        return true
    }
    function setSnooze(){
        window.localStorage.setItem('lastReview',format(new Date(),'yyyy-MM-dd'))
        navigate('/plan?reviewsnoozed')
    }
    function setReviewnotes(notes){
        plan.review_notes = notes
    }
    async function onSaveReview(){
        plan.time_completed = actualTime
        if(planExists){
            await apiUpdateReview(supabase,plan)
        }
        else{
            await apiInsertPlan(supabase,plan)
        }
        navigate('/plan?dayreviewed')

    }

    const closeModal = (updated, deleted) => {
        setModalOpen(false)
        setModalTask({})
        if (updated) {
            setUpdateMade(!updateMade)
        }
        if (deleted) {
            toast.success('Task deleted succesfully', toastOptions)
            setUpdateMade(!updateMade)
        }
    }
    const openModal = (task) => {
        setModalTask(task);
        setModalOpen(true)
    }
    const openCreateTaskModal = (day) => {
        setCreateTaskModalOpen(true)
        setModalDay(day)
    }
    const closeCreateTaskModal = (created = false) => {
        setCreateTaskModalOpen(false)
        setModalDay(null)
        if (created) {
            setUpdateMade(!updateMade)
        }
    }
    function getEstimates(tasks){
        var est = 0
        tasks.filter((task)=>task.done).map((task)=>{
            if(task.timeActual){
                let split = task.timeActual.split(':')
                est += parseInt(split[0])*60 + parseInt(split[1])
            }
            else if(task.timeEstimate){
                let split = task.timeEstimate.split(':')
                est += parseInt(split[0])*60 + parseInt(split[1])
            }
        })
        setActualTime(est)
        plan.time_completed = est
    }
    useEffect(()=> {
        async function getPlanifExists() {
            var p = await apiGetPlan(supabase, date)
            if (isArray(p) && p.length !== 0) {
                setPlan(p[0])
                setPlanExists(true)
            }

        }
        async function getTasks() {
            const tasks = await apiGetTasksByDay(supabase, date)
            getEstimates(tasks)
            setTasks(tasks)
        }
        getTasks()
        if (!isLoaded) {
            getPlanifExists()
            setIsLoaded(true)
        }
    },[updateMade])

    return (
        <Layout>
            <Content>
                <div className="flex p-10 bg-modal-menu-bg shadow-xl rounded-lg m-5">
                    <div className="timeline-wrapper flex justify-center w-full">
                        <WorkloadTimeline planned={plan.time_planned}/>
                    </div>
                    <div className="timeline-wrapper flex justify-center w-full">
                        <WorkloadTimeline planned={actualTime}/>
                    </div>
                </div>
                {!planExists && checkSnoozeOption() &&
                    <div className="pl-10 ">
                        <button className=" p-2 bg-chip-bg text-chip-text hover:bg-chip-accent rounded-lg" onClick={()=>setSnooze()}>Skip today</button>
                    </div>
                }
                <div className="flex flex-1 p-5 justify-center w-full">
                    <main className="pb-8 w-full">
                        <div className="mx-auto w-full h-full">
                            <h1 className="sr-only text-text-bg">Review your day</h1>
                            {/* Main 3 column grid */}
                            <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-4 lg:gap-8 w-full  h-full">
                                {/* Left column */}
                                <div className="grid grid-cols-1 gap-4 lg:col-span-2  h-full">
                                    <div className="overflow-x-scroll">
                                        <div className="p-6 h-full flex justify-center">
                                            <div className="flex flex-none flex-col w-80 clearfix">
                                                <div className="weekday-container flex flex-1 flex-col py-2.5 px-5">
                                                    <div className="date-label-container p-2">
                                                        <div className="date flex-1">
                                                            <div>
                                                                  <span className="cursor-pointer text-chip-bg text-xl font-semibold">
                                                                    {format(date, "iiii")}
                                                                  </span>
                                                                    </div>
                                                                    <span className="cursor-pointer text-chip-bg text-lg">{format(date, "MMMM, d")}
                                                                </span>
                                                        </div>
                                                    </div>
                                                    <div className="task-list-container overflow-y-hidden relative flex flex-1">
                                                        <div className="tasks-container relative flex flex-col w-80">
                                                            <div className="Task-add-container relative mt-8">
                                                                <div className="flex justify-start text-text-bg bg-card-bg px-2.5 rounded-2xl shadow-md ring-1 ring-inset ring-card-ring overflow-x-hidden my-1 p-2 min-h-10"
                                                                     onClick={() => openCreateTaskModal(date)}>

                                                                    <LuPlus
                                                                        className="h-6 w-6 shrink-0 flex text-chip-accent cursor-pointer hover:text-chip-hover mr-4"
                                                                        aria-hidden="true"
                                                                    />
                                                                    <span className="cursor-pointer flex text-lg font-semibold text-text-faded">Add Task</span>
                                                                </div>
                                                            </div>
                                                            <div className="tasks-container overflow-x-hidden overflow-y-hidden relative flex-1">
                                                                {tasks.map((item,index) => (
                                                                    <div>
                                                                        <Task  task={item} openTaskModal={openModal} />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* review column */}
                                <div className="grid grid-cols-1 gap-4 h-full">
                                    <section aria-labelledby="section-2-title">
                                        <div className="overflow-hidden lg:rounded-lg bg-modal-bg lg:shadow-lg h-full">
                                            <div className="p-6 h-full">
                                                <DailyReviewnotes notes={plan.review_notes} setReviewNotes={setReviewnotes} saveReview={onSaveReview} />
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                {/* goals column */}
                                <div className="grid grid-cols-1 gap-4 h-full">
                                    <section aria-labelledby="section-2-title">
                                        <div className="overflow-hidden lg:rounded-lg bg-modal-bg lg:shadow-lg h-full">
                                           <DailyGoal notes={plan.notes} goal={plan.main_goal} isReview={true}/>
                                        </div>
                                    </section>

                                </div>

                            </div>
                        </div>
                    </main>
                    {modalOpen && <TaskDetail task={modalTask} refe={'none'} closeTaskModal={closeModal}/>}
                    {createTaskModalOpen && <TaskCreate day={modalDay} closeModal={closeCreateTaskModal}/>}
                </div>
            </Content>
        </Layout>
    )
}
