import Layout from "../../../Layout";
import Content from "../../../Content";
import Weekday from "./Weekday";
import {addDays, isDate, subDays} from "date-fns";
import {compareDates} from "../../../../helpers";
import React, {useContext, useEffect, useState} from "react";
import TaskDetail from "./TaskDetail";
import TaskCreate from "./TaskCreate";
import {
    apiGetTasksBetween,
    apiGetTasksByDay,
} from "../../../../../App/api/tasksApi";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useSession, useSupabaseClient} from "@supabase/auth-helpers-react";
import useInfiniteScroll from 'react-easy-infinite-scroll-hook';
import {DraggingContext} from "../../../../Contexts";
import {checkLoginSync} from "../../../../../App/utils/SyncUtils";
import {toastOptions} from "../../../../Constants";
import {useSearchParams} from "react-router-dom";

function getTasksOfDay(tasklist, date) {
    return tasklist.filter((task) => {
        return compareDates(task.scheduled, date);
    });
}

export default function Tasks() {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTask, setModalTask] = useState({});
    const [modalRefe, setModalRefe] = useState('none');
    const supabase = useSupabaseClient()
    const session = useSession()
    const [modalDay, setModalDay] = useState(null);
    const [createTaskModalOpen, setCreateTaskModalOpen] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [dateList, setDateList] = useState(getInitialDates());
    const [isLoading, setIsLoading] = useState(true);
    const {isDragged} = useContext(DraggingContext)
    const [updateMade, setUpdateMade] = useState(false)
    const [searchParams,setSearchParams] = useSearchParams()
    const [needsToast, setNeedsToast] = useState(searchParams.has('dayplanned')?'planned':searchParams.has('dayreviewed')?'reviewed':'none')

    const createnext = async (direction)=> {
        try{
            setIsLoading(true)
            const newData = await loadMore(direction)
            setTasks((prev)=>[...newData, ...prev])
        }
        finally {
            setIsLoading(false)
        }
    }

    const loadMore = async (direction) => {
        var newdate = new Date()
        if(direction === 'left'){
            newdate = subDays(dateList[0],1)
            setDateList([newdate,...dateList])
        }
        else if(direction === 'right' ){
            newdate = addDays(dateList[dateList.length-1],1)
            setDateList([...dateList,newdate])
        }
        return await apiGetTasksByDay(supabase,newdate)
    }
    const closeModal = (updated,deleted) => {
        setModalOpen(false)
        setModalTask({})
        setModalRefe('none')
        if(updated) {
            setUpdateMade(!updateMade)
        }
        if(deleted){
            toast.success('Task deleted succesfully', toastOptions)
            setUpdateMade(!updateMade)
        }
    }
    const openModal = (task,refe) => {
        setModalTask(task);
        setModalRefe(refe)
        setModalOpen(true)
    }
    const openCreateTaskModal = (day) => {
        setCreateTaskModalOpen(true)
        setModalDay(day)
    }
    const closeCreateTaskModal = (created = false) => {
        setCreateTaskModalOpen(false)
        setModalDay(null)
        if(created){
            setUpdateMade(!updateMade)
        }
    }
    function getInitialDates(){
        const list = []
        for (let i = 6; i >0; i--) {
            var date = subDays(new Date(), i)
            list.push(date)
        }
        for (let i = 0; i <6; i++) {
            var date = addDays(new Date(), i)
            list.push(date)
        }
        return list
    }

    useEffect( () => {
        async function getTasks(){
            await apiGetTasksBetween(supabase,dateList[0],dateList[dateList.length-1]).then((list) => {
                setTasks(list);
            })
        }
        var sync = localStorage.getItem('taskz_google_sync')
        if(!isDate(new Date(sync))){
            checkLoginSync(supabase,session).then(()=>{
                localStorage.setItem('taskz_google_sync',new Date())
                getTasks()
                setIsLoading(false)
            })
        }
        else{
            getTasks()
            setIsLoading(false)
        }

        if(needsToast === 'planned'){
            toast('Day planned. Goodluck with your day!',toastOptions)
            setSearchParams('')
            setNeedsToast(false)
        }
        else if(needsToast === 'reviewed'){
            toast('Day reviewed',toastOptions)
            setSearchParams('')
            setNeedsToast(false)
        }
        else if(needsToast === 'snoozerd'){
            toast('Daily planning session snoozed',toastOptions)
            setSearchParams('')
            setNeedsToast(false)
        }

    }, [isDragged,updateMade])
    return (
        <Layout>
            <Content>
                <div className="max-h-full">
                    <InfiniteTaskScroll isLoading={isLoading} items={tasks} dates={dateList} next={createnext} openModal={openModal} openCreateTaskModal={openCreateTaskModal}/>
                </div>
                {modalOpen && <TaskDetail task={modalTask} refe={modalRefe} closeTaskModal={closeModal}/>}
                {createTaskModalOpen && <TaskCreate day={modalDay} closeModal={closeCreateTaskModal}/>}
            </Content>
        </Layout>
    )
}

function InfiniteTaskScroll({isLoading,items,dates,next,openModal,openCreateTaskModal}){
    const ref = useInfiniteScroll({
        next,
        columnCount: dates.length,
        hasMore: {left:!isLoading,right:!isLoading},
        scrollThreshold: 0.8,
        initialScroll: {left:1750}
    })
    return (
        <div id="scrollcontainer" className="flex overflow-x-auto overflow-y-auto" ref={ref} style={{maxHeight:"95vh"}}>
            {dates.map((date,i) => (
                <Weekday
                    key={date}
                    date={date}
                    tasks={getTasksOfDay(items, date)}
                    openTaskModal={openModal}
                    openCreateTaskModal={openCreateTaskModal}
                />
            ))}
            {isLoading && <div>Loading...</div>}
        </div>
    )

}
