import { useContext } from "react";
import { LuArrowLeftToLine, LuArrowRightToLine } from "react-icons/lu";
import {SidebarContext,
} from "../../../../Contexts";
import { left_sidebar_navigation } from "../../../../Constants";
import { classNames } from "../../../../helpers";
import SidebarDashboard from "./Sidebar_dashboard/SidebarDashboard";
import {setCurrentPage} from "../../../../../App/NavigationSlice";
import {useDispatch, useSelector} from "react-redux";

export default function Sidebar_navigation_left() {
  const { sidebarLeft,toggleSidebarLeft } = useContext(SidebarContext);
  const currentPage = useSelector(state => state.currentPage.value)
  const dispatch = useDispatch()

  return (
    <>
      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:left-0 lg:z-50 lg:block lg:w-20 lg:overflow-y-auto lg:bg-sidebar-nav-bg lg:pb-4">
        <div className="flex h-16 shrink-0 items-center justify-center">
          <img
            className="h-8 w-auto"
            src="Icon_32x32.png"
            alt="Taskz"
          />
        </div>
        <nav className="mt-8">
          <ul role="list" className="flex flex-col items-center space-y-1">
            <li>
              <button
                id="toggleLeftSidebarButton"
                className="text-text-bg-inverse hover:text-white group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold"
                onClick={() => {
                  toggleSidebarLeft();
                }}
              >
                {sidebarLeft == null || sidebarLeft ? (
                  <LuArrowLeftToLine
                    className="h-6 w-6 shrink-0"
                    aria-hidden="true"
                  />
                ) : (
                  <LuArrowRightToLine
                    className="h-6 w-6 shrink-0"
                    aria-hidden="true"
                  />
                )}
                <span className="sr-only">Close sidebar</span>
              </button>
            </li>
            {left_sidebar_navigation.map((item) => (
              <li key={item.name}>
                <a
                  href={item.href}
                  onClick={()=>dispatch(setCurrentPage(item.current))}
                  className={classNames(
                    item.current === currentPage
                      ? "text-chip-accent"
                      : "text-text-bg-inverse/90 hover:text-chip-bg",
                    "group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold",
                  )}
                >
                  <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                  <span className="sr-only">{item.name}</span>
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <aside
        className={classNames(
          (sidebarLeft !== null ? sidebarLeft : true) ? "lg:block" : "",
          "fixed bg-sidebar-bg bottom-0 top-0 left-20 hidden w-80 overflow-y-auto border-r border-text-bg-inverse px-4 py-6 sm:px-6 lg:px-8",
        )}
      >
        <SidebarDashboard />
      </aside>
    </>
  );
}
