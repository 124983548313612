import Layout from "../../../Layout";
import Content from "../../../Content";

import React, {useContext, useEffect, useState} from "react";
import {classNames, getMobname} from "../../../../helpers";
import {LuX} from "react-icons/lu";
import ScrollDetail from "./ScrollDetail";
import {useSupabaseClient} from "@supabase/auth-helpers-react";
import {apiGetScrolls} from "../../../../../App/api/Inventory/InventoryApi";
import QuestDetail from "./QuestDetail";
import {QuestContext} from "../../../../../App/utils/Contexts/QuestContext";
export default function Quests() {
    const [visibleScrolls, setVisibleScrolls] = useState("0");
    const [modalOpen,setModalOpen] = useState(false);
    const [modalScroll,setModalScroll] = useState({});
    const [questModalOpen,setQuestModalOpen] = useState(false);
    const [modalQuest,setModalQuest] = useState({});
    const [scrolls, setScrolls] = useState([])
    const supabase = useSupabaseClient()
    const {quests,setQuests,updated,setUpdated} = useContext(QuestContext)

    const closeModal = (started = false)=>{
        setModalOpen(false)
        setModalScroll({})
        if(started){
            setUpdated(!updated)
        }
    }
    const openModal = (scroll) =>{
        setModalScroll(scroll);
        setModalOpen(true)
    }
    const closeQuestModal = ()=>{
        setQuestModalOpen(false)
        setModalQuest({})
    }
    const openQuestModal = (quest) =>{
        setModalQuest(quest);
        setQuestModalOpen(true)
    }
    useEffect(()=>{
        async function fetchScrolls(){
            const scrolls = await apiGetScrolls(supabase)
            setScrolls(scrolls)
        }
        fetchScrolls()

    },[updated])
    return (
        <Layout>
            <Content>
                <div className="column-container p-8 lg:p-10  gap-x-8 gap-y-5 flex flex-wrap flex-row w-full justify-center ">
                    <div className="column container_scrolls_main flex-col flex-auto lg:min-w-96 2xl:max-w-160">
                        <div  className={classNames(visibleScrolls === "0" && "pb-4")}>
                            <h2 tabIndex="0" className="focus:outline-none text-sm leading-normal pt-8 text-text-bg/70 pb-4">Inventory</h2>
                            <div className="cursor-pointer flex flex-col justify-start text-text-bg bg-card-bg px-1.5 rounded-2xl shadow-md ring-1 ring-inset ring-card-ring overflow-x-hidden mb-5 p-2 min-h-20">
                                <div className="flex-1 flex justify-evenly px-1">
                                    <div className="flex items-center">
                                        <div className="w-20 h-20 rounded-full bg-text-faded flex items-center"  data-tooltip-target="tooltip-common" onClick={()=>setVisibleScrolls('common')}>
                                            <img src="/assets/game/items/quest_scroll.png" alt="scroll" className="w-14 h-14 m-auto"/>
                                        </div>
                                        <div id="tooltip-common" role="tooltip"
                                             className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-text-bg transition-opacity duration-300 bg-disabled-bg rounded-lg shadow-sm opacity-0 tooltip">
                                            Common quests
                                            <div className="tooltip-arrow" data-popper-arrow=""></div>
                                        </div>
                                    </div>
                                    <div className="flex items-center">
                                        <div className="w-20 h-20 rounded-full bg-text-faded flex items-center" data-tooltip-target="tooltip-uncommon" onClick={()=>setVisibleScrolls('uncommon')}>
                                            <img src="/assets/game/items/quest_scroll.png" alt="scroll" className="w-14 h-14 m-auto" />
                                        </div>
                                        <div id="tooltip-uncommon" role="tooltip"
                                             className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-text-bg transition-opacity duration-300 bg-disabled-bg rounded-lg shadow-sm opacity-0 tooltip">
                                            Uncommon quests
                                            <div className="tooltip-arrow" data-popper-arrow=""></div>
                                        </div>
                                    </div>
                                    <div className="flex items-center">
                                        <div className="w-20 h-20 rounded-full bg-text-faded flex items-center" data-tooltip-target="tooltip-rare" onClick={()=>setVisibleScrolls('rare')}>
                                            <img src="/assets/game/items/quest_scroll.png" alt="scroll" className="w-14 h-14 m-auto"/>
                                        </div>
                                        <div id="tooltip-rare" role="tooltip"
                                             className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-text-bg transition-opacity duration-300 bg-disabled-bg rounded-lg shadow-sm opacity-0 tooltip">
                                            Rare quests
                                            <div className="tooltip-arrow" data-popper-arrow=""></div>
                                        </div>
                                    </div>
                                    <div className="flex items-center">
                                        <div className="w-20 h-20 rounded-full bg-text-faded flex items-center" data-tooltip-target="tooltip-epic" onClick={()=>setVisibleScrolls('epic')}>
                                            <img src="/assets/game/items/quest_scroll.png" alt="scroll" className="w-14 h-14 m-auto"/>
                                        </div>
                                        <div id="tooltip-epic" role="tooltip"
                                             className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-text-bg transition-opacity duration-300 bg-disabled-bg rounded-lg shadow-sm opacity-0 tooltip">
                                            Epic quests
                                            <div className="tooltip-arrow" data-popper-arrow=""></div>
                                        </div>
                                    </div>
                                    <div className="flex items-center">
                                        <div className="w-20 h-20 rounded-full bg-text-faded flex items-center" data-tooltip-target="tooltip-legendary" onClick={()=>setVisibleScrolls('legendary')}>
                                            <img src="/assets/game/items/quest_scroll_fancy.png" alt="scroll" className="w-14 h-14 m-auto"/>
                                        </div>
                                        <div id="tooltip-legendary" role="tooltip"
                                             className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-text-bg transition-opacity duration-300 bg-disabled-bg rounded-lg shadow-sm opacity-0 tooltip">
                                            Legendary quests
                                            <div className="tooltip-arrow" data-popper-arrow=""></div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {
                                visibleScrolls!=="0" &&
                                <div className="cursor-pointer flex flex-col justify-start text-text-bg bg-card-bg px-1.5 rounded-2xl shadow-md ring-1 ring-inset ring-card-ring overflow-x-hidden mb-5 p-2 min-h-20">
                                    <div className="flex-1 flex justify-end px-1">
                                        <span className="sr-only">Close scroll display</span>
                                        <LuX onClick={()=>setVisibleScrolls('0')}
                                            className="ml-2 h-5 w-5 text-text-bg hover:text-chip-accent"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div className="flex-1 flex justify-center"><span className="-mt-6 mb-2 text-lg font-semibold">{visibleScrolls} quests</span></div>
                                    <div className="flex-1 flex justify-evenly px-1">
                                        {scrolls.filter(scroll => scroll.scroll_rarity === visibleScrolls).map(scroll => (
                                            <div className="flex items-center">
                                                <div className="flex flex-col items-center justify-center" onClick={()=>openModal(scroll)}>
                                                    <div className="w-16 h-16 rounded-full bg-text-faded flex items-center">
                                                        <img src="/assets/game/items/quest_scroll.png" alt="scroll" className="w-12 h-12 m-auto"/>
                                                    </div>
                                                    <span> {scroll.quest_type + " "+scroll.quest_target_amount+" "+getMobname(scroll.quest_target)}</span>
                                                </div>
                                            </div>
                                        ))
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        {/*

                         <div className="container_main">
                            <h2 tabIndex="0" className="focus:outline-none text-sm leading-normal pt-8  text-text-bg/70  pb-4">Main quest</h2>
                            <div className="cursor-pointer flex-1 justify-start text-text-bg bg-card-bg px-1.5 rounded-2xl shadow-md ring-1 ring-inset ring-card-ring overflow-x-hidden p-2 min-h-20 border-chip-bg">

                                <div className="flex-1 flex justify-center items-center px-1">
                                    <span className="main_quest_title text-text-bg font-semibold ">Main Quest: Gather 5 Rat tail</span>
                                </div>
                                <div className="flex-1 flex items-center px-1">
                                    <div className="w-20 h-20 rounded-full bg-text-faded flex items-center" data-tooltip-target="tooltip-rat_tail">
                                        <img src="/assets/game/items/rat_tail.png" alt="rat tail" className="w-14 h-14 m-auto"/>
                                    </div>
                                    <div className="Quest_progress">
                                        <span className="ml-4 text-text-bg font-semibold">0 / 5</span>
                                    </div>

                                </div>
                                <div className="mt-4">
                                    <p className="text-left text-sm text-text-bg">
                                        loottable options will be displayed here
                                    </p>
                                </div>
                                <div id="tooltip-rat_tail" role="tooltip"
                                     className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-text-bg transition-opacity duration-300 bg-disabled-bg rounded-lg shadow-sm opacity-0 tooltip">
                                    Rat tails
                                    <div className="tooltip-arrow" data-popper-arrow=""></div>
                                </div>

                            </div>
                        </div>
                         */}

                    </div>
                    <div className="column container_quest flex-col flex-auto s:w-full lg:min-w-96 2xl:max-w-160">
                        <h2 tabIndex="0" className="focus:outline-none text-sm leading-normal pt-8 text-text-bg/70  pb-4">Active quests</h2>
                        {quests.filter((q)=>!q.rewards_collected).map(quest => (
                            <div onClick={()=>openQuestModal(quest)}
                                className="cursor-pointer flex flex-col justify-start text-text-bg bg-card-bg px-1.5 rounded-2xl shadow-md ring-1 ring-inset ring-card-ring overflow-x-hidden mb-5 p-2 min-h-20">
                                <div className="flex-1 grid grid-cols-5 px-1">
                                    <div className="row-span-2 flex items-center justify-center">
                                        <div className="w-16 h-16 rounded-full bg-text-faded flex justify-center items-center">
                                            <img src={"assets/game/mobs/"+quest.quest_target+".png"} alt="mob" className=" w-10 h-10"/>
                                        </div>
                                    </div>
                                    <div className="col-span-3 ">
                                    <span className="text-text-bg text-s font-semibold">
                                      {quest.quest_type + " "+quest.quest_target_amount+" "+getMobname(quest.quest_target)}
                                    </span>
                                    </div>
                                    <div className="row-span-2 flex items-center">

                                    </div>
                                    <div className="col-span-3  flex justify-start items-center">
                                        <span className="text-text-bg text-m flex items-center font-semibold">
                                          {quest.progress} / {quest.quest_target_amount}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="container quests_history flex flex-col justify-center mx-16">
                        <h2 tabIndex="0" className="focus:outline-none text-sm leading-normal pt-8 text-text-bg/70 pb-4">Completed quests</h2>
                        {quests.filter((q)=>q.rewards_collected).map(quest => (
                            <div onClick={()=>openQuestModal(quest)}
                                 className="cursor-pointer flex flex-col justify-start text-text-bg bg-card-bg px-1.5 rounded-2xl shadow-md ring-1 ring-inset ring-card-ring overflow-x-hidden mb-5 p-2 min-h-20">
                                <div className="flex-1 grid grid-cols-5 px-1">
                                    <div className="row-span-2 flex items-center justify-center">
                                        <div className="w-16 h-16 rounded-full bg-text-faded flex justify-center items-center">
                                            <img src={"assets/game/mobs/"+quest.quest_target+".png"} alt="mob" className=" w-10 h-10"/>
                                        </div>
                                    </div>
                                    <div className="col-span-3 ">
                                    <span className="text-text-bg text-s font-semibold">
                                      {quest.quest_type + " "+quest.quest_target_amount+" "+getMobname(quest.quest_target)}
                                    </span>
                                    </div>
                                    <div className="row-span-2 flex items-center">

                                    </div>
                                    <div className="col-span-3  flex justify-start items-center">
                                        <span className="text-text-bg text-m flex items-center font-semibold">
                                          {quest.progress} / {quest.quest_target_amount}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {questModalOpen && <QuestDetail quest={modalQuest} closeQuestModal={closeQuestModal} />}
                {modalOpen && <ScrollDetail scroll={modalScroll} closeScrollModal={closeModal} />}
            </Content>
        </Layout>
    );
}
