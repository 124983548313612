import React from 'react'
import MonthDay from "./MonthDay";

export default class MonthWeek extends React.PureComponent{
    onClickDay(day) {
        this.props.onClickDay(day.date);
    }

    returnWeeks(week) {
        if (Array.isArray(week) && week.length) {
            return week.map((day, i) => {
                return (
                    <MonthDay
                        key={i}
                        date={day.date}
                        current={day.current}
                        selectedDay={this.props.selectedDay}
                        events={day.events}
                        isDesktop={this.props.isDesktop}
                        timeformat={this.props.timeformat}
                        onClickEvent={this.props.onClickEvent}
                        onClickDay={() => this.onClickDay(day)}
                    />
                );
            });
        }
    }

    render() {
        return (
            <div className="weekRow">{this.returnWeeks(this.props.week)}</div>
        );
    }
}
