import {getUserID} from "../ApiConfig";


export async function apiGetCompanions(supabase){

    const {data: companions, error} = await supabase.from('Companions').select('*')
    if(!error){
        return companions
    }
    return {
        // TODO: error handling
    }

}
export async function apiGetEggs(supabase){

    const {data: eggs, error} = await supabase.from('Eggs').select('*')
    if(!error){
        return eggs
    }
    return {
        // TODO: error handling
    }
}
export async function apiAddEgg(supabase, egg){
    const id = await getUserID(supabase)
    if (!id) return {}

    egg.user = id
    const {data:e,error} = await supabase.from('Eggs').insert(egg).select()
    if(!error){
        return e
    }
    return {}
}

export async function apiRemoveEgg(supabase,egg){

    const {data:e,error} = await supabase.from('Eggs').delete().eq('id',egg).select()
    if(!error){
        return e
    }
    return {}

}
export async function apiGetCompanionLoottable(supabase,id){

    const {data:loottable,error} = await supabase.from('Loottable_companions').select('name,pool').eq('id',id)
    if(!error){
        return loottable[0]
    }
    return []
}

export async function apiGetCompanionSpecies(supabase,species){
    const {data:c,error} = await supabase.from('Companion_types').select('hatchtime_type,hatchtime_time').eq('machine_name',species)
    if(!error){
        return c[0]
    }
    return {}
}
export async function apiGetCompanionType(supabase,species){
    const {data:c,error} = await supabase.from('Companion_types').select('*').eq('machine_name',species)
    if(!error){
        return c[0]
    }
    return {}
}
export async function apiAddCompanion(supabase, companion){
    const id = await getUserID(supabase)
    if (!id) return {}

    companion.user = id
    const {data:e,error} = await supabase.from('Companions').insert(companion).select()
    if(!error){
        return e
    }
    return {}
}

export async function apiPlayWithCompanion(supabase,companionID, exp){
    const {data:companion,error} = await supabase.from('Companions').update({experience: exp,last_played_with:new Date()}).eq('id',companionID).select()
    if(!error){
        return companion
    }
    return {}
}
