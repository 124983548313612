import React, {Fragment, useContext, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {LuHeart, LuPencil} from "react-icons/lu";
import {getCompanionAge, getCompanionImagename, getCompanionVariantName} from "../../../../helpers";
import {addHours, format, isAfter} from "date-fns";
import {CompanionContext} from "../../../../../App/utils/Contexts/CompanionContext";

export default function CompanionDetail({companion,closeCompanionModal}){
    const [companionLastPlay,setCompanionLastPlay] = useState(new Date(companion.last_played_with))
    const {playWith} = useContext(CompanionContext)



    function companionCanPlay(){
        var nextPlay = addHours(companionLastPlay,6)
        if(isAfter(new Date(),nextPlay)){
            return true
        }
        return false
    }
    function playCompanion(){
       setCompanionLastPlay(new Date())
        companion.experience +=2
        playWith(companion)


    }
    return (
        <>
            <Transition.Root show={true} as={Fragment}>
                <Dialog as="div" className="relative z-10 " onClose={()=>closeCompanionModal()}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-dark-grey bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-card-bg px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div>
                                        <div className=" text-text-bg  font-semibold items-center flex justify-end ">
                                            <LuHeart
                                                className="h-6 w-6 shrink-0 text-text-bg mr-2 hover:text-color-danger cursor-pointer"
                                                aria-hidden="true"
                                            />
                                        </div>
                                        <div className="text-center sm:mt-5">

                                            <div className=" text-text-bg  font-semibold dialog-title items-center flex justify-center ">
                                                <div className="flex items-center">
                                                    <span className="text-text-bg text-xl font-semibold mr-4">{companion.name}</span>
                                                    {/*<LuPencil
                                                        className="h-6 w-6 shrink-0 text-text-bg mr-2 hover:text-chip-bg cursor-pointer"
                                                        aria-hidden="true"
                                                    /> */}
                                                </div>
                                            </div>
                                            <div className="mt-4">
                                                <img src={"/assets/game/companions/"+getCompanionImagename(companion)} alt="scroll" className="h-56 m-auto"/>
                                            </div>
                                            <div  className="mt-4">
                                                <span className="flex justify-center text-text-bg text-lg font-semibold">{getCompanionVariantName(companion.type,companion.variant)} </span>
                                            </div>
                                            <div className=" flex flex-col items-center">
                                                <span className="text-xs text-text-bg font-medium">Hatched on: {format(new Date(companion.created_at), "dd/MM/yyyy")}</span>
                                                <span className="text-xs text-text-bg font-medium" data-tooltip-target="tooltip-companionAge">{getCompanionAge(companion.experience)}</span>

                                            </div>
                                            <div className="mt-4">
                                                <p className="mt-8 flex flex-1 justify-evenly gap-x-12 text-sm text-text-bg">
                                                    {companionCanPlay() &&
                                                        <button className="block flex-1 px-3 py-1 text-sm font-semibold leading-6 bg-chip-bg text-chip-text hover:bg-chip-accent rounded-lg outline-none shadow-md hover:text-chip-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-chip-accent"
                                                                onClick={() => playCompanion()}
                                                        >
                                                            Play
                                                        </button>

                                                    }

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">

                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}
