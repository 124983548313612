import React from 'react'
import {classNames} from "../../../../../helpers";
import {Rnd} from "react-rnd";

export default class Event extends React.PureComponent{
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.onDragStop = this.onDragStop.bind(this);
        this.onResizeStop = this.onResizeStop.bind(this);
        this.state = {
            isDragging:false,
            x: 0,
            y: 0,
            positiondiff: 0,
        }

    }

    onClick(e) {
        e.stopPropagation();
        if(this.state.isDragging) return
        this.props.onClick();
    }
    onDragStop(e,d){
        //e.stopPropagation()
        var refThis = this
        this.props.onDragStop(e,d,this.props.startPosition,this.props.startheight)
        setTimeout(function(){ refThis.setState({isDragging:false});}, 500);
    }
    onResizeStop(e,direction,ref,delta,position){
        //e.stopPropagation()
        var refThis = this
        this.props.onResizeStop(e,direction,ref,delta,position,this.props.startPosition,this.props.startheight)
        setTimeout(function(){ refThis.setState({isDragging:false});}, 500);
    }
    render() {
        var test = document.getElementsByClassName('dailyTimeLine')
        var width = '100%'
        var x = 0
        var y = 0
        if(test.length > 0){
            width = test[0].offsetWidth
            if(this.props.overlap !== 0){
                width = Math.floor(width/(this.props.overlap +1))-convertRemToPixels(1.25)
            }
            else{
                width = width - convertRemToPixels(1.25)
            }
        }
        function convertRemToPixels(rem) {
            return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
        }

        return (
            <>
                {  !["allDayEvent","singleAllDayEvent"].includes(this.props.eventType) &&
                    <Rnd
                        ref={c => { this.rnd = c; }}
                        default={{
                            x:0,
                            y:0,
                            width: width,
                            height: this.props.startheight,
                        }}
                        position={{ x: x, y: y }}
                        onResizeStart={(e, direction, ref, delta, position) => {
                          this.setState({isDragging:true})
                        }}
                        onDragStart={(e, d) => {
                            //this.setState({isDragging:true})
                        }}
                        onDragStop={(e, d) => {
                            this.onDragStop(e,d)
                        }}
                        onDrag={(e, d) =>{
                            this.setState({isDragging:true})
                        }}
                        onResizeStop={(e, direction, ref, delta, position) => {
                            this.onResizeStop(e,direction,ref,delta,position)
                        }}

                        enableResizing={{
                            bottom: true,
                            bottomLeft: false,
                            bottomRight: false,
                            left: false,
                            right: false,
                            top: true,
                            topLeft: false,
                            topRight: false,
                        }}
                        dragAxis="y"
                        bounds=".dailyCalendarContainer"
                        className="w-full"
                    >
                        <div
                            style={{ backgroundColor: this.props.color.backgroundColor,color: this.props.color.foregroundColor}}
                            onClick={this.onClick}
                            className={classNames(this.props.color?'':'bg-default-task-bg',
                                "dailyEventWrapper w-full text-xs min-h-7 left-12 h-full text-text-selected-day rounded-lg box-border ml-2.5 px-1"
                            )}
                        >
                            <span className="dailyEventTitle font-semibold">{this.props.summary}</span>
                        </div>
                    </Rnd>
                }
                { ["allDayEvent","singleAllDayEvent"].includes(this.props.eventType) &&
                    <div
                        style={{ backgroundColor: this.props.color.backgroundColor,color: this.props.color.foregroundColor}}
                        className={classNames(this.props.color?'':'bg-default-task-bg',
                            "dailyEventWrapper w-full text-xs min-h-7 left-12 h-full text-text-selected-day rounded-lg box-border px-1 pt-1 mt-1"
                        )}
                    >
                        <span className="dailyEventTitle font-semibold p-1 mt-1">{this.props.summary}</span>
                    </div>

                }
            </>





        );
    }

}
