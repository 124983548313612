import {getUserID} from "../ApiConfig";
import {format} from "date-fns";

export async function apiGetPlan(supabase,date = new Date()) {

    const {data: plan, error} = await supabase.from('Daily_plan').select('*').eq('date',format(date,'yyyy-MM-dd'))
    if(!error){
        return plan
    }
    return {
        // TODO: error handling
    }
}

export async function apiGetEstimates(supabase,date){

    const {data: estimates, error} = await supabase.from('Tasks').select('*').eq('scheduled',format(date,'yyyy-MM-dd')).not('timeEstimate','is','null').neq('timeEstimate','00:00:00')
    if(!error){
        return estimates
    }
    return {}
}


export async function apiUpdatePlan(supabase,plan){

    const {data, error} = await supabase.from('Daily_plan').update({main_goal:plan.main_goal,notes:plan.notes,time_planned:plan.time_planned}).eq('date',format(plan.date,'yyyy-MM-dd'))
    if(!error){
        return data
    }
    return {}
}
export async function apiUpdateReview(supabase,plan){

    const {data, error} = await supabase.from('Daily_plan').update({time_completed:plan.time_completed,review_notes:plan.review_notes}).eq('date',format(plan.date,'yyyy-MM-dd'))
    if(!error){
        return data
    }
    return {}
}
export async function apiInsertPlan(supabase,plan){
    const id = await getUserID(supabase)
    if (!id) return []

    const {data, error} = await supabase.from('Daily_plan').insert([{...plan,user:id}])
    if(!error){
        return data
    }
    return {}
}
