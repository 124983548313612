import Nav from "./Nav";
import Login_form from "./Login_form";
import Footer from "./Footer";
import {useSelector} from "react-redux";
import {classNames} from "../../../../helpers";

export default function Login_page() {
    const settings=useSelector(state => state.settings).settings
    var theme = 'dark'
    if(settings.theme_light){theme = 'light'}
    return (
        <div className={classNames("bg-gradient",{theme})}>
      <Nav />
      <Login_form />
      <Footer />
    </div>
  );
}
