

import { useSupabaseClient} from "@supabase/auth-helpers-react";
import Nav from "../../../components/layout/Content/Main_page/Accounts/Nav";
import Footer from "../../../components/layout/Content/Main_page/Accounts/Footer";
import React, {useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {toast} from "react-toastify";
import {toastOptions} from "../../../components/Constants";

export default function Passwordrecovery(){
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [token, setToken] = useState('')
    const [searchParams,setSearchParams] = useSearchParams()
    const [email, setEmail] = useState(searchParams.get('email'))
    const supabase = useSupabaseClient()
    const [error,setError] = useState([])
    const navigate = useNavigate()
    async function checkConfirmPassword(){
        setError([])
        if(password === passwordConfirm){
            const {data, error } = await supabase.auth.verifyOtp({
                email:email,
                type:'recovery',
                token:token,
            })
            if(!error){
                const { data, error } = await supabase.auth.updateUser({ password: password })
                if(error) {
                    error.push(error)
                }
                else{
                    toast.success("Password updated",toastOptions)
                    navigate('/login')
                }
            }
            else{
                console.log(error)
            }
        }
        else{
            error.push("Passwords do not match")
        }

    }
    return (
        <div className=" w-full h-full bg-gradient">
            <Nav />
            <div className="mt-32 bg-modal-bg rounded-2xl min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 sm:mx-auto sm:w-full max-w-lg">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <div>
                        <label>Token</label>
                        <input
                            id="token"
                            name="token"
                            type="text"
                            onChange={(e) => setToken(e.target.value)}
                            required
                            className="block w-full rounded-md border-0 py-1.5 text-text-important shadow-sm ring-1 bg-input-bg ring-inset ring-card-ring placeholder:text-text-bg/50 focus:ring-2 focus:ring-inset focus:ring-chip-bg sm:text-sm sm:leading-6"
                        />
                    </div>
                    <div>
                        <label>new password</label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="block w-full rounded-md border-0 py-1.5 text-text-important shadow-sm ring-1 bg-input-bg ring-inset ring-card-ring placeholder:text-text-bg/50 focus:ring-2 focus:ring-inset focus:ring-chip-bg sm:text-sm sm:leading-6"
                        />
                    </div>
                    <div>
                        <label>confirm new password</label>
                        <input
                            id="confirm_password"
                            name="confirm_password"
                            type="password"
                            onChange={(e) => setPasswordConfirm(e.target.value)}
                            required
                            className="block w-full rounded-md border-0 py-1.5 text-text-important shadow-sm ring-1 bg-input-bg ring-inset ring-card-ring placeholder:text-text-bg/50 focus:ring-2 focus:ring-inset focus:ring-chip-bg sm:text-sm sm:leading-6"
                        />
                    </div>
                    {error.map((err)=>{
                        <p className="text-color-danger">{err}</p>
                    })}
                    <button className="p-2 rounded-lg bg-chip-bg text-chip-text hover:bg-chip-accent font-semibold mt-5" onClick={()=>checkConfirmPassword()}>
                        Update
                    </button>

                </div>
            </div>
            <Footer />
        </div>
    )


}
