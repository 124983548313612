import {Dialog, Transition} from "@headlessui/react";
import React, {Fragment, useState} from "react";
import {useSupabaseClient} from "@supabase/auth-helpers-react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {toastOptions} from "../../../../Constants";


export default function ForgotPassword({closeModal}){
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const [email,setEmail] = useState('')
    const supabase= useSupabaseClient()
    const navigate =useNavigate()
    async function validate(){
        if(email !== '' && email.match(validRegex)){
            await supabase.auth.resetPasswordForEmail(email)
            navigate('/resetpassword?type=recovery&email='+encodeURIComponent(email))
        }
        else{
            toast("Please enter a valid email address",toastOptions)
        }
    }
    return (
        <>
            <Transition.Root show={true} as={Fragment}>
                <Dialog as="div" className="relative z-10 " onClose={()=>closeModal()}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-dark-grey bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-card-bg px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div>

                                        <div className="mt-3 text-center sm:mt-5">
                                            <div className=" text-text-bg  font-semibold dialog-title items-center flex-1 flex-col justify-between ">
                                                <div className="mt-2">
                                                    <div className="mt-2 flex flex-1 items-center justify-evenly">
                                                        <input
                                                            id="email"
                                                            name="email"
                                                            type="email"
                                                            placeholder="Email"
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            required
                                                            autoFocus={true}
                                                            className="flex-1 mr-10 block rounded-md border-0 py-1.5 text-text-important shadow-sm ring-1 bg-input-bg ring-inset ring-card-ring placeholder:text-text-bg/50 focus:ring-inset focus:ring-chip-bg sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mt-4 flex flex-1 justify-center items-center">
                                                    <div>
                                                        <button onClick={()=>validate()}
                                                                className="block flex-1 px-3 py-1.5 text-m font-semibold leading-6 bg-chip-bg text-chip-text hover:bg-chip-accent rounded-lg outline-none shadow-md hover:text-chip-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-chip-accent">
                                                            Reset password
                                                        </button>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">

                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}
