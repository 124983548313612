import Content from "../Content";
import Layout from "../Layout";
import {useEffect, useState} from "react";
import {useSupabaseClient} from "@supabase/auth-helpers-react";
import {apiGetKnownBugs, apiInsertBug} from "../../../App/api/Bugs/BugApi";
import {toastOptions} from "../../Constants";
import {toast} from "react-toastify";


export default function Bugs(){
    const [knownBugs, setKnownBugs] = useState([])
    const supabase = useSupabaseClient()
    const [bugDescription, setBugDescription] = useState("")

    async function submitBugReport(){
        var desx = bugDescription
        setBugDescription('')
        await apiInsertBug(supabase,bugDescription)
        toast.success("Thank you for your report!",toastOptions)
    }
    useEffect(()=>{
        async function getBugs(){
            var bugs = await apiGetKnownBugs(supabase)
            setKnownBugs(bugs)
            console.log(bugs)
        }
        getBugs()
    },[])
    return (
            <Layout>
                <Content>
                    <div>
                        <div className="flex p-10 overflow-x-auto">
                            <div className=" mt-10 flex-1 relative overflow-hidden">
                                <div className="border-b dark:border-gray-700 mx-4">
                                    <div className="flex items-center justify-between md:space-x-4 py-3">
                                        <div className="w-full flex flex-col space-y-4 md:items-center">
                                            <div>
                                                <ul className="list-disc">
                                                    {knownBugs.map((bug) => (
                                                        <li key={bug.id}>{bug.description}</li>
                                                    ))}
                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between md:space-x-4 py-3">
                                    <div className="w-full flex flex-col space-y-4 md:items-center">
                                        <div className="flex flex-col justify-center items-center">
                                            <h3 className="text-lg text-text-bg p-6">Found a bug? Please notify us using the form below.</h3>
                                            <textarea
                                                name="newBug"
                                                id="newBug"
                                                rows="15"
                                                className="resize-none flex-1 block w-full rounded-md border-0 py-1.5 bg-input-bg md:text-base text-dark-gray shadow-sm ring-0 border-transparant focus:ring-0 focus:outline-none sm:text-sm sm:leading-6"
                                                value={bugDescription}
                                                onChange={(e)=>setBugDescription(e.target.value)}
                                            />
                                            <button onClick={()=>submitBugReport()}
                                                className="p-2 m-2 bg-chip-bg text-chip-text rounded-lg hover:bg-chip-accent">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Content>
            </Layout>
        )

}
