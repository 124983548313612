import React, {useEffect, useState} from "react";
import { classNames } from "../helpers";
import { Dialog, Switch } from "@headlessui/react";
import {settings_dummylist, settingsNavigation, toastOptions} from "../Constants";
import { LuArrowLeftToLine } from "react-icons/lu";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import {useSelector,useDispatch} from "react-redux";
import {updateSetting} from "../../App/SettingSlice";
import Select from 'react-select'
import TimezoneSelect from 'react-timezone-select'
import {setCurrentPage} from "../../App/NavigationSlice";
import {useSupabaseClient, useUser} from "@supabase/auth-helpers-react";
import CalendarSettings from "./CalendarSettings";
import {useNavigate, useSearchParams} from "react-router-dom";
import {apiGetUserDataRequests, apiRequestUserData, prepareUserForDeletion} from "../../App/api/ApiConfig";
import {toast} from "react-toastify";
import {format} from "date-fns";

export default function Settings() {

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const settings=useSelector(state => state.settings).settings
  const dispatch = useDispatch()
  const supabase = useSupabaseClient()
  const user = useUser()
  const [searchParams,setSearchParams] = useSearchParams()
  const [activeTab, setActiveTab] = useState(searchParams.has('Google_authorization_done')?"Calendar settings":"General");
  const navigate = useNavigate()
  const [userDataRequests, setUserDataRequests] = useState([])

  async function resetPassword(){
    await supabase.auth.resetPasswordForEmail(user.email)
    await supabase.auth.signOut('global').then(()=>{
      navigate('/resetpassword?type=recovery&email='+encodeURIComponent(user.email))
    })
  }
  async function requestUserData(){
    await apiRequestUserData(supabase,user.id)
    toast.success("Data request sent",toastOptions)
  }
  async function signOutGlobal(){
    await supabase.auth.signOut('global')
  }
  function getDefaultValue(list,value){
    return list.filter(item => item.value === value)
  }
  function handleTimezoneChange(zone){
    if(zone !== ''){
      dispatch(updateSetting({supabase:supabase,setting_name: 'timezone',setting_value:zone.value}))
    }

  }
  function handleSettingChange(event,machine_name){
    if(event.value !== ''){
      dispatch(updateSetting({supabase:supabase,setting_name:machine_name,setting_value:event.value}))
    }
  }
  async function deleteUser(){
    prepareUserForDeletion(supabase,user.id)
    window.localStorage.clear()
    supabase.auth.signOut()
  }

  useEffect(()=>{
    async function getUserDataRequests(){
      var requests = await apiGetUserDataRequests(supabase,user.id)
      setUserDataRequests(requests)
    }

    if(activeTab === 'Account'){
      getUserDataRequests()
      var timer;
      const button = document.getElementById('delete_account_button')
      const press = () => {
        button.classList.add("full")
        var timer = setTimeout(remove, 1500);
      }
      const release = () => {
        console.log("release triggered")
        button.classList.remove("full");
        clearTimeout(timer);
      };

      button.addEventListener("mousedown", press, false);
      button.addEventListener("touchstart", press, false);
      button.addEventListener("mouseup", release, false);
      button.addEventListener("touchend", release, false);
      const remove = () => {
        deleteUser()
      }
    }
  },[activeTab])
  return (
      <div className="bg-gradient min-h-max flex flex-1">
        <div className="mx-auto max-w-7xl 2xl:max-w-screen-2xl pt-16 lg:flex lg:flex-1 lg:gap-x-16 lg:px-8">
          <h1 className="sr-only">{activeTab} Settings</h1>

          <aside className="flex overflow-x-auto border-b border-text-bg/50 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
            <nav className="flex-none px-4 sm:px-6 lg:px-0">
              <button
                  type="button"
                  className="-m-3 p-3 lg:hidden"
                  onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-5 w-5 text-text-bg" aria-hidden="true" />
              </button>
            </nav>
            <nav className="flex-none px-4 sm:px-6 lg:px-0">
              <ul
                  role="list"
                  className="hidden lg:flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col"
              >
                <li key="Back to app">
                  <a
                      href="/plan"
                      onClick={()=>dispatch(setCurrentPage('plan'))}
                      className={
                        "text-chip-bg group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm mb-10 leading-6 font-semibold"
                      }
                  >
                    <LuArrowLeftToLine
                        className={"text-chip-bg h-6 w-6 shrink-0"}
                        aria-hidden="true"
                    />
                    Back to app
                  </a>
                </li>
                {settingsNavigation.map((item) => (
                    <li key={item.name}>
                      <button
                          onClick={()=>setActiveTab(item.name)}
                          className={classNames(
                              item.name === activeTab
                                  ? "text-chip-bg"
                                  : "text-text-bg hover:text-chip-accent",
                              "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold",
                          )}
                      >
                        <item.icon
                            className={classNames(
                                item.name === activeTab
                                    ? "text-chip-bg"
                                    : "text-text-faded group-hover:text-chip-bg",
                                "h-6 w-6 shrink-0",
                            )}
                            aria-hidden="true"
                        />
                        {item.name}
                      </button>

                    </li>
                ))}
              </ul>
            </nav>
            <Dialog
                as="div"
                className="xl:hidden"
                open={mobileMenuOpen}
                onClose={setMobileMenuOpen}
            >
              <div className="fixed inset-0 z-50" />
              <Dialog.Panel className="fixed inset-y-0 left-0 z-50 w-full overflow-y-auto bg-body-bg-start px-4 pb-6 sm:max-w-sm sm:px-6 sm:ring-1 sm:ring-disabled-text">
                <div className="-ml-0.5 flex h-16 items-center gap-x-6">
                  <button
                      type="button"
                      className="-m-2.5 p-2.5 text-text-bg"
                      onClick={() => setMobileMenuOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  <div className="-ml-0.5">
                    <a href="#" className="-m-1.5 block p-1.5">
                      <span className="sr-only">Taskz</span>
                      <img
                          className="h-8 w-auto"
                          src="Icon_32x32.png"
                          alt="Taskz"
                      />
                    </a>
                  </div>
                </div>
                <div className="mt-2 space-y-2">
                  <a
                      key="Back to app"
                      onClick={()=>dispatch(setCurrentPage('plan'))}
                      href="/plan"
                      className={
                        "-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-text-bg "
                      }
                  >
                    Back to app
                  </a>
                  {settingsNavigation.map((item) => (
                      <button
                          key={item.name}
                          onClick={()=>setActiveTab(item.name)}
                          className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-text-bg "
                      >
                        {item.name}
                      </button>
                  ))}
                </div>
              </Dialog.Panel>
            </Dialog>
          </aside>

          <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
            <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
              <div>
                {activeTab === 'Calendar settings' &&
                    <CalendarSettings />
                }

                <h2 className="font-semibold text-lg text-text-bg">
                  {activeTab}
                </h2>
                <dl className="mt-6 space-y-6 divide-y divide-text-bg-inverse border-t border-text-bg-inverse text-sm leading-6">
                  {settings_dummylist.filter(setting => setting.category === activeTab && setting.enabled).map(configurableSetting =>
                      <div>
                        {configurableSetting.display === 'toggle' &&
                            <Switch.Group as="div" className="flex pt-6">
                              <Switch.Label as="dt" className="flex-auto pr-6 font-medium text-text-bg sm:w-64" passive>
                                {configurableSetting.name}
                                <p className="mt-1 text-sm leading-6 text-text-bg/60">
                                  {configurableSetting.description}
                                </p>
                              </Switch.Label>
                              <dd className="flex flex-none items-center justify-end">
                                <Switch
                                    checked={settings[configurableSetting.machine_name]}
                                    key={configurableSetting.machine_name}
                                    onChange={()=>dispatch(updateSetting({supabase:supabase,setting_name:configurableSetting.machine_name,setting_value: !settings[configurableSetting.machine_name]}))}
                                    className={classNames(
                                        settings[configurableSetting.machine_name]
                                            ? "bg-chip-bg"
                                            : "bg-dark-grey",
                                        "flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-text-bg/30 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-chip-bg",
                                    )}
                                >
                      <span
                          aria-hidden="true"
                          className={classNames(
                              settings[configurableSetting.machine_name]
                                  ? "translate-x-3.5"
                                  : "translate-x-0",
                              "h-4 w-4 transform rounded-full bg-toggle-bg shadow-sm ring-1 ring-text-bg/50 transition duration-200 ease-in-out",
                          )}
                      />
                                </Switch>
                              </dd>
                            </Switch.Group>
                        }
                        {configurableSetting.display !=='toggle' &&
                            <div className="pt-6 sm:flex flex justify-between">
                              <dt className="font-medium text-text-bg sm:w-64 sm:flex-auto sm:pr-6">
                                {configurableSetting.name}
                                <p className="mt-1 text-sm leading-6 text-text-bg/60">
                                  {configurableSetting.description}
                                </p>
                              </dt>
                              <dd className="mt-1 gap-x-6 sm:mt-0 sm:flex-none 2xl:min-w-20">

                                {configurableSetting.machine_name === 'timezone' &&

                                    <TimezoneSelect value={(settings.timezone!==null?settings.timezone:"")}
                                                    onChange={(e) =>handleTimezoneChange(e)}
                                                    key={configurableSetting.machine_name}
                                                    unstyled
                                                    isSearchable={false}
                                                    isMulti={false}
                                                    classNames={{
                                                      input: () => "[&_input:focus]:ring-transparent ",
                                                      valueContainer: () => "py-px px-2",
                                                      indicatorSeparator: () => "items-stretch w-px bg-text-bg-inverse  mx-2",
                                                      control: () => "focus:outline-none text-text-selected-day form-input outline-none px-3 py-2 rounded border-text-bg-inverse bg-input-bg ",
                                                      menu: () => "bg-input-bg",
                                                      option: ({ isFocused, isSelected }) =>
                                                          classNames(
                                                              isFocused && `hover:cursor-pointer hover:bg-chip-bg `,
                                                              isSelected && "bg-chip-bg",
                                                              "text-text-selected-day px-3 py-2 "
                                                          ),
                                                    }}
                                    />
                                }
                                {configurableSetting.machine_name !== 'timezone' &&
                                    <>
                                    <Select name={configurableSetting.machine_name}
                                            id={configurableSetting.machine_name}
                                            key={configurableSetting.machine_name}
                                            defaultValue={getDefaultValue(configurableSetting.displayOptions,settings[configurableSetting.machine_name]) !== null?getDefaultValue(configurableSetting.displayOptions,settings[configurableSetting.machine_name]):''}
                                            onChange={(e) =>handleSettingChange(e,configurableSetting.machine_name)}
                                            options={configurableSetting.displayOptions}
                                            isSearchable={false}
                                            isMulti={false}
                                            unstyled
                                            classNames={{
                                              input: () => "[&_input:focus]:ring-transparent ",
                                              valueContainer: () => "py-px px-2",
                                              indicatorSeparator: () => "items-stretch w-px bg-text-bg-inverse  mx-2",
                                              control: () => "focus:outline-none text-text-selected-day form-input outline-none px-3 py-2 rounded border-text-bg-inverse bg-input-bg",
                                              menu: () => "bg-input-bg",
                                              option: ({ isFocused, isSelected }) =>
                                                  classNames(
                                                      isFocused && `hover:cursor-pointer hover:bg-chip-bg `,
                                                      isSelected && "bg-chip-bg ",
                                                      "text-text-selected-day px-3 py-2 "
                                                  ),
                                            }}
                                    /></>
                                }
                              </dd>
                            </div>
                        }
                      </div>
                  )}
                  {activeTab === 'Account' &&
                      <>

                        <div>
                          <div className="pt-6 sm:flex flex justify-between">
                            <dt className="font-medium text-text-bg sm:w-64 sm:flex-auto sm:pr-6">
                              Logout from all devices
                              <p className="mt-1 text-sm leading-6 text-text-bg/60">
                                You will be logged out from the application on all connected devices.
                              </p>
                            </dt>
                            <dd className="mt-1 gap-x-6 sm:mt-0 flex sm:flex-none 2xl:min-w-20 flex">

                              <button className="p-2 rounded-lg bg-chip-bg text-chip-text hover:bg-chip-accent font-semibold mt-5" onClick={()=>signOutGlobal()}>
                                Sign out
                              </button>
                            </dd>
                          </div>
                        </div>

                        <div>
                          <div className="pt-6 sm:flex flex justify-between">
                            <dt className="font-medium text-text-bg sm:w-64 sm:flex-auto sm:pr-6">
                              Update password
                              <p className="mt-1 text-sm leading-6 text-text-bg/60">
                                You will be logged out from the application on all logged in devices and will receive a password reset link in your mailbox.
                              </p>
                            </dt>
                            <dd className="mt-1 gap-x-6 sm:mt-0 flex sm:flex-none 2xl:min-w-20 flex">

                              <button className="p-2 rounded-lg bg-chip-bg text-chip-text hover:bg-chip-accent font-semibold mt-5" onClick={()=>resetPassword()}>
                                Reset password
                              </button>
                            </dd>
                          </div>
                        </div>
                        <div>
                          <div>


                          <div className="pt-6 sm:flex flex justify-between">
                            <dt className="font-medium text-text-bg sm:w-64 sm:flex-auto sm:pr-6">
                             Request data
                              <p className="mt-1 text-sm leading-6 text-text-bg/60">
                                A zip file containing all of your data stored on our servers will be sent to your mailbox. Please allow up to 48 hours for this data to compile.
                              </p>
                            </dt>
                            <dd className="mt-1 gap-x-6 sm:mt-0 flex sm:flex-none 2xl:min-w-20 flex">

                              <button className="p-2 rounded-lg bg-chip-bg text-chip-text hover:bg-chip-accent font-semibold mt-5" onClick={()=>requestUserData()}>
                                Request data
                              </button>
                            </dd>
                          </div>
                            {userDataRequests.length > 0 &&
                                <div className="ml-10">
                                  <span className="font-semibold text-base text-text-bg">
                                    Data requests
                                  </span>
                                  <dl className="border-t border-text-bg-inverse text-sm leading-6">
                                    {userDataRequests.map(request =>
                                        <div>
                                          <div className="pt-3 sm:flex flex justify-between">
                                            <dt className="font-medium text-text-bg sm:w-64 sm:flex-auto sm:pr-6">
                                              {format(new Date(request.created_at), 'dd/MM/yyyy HH:mm')}
                                            </dt>
                                            <dd>
                                              {request.status}
                                              {request.status === 'completed' &&
                                                  format(new Date(request.completed_at), 'dd/MM/yyyy HH:mm')
                                              }
                                            </dd>
                                          </div>
                                        </div>
                                    )}
                                  </dl>
                                </div>
                            }
                        </div>
                      </div>
                      <div>
                        <div className="pt-6 sm:flex flex justify-between">
                          <dt className="font-medium text-text-bg sm:w-64 sm:flex-auto sm:pr-6">
                             Delete account
                            <p className="mt-1 text-sm leading-6 text-text-bg/60">
                              Warning: deleting your account will delete all your data. This action cannot be undone.
                            </p>
                          </dt>
                          <dd className="mt-1 gap-x-6 sm:mt-0 sm:flex-none 2xl:min-w-20">
                            <button id="delete_account_button" className=" p-2 rounded-lg bg-color-danger text-primary-1 font-semibold text-base  hover:bg-color-danger/60">
                              Delete  account
                            </button>
                          </dd>
                        </div>
                      </div>
                      </>
                  }
                </dl>
              </div>

            </div>
          </main>
        </div>
      </div>

  );
}
