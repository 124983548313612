import React, { useState } from "react";

import ReactDOM from "react-dom";

import { joinUrl } from "../../../../helpers";
import {LuFacebook, LuInstagram} from "react-icons/lu";
import { toastOptions } from "../../../../Constants";
import { toast } from "react-toastify";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { apiSubscribeNewsletter } from "../../../../../App/api/BlogApi";

const base_url = "https://wwww.taskz.io";
const navigation = {
  information: [
    { name: 'Privacy Policy', href: '/privacy' },
    { name: 'Terms & Conditions', href: '/termsofservice' },
    //{ name: 'Status', href: '#' },
  ],
  support: [
    //{ name: "Pricing", href: "pricing" },
    //{ name: "Documentation", href: "#" },
    //{ name: "Guides", href: "#" },
  ],
  company: [
    { name: "About", href: "/about" },
    { name: "Blog", href: "/blog" },
    // { name: 'Jobs', href: '#' },
    // { name: 'Press', href: '#' },
    //{ name: 'Partners', href: '#' },
  ],
  social: [
    {
      name: 'Facebook',
      href: '#',
      icon: LuFacebook,
    },
    {
      name: 'Instagram',
      href: '#',
      icon: LuInstagram,
    },
    {
      name: 'X',
      href: '#',
      icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
          </svg>
      ),
    },

  ],
};
function Footer() {
  const [email, setEmail] = useState('')
  const supabase = useSupabaseClient()
  async function subscribeNewsletter(){
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if(email === '' || !email.match(validRegex)){
      toast.error('Please enter a valid email address',toastOptions)
    }
    else{
      var res = await apiSubscribeNewsletter(supabase,email)
      setEmail('')
      if(res.error){
        console.log(res.error)
        if(res.error.code === '23505'){
          toast.error('You are already subscribed',toastOptions)
        }
        else{
          toast.error(res.details,toastOptions)
        }

      }
      else{
        toast.success('Subscribed successfully',toastOptions)
      }
    }
  }
  return (
    <footer className="" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <img
              className="h-8 w-auto"
              src="Icon_32x32.png"
              alt="Taskz"
          />
          <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-text-bg">
                  Information
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.information.map((item) => (
                    <li key={item.name}>
                      <a
                        href={joinUrl(base_url, item.href)}
                        className="text-sm leading-6 text-text-bg hover:text-chip-accent"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-text-bg">
                  Support
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.support.map((item) => (
                    <li key={item.name}>
                      <a
                        href={joinUrl(base_url, item.href)}
                        className="text-sm leading-6 text-text-bg hover:text-text-chip-accent"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-text-bg">
                  Company
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <a
                        href={joinUrl(base_url, item.href)}
                        className="text-sm leading-6 text-text-bg hover:text-chip-accent"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16 border-t border-text-bg/60 pt-8 sm:mt-20 lg:mt-24 lg:flex lg:items-center lg:justify-between">
          <div>
            <h3 className="text-sm font-semibold leading-6 text-text-bg">
              Subscribe to our newsletter
            </h3>
            <p className="mt-2 text-sm leading-6 text-text-bg">
              The latest news, articles, and resources, sent to your inbox
              weekly.
            </p>
          </div>
          <form className="mt-6 sm:flex sm:max-w-md lg:mt-0">
            <label htmlFor="email-address" className="sr-only">
              Email address
            </label>
            <input
              type="email"
              name="email-address"
              id="email-address"
              autoComplete="email"
              required
              className="w-full bg-input-bg min-w-0 appearance-none rounded-md border-0 px-3 py-1.5 text-base text-text-selected-day shadow-sm ring-1 ring-inset ring-text-bg-inverse placeholder:text-text-bg focus:ring-2 focus:ring-inset focus:ring-chip-bg sm:w-56 sm:text-sm sm:leading-6"
              placeholder="Enter your email"
            />
            <div className="mt-4 sm:ml-4 sm:mt-0 sm:flex-shrink-0">
              <button
                type="submit"
                className="flex w-full items-center justify-center rounded-md bg-chip-bg px-3 py-2 text-sm font-semibold shadow-sm hover:bg-chip-bg focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-chip-bg"
              >
                Subscribe
              </button>
            </div>
          </form>
        </div>
        <div className="mt-8 border-t border-text-bg/60 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2">
            {navigation.social.map((item) => (
              <a
                key={item.name}
                href={joinUrl(base_url, item.href)}
                className="text-text-bg hover:text-chip-bg"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <p className="mt-8 text-xs leading-5 text-text-bg md:order-1 md:mt-0">
            &copy; 2024 Taskz, Inc. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

if (document.getElementById("footer")) {
  ReactDOM.render(<Footer />, document.getElementById("footer"));
}
