import React, {Fragment, useContext, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {LuAlarmClock, LuCalendar, LuClock} from "react-icons/lu";
import {TbExclamationMark} from "react-icons/tb";
import {classNames, getMobname} from "../../../../helpers";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {format, isDate} from "date-fns";
import {useSupabaseClient, useUser} from '@supabase/auth-helpers-react'
import {apiCreateTask} from "../../../../../App/api/tasksApi";
import {LevelContext} from "../../../../../App/utils/Contexts/LevelContext";
import {battleVictory, calcDamage} from "../../../../../App/utils/CombatUtils";
import {toastOptions} from "../../../../Constants";
import {apiEndFight, apiUpdateMobHealth} from "../../../../../App/api/Quest/QuestApi";
import {CombatContext} from "../../../../../App/utils/Contexts/CombatContext";
import {QuestContext} from "../../../../../App/utils/Contexts/QuestContext";
import {NotificationContext} from "../../../../../App/utils/Contexts/NotificationContext";


export default function TaskCreate({day,closeModal}){
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [due, setDue] = useState(null)
    const [dueOpen, setDueOpen] = useState(false)
    const scheduled = format(new Date(day),"yyyy-MM-dd")
    const scheduledTime = day.typeof === Date? format(new Date(day),"HH:mm:ss"):null
    const [urgent, setUrgent] = useState(false)
    const [important, setImportant] = useState(false)
    const [category, setCategory] = useState(null)
    const {addExp} = useContext(LevelContext)
    const {combat,hasActiveCombat,character,setUpdated,updated} = useContext(CombatContext)
    const {updateQuests} = useContext(QuestContext)
    const [hours,setHours] = useState(0);
    const [minutes,setMinutes] = useState(0);
    const user=useUser()
    const supabase = useSupabaseClient()
    const {addGameNotification} = useContext(NotificationContext)

    async function validateForm() {
        var continuepost = true
        if (title === '') {
            toast("Title is required")
            continuepost = false
            return
        }
        if (due !== null && isDate(new Date(due)) === false) {
            toast("Due date is not valid")
            continuepost = false
            return
        }
        let test = format(new Date(0, 0, 0, hours, minutes, 0), 'HH:mm:ss')
        if(continuepost){
            const task = {
                'title': title,
                'user': user.id,
                'description': description,
                'due': due,
                'scheduled': scheduled,
                'scheduled_time': scheduledTime,
                'timeEstimate': test,
                'done': false,
                'urgent': urgent,
                'important': important,
            }

            if(hasActiveCombat ){
                if(combat.weakness === "plan"){
                    var damage = calcDamage({strength:character.strength,wisdom:character.wisdom,intelligence:character.intelligence})
                    var cHealth = combat.current_health - damage
                    if(cHealth <= 0){
                        var looti = await battleVictory(supabase,combat,character)
                        updateQuests("kill",combat.mob_name)
                        toast.success("You've defeated the "+combat.mob_name,toastOptions)
                        var items = []
                        looti.map((item)=>{
                            items.push({item:item.item.id,amount:item.amount})
                        })

                        var notification = {
                            title: "Battle won!",
                            content: "You've won the battle against the "+getMobname(combat.mob_name)+ "and received the following rewards",
                            linked_items: JSON.stringify(items),
                            icon: "combat"
                        }
                        addGameNotification(notification)
                        await apiEndFight(supabase,combat.id)
                        await addExp(20)
                    }
                    else{
                        await apiUpdateMobHealth(supabase,combat.id,cHealth)
                        task.combat_damage_dealt = true
                    }
                    setUpdated(!updated)
                }
            }



            await apiCreateTask(supabase,task)
            await addExp(5)
            closeModal(true)
        }



    }
    function handleOnChange(value){
        if(Number.isNaN(value) || value<0) return;
        const newhours = Number(hours) + Math.floor(value/60);
        const newminutes = value - (Math.floor(value/60) * 60);
        setHours(newhours);
        setMinutes(newminutes);
    }
    function handleOnChangeHour(value){
        if(Number.isNaN(value) || value<0) return;
        setHours(value);
    }
    function toggleDuedate(){
        if(dueOpen){
            setDue(null)
        }
        setDueOpen(!dueOpen)
    }
     return (
         <>
             <Transition.Root show={true} as={Fragment}>
                 <Dialog as="div" className="relative z-10 " onClose={()=>closeModal()}>
                     <Transition.Child
                         as={Fragment}
                         enter="ease-out duration-300"
                         enterFrom="opacity-0"
                         enterTo="opacity-100"
                         leave="ease-in duration-200"
                         leaveFrom="opacity-100"
                         leaveTo="opacity-0"
                     >
                         <div className="fixed inset-0 bg-dark-grey bg-opacity-75 transition-opacity" />
                     </Transition.Child>

                     <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                         <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                             <Transition.Child
                                 as={Fragment}
                                 enter="ease-out duration-300"
                                 enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                 enterTo="opacity-100 translate-y-0 sm:scale-100"
                                 leave="ease-in duration-200"
                                 leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                 leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                             >
                                 <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-card-bg px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                     <div>

                                             <div className="mt-3 text-center sm:mt-5">
                                                 <div className=" text-text-bg  font-semibold dialog-title items-center flex-1 flex-col justify-between ">
                                                     <div className="mt-2">
                                                         <div className="mt-2 flex flex-1 items-center justify-evenly">
                                                             <input
                                                                 id="title"
                                                                 name="title"
                                                                 type="text"
                                                                 autoComplete="text"
                                                                 placeholder="Task"
                                                                 onChange={(e) => setTitle(e.target.value)}
                                                                 required
                                                                 autoFocus={true}
                                                                 className="flex-1 mr-10 block rounded-md border-0 py-1.5 text-text-important shadow-sm ring-1 bg-input-bg ring-inset ring-card-ring placeholder:text-text-bg/50 focus:ring-inset focus:ring-chip-bg sm:text-sm sm:leading-6"
                                                             />
                                                             <div className="flex items-center justify-center">
                                                                 <LuClock
                                                                     className="h-4 w-4 shrink-0 text-text-bg"
                                                                     aria-hidden="true"
                                                                 />
                                                                 <input
                                                                     id="hours"
                                                                     name="hours"
                                                                     type="text"
                                                                     value={hours}
                                                                     onChange={(e) =>handleOnChangeHour(e.target.value)}
                                                                     className="block w-10 text-center px-0 py-1 bg-modal-bg placeholder:text-text-bg/50 border-0 focus:ring-0"
                                                                 />
                                                                 <span className="">:</span>
                                                                 <input
                                                                     id="minutes"
                                                                     name="minutes"
                                                                     type="text"
                                                                     onChange={(e) =>handleOnChange(e.target.value)}
                                                                     value={minutes}
                                                                     className="block w-10 text-center px-0 py-1 bg-modal-bg placeholder:text-text-bg/50 border-0 focus:ring-0"
                                                                 />
                                                             </div>
                                                         </div>
                                                     </div>
                                                     <div className="mt-4">
                                                         <div className="mt-2 flex flex-1 items-center justify-between">
                                                             <div className="flex min-w-72 mx-9 items-center justify-between">
                                                                 <div className="flex  items-center cursor-pointer" onClick={()=>setImportant(!important)}>
                                                                     <TbExclamationMark
                                                                         className={classNames(important ? "text-color-danger" : "text-text-bg", "h-5 w-5 shrink-0")}
                                                                         aria-hidden="true"
                                                                     />
                                                                 </div>
                                                                 <div className="flex  px-4 items-center cursor-pointer" onClick={()=>setUrgent(!urgent)}>
                                                                     <LuAlarmClock
                                                                         className={classNames(urgent ? "text-color-danger" : "text-text-bg", "h-5 w-5 shrink-0")}
                                                                         aria-hidden="true"
                                                                     />
                                                                 </div>
                                                                 <div className="flex  items-center cursor-pointer min-w-40" >

                                                                     <LuCalendar onClick={()=>toggleDuedate()}
                                                                         className="h-5 w-5 shrink-0 text-text-bg mr-2"
                                                                         aria-hidden="true"
                                                                     />
                                                                     {dueOpen &&
                                                                         <input
                                                                             id="due"
                                                                             name="due"
                                                                             type="date"
                                                                             placeholder="Due?"
                                                                             onChange={(e) => setDue(e.target.value)}
                                                                             className="items-center block rounded-md border-0 py-1.5 shadow-sm ring-1 bg-input-bg ring-inset ring-card-ring placeholder:text-text-bg/50 focus:ring-inset focus:ring-chip-bg sm:text-sm sm:leading-6"
                                                                         />
                                                                     }

                                                                 </div>
                                                             </div>
                                                             <div className="w-20 py-2 flex flex-1 items-start justify-start">
                                                                <span className="text-sm font-normal px-0 mx-0 w-20 flex-1 items-start justify-start">category</span>
                                                             </div>

                                                         </div>
                                                     </div>
                                                     <div className="mt-2">
                                                         <input
                                                             id="description"
                                                             name="description"
                                                             type="text"
                                                             autoComplete="description"
                                                             placeholder="description"
                                                             onChange={(e) => setDescription(e.target.value)}
                                                             className="block w-full rounded-md border-0 py-1.5 text-text-important shadow-sm ring-1 bg-input-bg ring-inset ring-card-ring placeholder:text-text-bg/50 focus:ring-inset focus:ring-chip-bg sm:text-sm sm:leading-6"
                                                         />
                                                     </div>
                                                     <div className="mt-4 flex flex-1 justify-center items-center">
                                                         <div>
                                                             <button onClick={()=>validateForm()}
                                                                 className="block flex-1 px-3 py-1.5 text-m font-semibold leading-6 bg-chip-bg text-chip-text hover:bg-chip-accent rounded-lg outline-none shadow-md hover:text-chip-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-chip-accent">
                                                                 Save task
                                                             </button>
                                                         </div>

                                                     </div>

                                                 </div>
                                             </div>

                                     </div>
                                     <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">

                                     </div>
                                 </Dialog.Panel>
                             </Transition.Child>
                         </div>
                     </div>
                 </Dialog>
             </Transition.Root>
         </>
    )
 }
