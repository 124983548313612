
import { LuPlus } from "react-icons/lu";
import { format } from "date-fns";
import {Draggable, Droppable} from "react-beautiful-dnd";
import Task from "../../Main_page/Tasks view/Task";
export default function Today({ date, tasks, openTaskModal, openCreateTaskModal}) {
    return (
        <div className="flex flex-none flex-col w-80 clearfix">
            <div className="weekday-container flex flex-1 flex-col py-2.5 px-5">
                <div className="date-label-container p-2">
                    <div className="date flex-1">
                        <div>
                          <span className="cursor-pointer text-chip-bg text-xl font-semibold">
                            {format(date, "iiii")}
                          </span>
                        </div>
                        <span className="cursor-pointer text-chip-bg text-lg">{format(date, "MMMM, d")}
                        </span>
                    </div>
                </div>
                <div className="task-list-container overflow-y-hidden relative flex flex-1">
                    <div className="tasks-container relative flex flex-col w-80">
                        <div className="Task-add-container relative mt-8">
                            <div className="flex justify-start text-text-bg bg-card-bg px-2.5 rounded-2xl shadow-md ring-1 ring-inset ring-card-ring overflow-x-hidden my-1 p-2 min-h-10"
                                 onClick={() => openCreateTaskModal(date)}>

                                <LuPlus
                                    className="h-6 w-6 shrink-0 flex text-chip-accent cursor-pointer hover:text-chip-hover mr-4"
                                    aria-hidden="true"
                                />
                                <span className="cursor-pointer flex text-lg font-semibold text-text-faded">Add Task</span>
                            </div>
                        </div>
                        <Droppable droppableId={"tasklist-"+format(date,'dd/MM/yyyy')} renderClone={(provided,snapshot,rubric)=> (
                            <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                <Task  task={tasks[rubric.source.index]} openTaskModal={openTaskModal} />
                            </div>
                        )}>
                            {(provided) => (
                                <div  ref={provided.innerRef} {...provided.droppableProps} className="tasks-container overflow-x-hidden overflow-y-hidden relative flex-1">
                                    {tasks.map((item,index) => (
                                        <Draggable key={item.id} draggableId={"task-"+item.id} index={index}>
                                            {(provided, snapshot)=> (
                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                    <Task  task={item} openTaskModal={openTaskModal} />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                </div>
                            )}
                        </Droppable>
                    </div>
                </div>
            </div>
        </div>
    );
}
