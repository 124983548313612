

export default function DailyGoal({notes,goal,setGoal = ()=>{},setNotes= ()=>{},onSavePlan= ()=>{}, isReview = false}){

    return(
        <div className="dailygoal-container grid grid-cols-1 grid-rows-3 h-full p-6">
            <div className="h-full flex flex-col">
                <span className="text-lg text-text-bg font-semibod flex justify-center mb-4">Main goal</span>
                <textarea
                    name="goal"
                    id="goal"
                    className="mb-4 flex-1 resize-none block w-full rounded-md border-0 py-1.5 bg-input-bg md:text-base text-dark-grey shadow-sm  ring-0 border-transparant focus:ring-0 focus:outline-none sm:text-sm sm:leading-6"
                    defaultValue={goal}
                    onChange={(e)=>setGoal(e.target.value)}
                />
            </div>
            <div className="row-span-2 flex flex-col mb-12">
                <span className="text-lg text-text-bg font-semibod flex justify-center m-4">Notes</span>
                <textarea
                    name="notes"
                    id="notes"
                    className="resize-none flex-1 block w-full rounded-md border-0 py-1.5 bg-input-bg md:text-base text-dark-gray shadow-sm ring-0 border-transparant focus:ring-0 focus:outline-none sm:text-sm sm:leading-6"
                    defaultValue={notes}
                    onChange={(e)=>setNotes(e.target.value)}
                />
                {!isReview &&
                    <div className="flex justify-center">
                        <button className="bg-chip-bg text-chip-text m-2 p-2 rounded-lg hover:bg-chip-accent" onClick={()=>onSavePlan()}>Save plan</button>
                    </div>
                }
            </div>



        </div>
    )
}
