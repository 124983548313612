import {getUserID} from "../ApiConfig";



export async function apiGetUserSettings(supabase) {
    const id = await getUserID(supabase)
    if (!id) return {}
    const {data: settings, error} = await supabase.from('Settings_user').select('*').eq('user', id)
    if(!error){
        if(settings.length === 0){
            const {settings, error} = await supabase.from('Settings_user').insert([{user: id}]).select()
            console.log(settings)
            return settings[0]
        }
        return settings[0]
    }
    return {
        // TODO: error handling
    }
}

export async function apiUpdateUserSetting(supabase,setting){

    const {res, error} = await supabase.from('Settings_user').update(setting).select('*')
    if (!error && res) {
        return true;
    }
    return {

        // TODO: error handling
    }
}
