import {addDays, addHours, addMinutes, format, intervalToDuration} from "date-fns";
import {apiGetMobLoottable} from "../api/Quest/QuestApi";
import {rollLoottable, rollLoottableCompanion, sortLoot} from "./LoottableUtils";
import {apiUpdateGemstones, apiUpdateGold} from "../api/characterApi";
import {apiAddStackable, apiGetItemID, apiGetStackables, apiUpdateStackable} from "../api/Inventory/InventoryApi";
import {apiAddEgg, apiGetCompanionLoottable, apiGetCompanionSpecies} from "../api/Companions/CompanionAPI";


export function getCurrentPercentage(current, total) {
    return (current/total) * 100;
}
export function getPercentageRemaining(percent) {
    return 100 - percent;
}

export function calcDamage(userstats){
    var userdamage = (Math.ceil(userstats.strength/2) + Math.ceil(userstats.wisdom/2) + Math.ceil(userstats.intelligence/2))+(userstats.intelligence); // TODO check user class for bonus stat
    return userdamage;
}
export function getEndTime(combat,userDex){
    if(!combat || !combat.mob)return new Date()
    var endTime;
    var Startdate = new Date(combat.created_at);
    if(combat.mob.limit_type === "hour"){
        endTime = addHours(Startdate,combat.limit_amount);
    }
    else{
        endTime = addMinutes(Startdate,combat.limit_amount);
    }
    endTime = addMinutes(endTime, (Math.floor(userDex/5)))
    return endTime;
}
export function calcBattleTimeRemaining(combat,userDex){
    if(!combat.mob)return intervalToDuration({start:new Date(),end:new Date()})
    var started = combat.created_at;
    var limit = combat.mob.limit_type;
    var endTime;
    var Startdate = new Date(started);
    if(limit === "hour"){
        endTime = addHours(Startdate,combat.limit_amount);
    }
    else{
        endTime = addMinutes(Startdate,combat.limit_amount);
    }
    endTime = addMinutes(endTime, (Math.floor(userDex/5)))
    var diff = intervalToDuration({start: new Date(),end:endTime});
    return diff;
}
export function format_time(time){
    var hours =""
    var minutes = ""
    var seconds = ""
    if(time === null) return ""
    if(time.hours !== undefined && time.hours > 0){
        hours = time.hours<10?"0"+time.hours:time.hours
    }
    if(time.minutes !== undefined && time.minutes > 0){
        minutes = time.minutes<10?"0"+time.minutes:time.minutes
    }
    if(time.minutes !== undefined && time.minutes < 0 && time.seconds !== undefined && time.seconds > 0){
        minutes = "00"
    }
    if(time.seconds !== undefined && time.seconds > 0){
        seconds = time.seconds<10?"0"+time.seconds:time.seconds
    }
    var format = ""
    if(hours !== ""){
        format += hours + ":"
    }
    if(minutes !== ""){
        format += minutes
    }

    if(hours === ""){
        format += ":" + seconds
    }
    if(hours === "" && minutes === "" && seconds === ""){
        return "__:__"
    }
    return format
}

export async function battleVictory(supabase, combat,character){
    const loot = await apiGetMobLoottable(supabase,combat.mob.id)
    const stackables = await apiGetStackables(supabase)

    const lootItems = rollLoottable(loot.pools,loot.roll_min, loot.roll_max, loot.roll_type)
    const looti = sortLoot(lootItems)


    looti.map((item)=> {
        var types = item.item.id.split(":")
        if(types[0] === "currency"){
            if(types[1] === "gold"){
                apiUpdateGold(supabase, (character.gold + item.amount),character.id)
            }
            if(types[1] === "gemstone"){
                apiUpdateGemstones(supabase, (character.gemstone + item.amount),character.id)
            }
        }
        if(types[0] === "gear"){

        }
        if(types[0] === "consumable"){

        }
        if(types[0] === "companion"){
            console.log("companion loot",item)
            var companionLoottableId = item.item.nbt.companion_roll_type_pool_id
            handleCompanionLoot(supabase,companionLoottableId)
        }
        if(types[0] === "mob_drops"){
            handleStackableLoot(item,stackables,supabase, types[0],types[1])
        }
    })
    return looti

}
export async function handleCompanionLoot(supabase,tableId){
    var loottable = await apiGetCompanionLoottable(supabase,tableId)
    var chosenEgg = rollLoottableCompanion(loottable.pool,"uniform")
    var species = await apiGetCompanionSpecies(supabase,chosenEgg)
    var started_hatching = new Date()
    if(species.hatchtime_type === "hour"){
        started_hatching = addHours(started_hatching,species.hatchtime_time)
    }
    else if(species.hatchtime_type === "day"){
        started_hatching = addDays(started_hatching,species.hatchtime_time)
    }
    var egg = {
        species:chosenEgg,
        hatchdate: format(started_hatching,"yyyy-MM-dd"),
        hatchtime: format(started_hatching,"HH:mm:ss")
    }
    apiAddEgg(supabase,egg)
}
export async function handleStackableLoot(item,stackables,supabase,type,id){
    var finder = stackables.find((stackable)=> stackable.item.type === type && stackable.item_name === id)
    if(finder){
        var amount = finder.count + item.amount
        apiUpdateStackable(supabase,finder.item_name,amount)
    }
    else{
        var itemID = await apiGetItemID(supabase,id)
        var stackable = {
            item:itemID,
            item_name:id,
            count:item.amount,
        }
        apiAddStackable(supabase,stackable)

    }
}

