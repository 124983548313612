import React from 'react'
import {classNames} from "../../../../../helpers";
import {format} from "date-fns";
import {Draggable} from "react-beautiful-dnd";
import {convertTimeToFormat} from "../../../../../../App/utils/TimeUtils";

export default class MonthEvent extends React.PureComponent{

    returnEventsClassName(position, color) {
        const { inactive } = this.props;
        var classes = "dayCellEvent select-none block text-left w-full text-ellipsis rounded-lg mb-1 p-1.5 "
        if(position){
            classes = classNames(classes, position === 'start'? "eventStart": position === 'middle'? "eventMiddle": "eventEnd", inactive? "eventInactive": "")
        }
        classes = classNames(classes, color?'':'lg:bg-default-task-bg text-text-selected-day')
        return classes
    }
    countWords(str) {
        return str.trim().split(/\s+/).length;
    }
    returnEventName(summary, position,color,fromDate,desktop) {

        let showTitle = false;
        if (position) {
            if (position === 'start') {
                showTitle = true;
            }
        } else {
            showTitle = true;
        }

        if (showTitle) {
            return (
                <div className='flex'>
                    <div style={{backgroundColor:color.backgroundColor}}
                        className={classNames(color?'':'bg-default-task-bg',"lg:hidden w-0.5 rounded-sm block flex-none")}></div>
                    <span style={{color: desktop?color.foregroundColor:''}}
                        className={classNames(color?'':' lg:text-text-selected-day','hidden lg:block eventTitle text-text-bg text-xs leading-tight md:font-semibold whitespace-nowrap mr-2')}>{convertTimeToFormat(this.props.timeformat,format(fromDate,"HH:mm"))}</span>
                    <span style={{color: desktop?color.foregroundColor:''}}
                        className={classNames(this.countWords(summary)>1?"line-clamp-2":"line-clamp-1",color?'':' lg:text-text-selected-day', "eventTitle text-text-bg flex-1 text-xs leading-tight md:font-semibold  text-ellipsis overflow-hidden pl-1")}>{summary}</span>
                </div>
                )
         }
    }
    render() {

        const { color, position, summary,isDesktop } = this.props;
        const fromDate = new Date(this.props.start)
        return (
            <Draggable draggableId={this.props.id + "-" + format(fromDate,"HH:mm")+"-"+(this.props.isTaskz?"taskz":"provider")} index={this.props.index}>
                {(provided,snapshot) => (
                    <div

                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{backgroundColor:isDesktop?color.backgroundColor:'',...provided.draggableProps.style,} }
                        className={this.returnEventsClassName(position,color)}
                        onClick={this.props.onClick}>
                            {this.returnEventName(summary, position,color,fromDate,isDesktop)}
                    </div>
                )}

            </Draggable>
        );
    }


}
