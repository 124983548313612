import {classNames} from "../../../../helpers";
import {useSelector} from "react-redux";


export default function WorkloadTimeline({planned}){
    const settings=useSelector(state => state.settings).settings
    const wThres = settings.workload_threshold
    const displayed = wThres+2

    function getTaskTotalHours(){
        return Math.floor(planned/60)
    }
    function getTaskTotalMinutes(){
        return planned- ((Math.floor(planned/60))*60)
    }
    function getWidth(){
        return 100/displayed + '%'
    }

    function getBarcolor(i){
        if(i>=getTaskTotalHours()){
            return "bg-text-bg"
        }
        if( getTaskTotalHours()>wThres-2 && getTaskTotalHours()<=wThres){
            return "bg-color-warning"
        }

        if(getTaskTotalHours()>wThres){
            return "bg-color-danger"
        }
        return "bg-chip-accent"
    }


    return (
        <div className="flex flex-1 px-20 justify-center w-full">
            <ul className="timeline flex flex-1" id='timeline'>
            {[...Array(displayed)].map((x, i) =>
                <div style={{width:getWidth()}}>
                    <div className="flex flex-1 justify-end text-text-bg">{i+1}</div>
                    <hr className={classNames(getBarcolor(i))} />
                </div>
            )}
        </ul>
            <span className="w-20 ml-6 text-text-bg">{getTaskTotalHours()} h {getTaskTotalMinutes()} m</span>
        </div>


    )}
