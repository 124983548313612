
import {format} from "date-fns";

export function convertTimeToFormat(tformat,time,minutes = null){
    var timeHours = time
    var timeMinutes = minutes
    if(minutes === null){
        var temp = time.split(':')
        timeHours = Number(temp[0])
        timeMinutes = Number(temp[1])
    }
    var date = new Date(1997,2,1,timeHours,timeMinutes)
    if(tformat === "HH"){
        return format(date,"HH:mm")
    }
    else{
        return format(date,"hh:mm a")
    }
}
