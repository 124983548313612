import {getUserID} from "../ApiConfig";


export async function apiGetKnownBugs(supabase){
    const {data: bugs, error} = await supabase.from('Known_bugs').select('').neq('status','Solved').order('created_at',{ascending:false})
    if(!error){
        console.log(bugs)
        return bugs
    }
    return {
        // TODO: error handling
    }
}
 export async function apiInsertBug(supabase,description){
     const id = await getUserID(supabase)
     if (!id) return {}

    const {res, error} = await supabase.from('Bugs').insert({content:description,user:id})
    if (!error) {
        return res
    }
    return {}
}
