import {createContext, useContext, useEffect, useState} from "react";
import {DragDropContext} from "react-beautiful-dnd";
import {useSession, useSupabaseClient} from "@supabase/auth-helpers-react";
import {
    apiGetTask,
    apiUpdateTaskDailycalResize,
    apiUpdateTaskGID,
    apiUpdateTaskScheduled,
    apiUpdateTaskToBacklog
} from "../App/api/tasksApi";
import {checkToken, deletegCalEvent, insertgCalEvent, setTime} from "../App/utils/CalendarUtils";
import {formatRFC3339} from "date-fns";
import {toast} from "react-toastify";

export const sidebarnav_context = createContext(null);

export const Selected_day_context = createContext();

export const SelectedDayContextProvider = ({ children }) => {
  const [selectedDay, setSelectedDay] = useState(new Date());

  return (
    <Selected_day_context.Provider
      value={{
        selectedDay,
        setSelectedDay,
      }}
    >
      {children}
    </Selected_day_context.Provider>
  );
};




export const DraggingContext = createContext();

export const DraggingContextProvider = ({ children }) => {
    const [isDragged, setIsDragged] = useState(false);
    const supabase = useSupabaseClient()
    const {selectedDay} = useContext(Selected_day_context)
    const session =useSession()
    async function handleOnDragEnd(result) {
        if (!result.destination) return
        const taskid = result.draggableId.split('-')[1]
        const task = await apiGetTask(supabase,taskid)
        if(result.destination.droppableId==='dailyTimeLine'){
            if(task.google_calendar_id === null){
                const start = setTime(selectedDay,10,30,0)
                var minutes = 0
                var hours = 1
                if(task.timeEstimate !== null && task.timeEstimate !== '00:00:00'){
                    let split = task.timeEstimate.split(':')
                    hours =  Number(split[0])
                    minutes = Number(split[1])
                }
                const end = setTime(start,10+hours,30+minutes,0)
                if(!session.provider_token){
                    var est = hours + ":" + minutes + ":00"
                    await apiUpdateTaskDailycalResize(supabase,task.id,start,est)
                }
                else{
                    await checkToken(session)
                    var token = session.provider_token
                    if(!session.provider_token)return []
                    const eventy = {
                        summary : task.title,
                        start: {
                            "dateTime": formatRFC3339(start)
                        },
                        end: {
                            "dateTime": formatRFC3339(end)
                        },
                        description: task.description,
                        extendedProperties: {
                            private: {
                                taskzId: task.id,
                                lastSync: new Date().toISOString()
                            }
                        }
                    }
                    const event = await insertgCalEvent('primary',eventy,token)
                    task.scheduled = selectedDay
                     await apiUpdateTaskGID(supabase,task.id,selectedDay,start,event.id)
                }
                setIsDragged(!isDragged)
            }
            else{
                toast("Task already has a google calendar event")
            }
        }
        else if(result.destination.droppableId==='sidebar-backlog'){
            const task = await apiGetTask(supabase,taskid)
            if(task.google_calendar_id !== null){
                await checkToken(session)
                var tokeny = session.provider_token
                if(!session.provider_token)return []
                await deletegCalEvent('primary',task.google_calendar_id,tokeny)
            }
            await apiUpdateTaskToBacklog(supabase, taskid)
            setIsDragged(!isDragged)
        }
        else{
            if(task.google_calendar_id !== null){
                await checkToken(session)
                var token = session.provider_token
                if(!session.provider_token)return []
                await deletegCalEvent('primary',task.google_calendar_id,token)
            }
            const scheduled = result.destination.droppableId.split('-')[1]
            const split = scheduled.split('/')
            const scheduledDate = new Date(split[2],split[1]-1,split[0])
            await apiUpdateTaskScheduled(supabase, taskid, scheduledDate)
            setIsDragged(!isDragged)
        }

    }
    return (
        <DraggingContext.Provider
            value={{
                isDragged,
                setIsDragged,
            }}
        >
            <DragDropContext onDragEnd={handleOnDragEnd}>
            {children}
            </DragDropContext>
        </DraggingContext.Provider>
    );
};


export const SidebarContext = createContext();
export const SidebarContextProvider = ({ children }) => {
    const [sidebarLeft, setSidebarLeft] = useState(true)
    const [sidebarRight, setSidebarRight] = useState(true)
    const [width, setWidth] = useState(window.innerWidth);
    const breakPoint = 1280;

    const toggleSidebarRight = () => {
        setSidebarRight(!(sidebarRight !== null ? sidebarRight : true));
        if (sidebarRight === false && width < breakPoint) {
            setSidebarLeft(false);
        }
    }
    const toggleSidebarLeft = () => {
        setSidebarLeft(!(sidebarLeft !== null ? sidebarLeft : true));
        if (sidebarLeft === false && width < breakPoint) {
            setSidebarLeft(false);
        }
    }


    useEffect(() => {
        function sizeUpdated() {
            setWidth(window.innerWidth);
            if (width <= breakPoint) {
                setSidebarLeft(false);
                setSidebarRight(false);
            }
        }
        window.addEventListener("resize", sizeUpdated);
        return () => window.removeEventListener("resize", sizeUpdated);
    });

    return (
        <SidebarContext.Provider value={{sidebarLeft,setSidebarLeft,sidebarRight,setSidebarRight,toggleSidebarRight,toggleSidebarLeft}}>
            {children}
        </SidebarContext.Provider>
    )
}
