import React from 'react'
import PropTypes from "prop-types";
import Monthly from "./Monthly";
import {getNewDate} from "../../../../../../App/utils/CalendarUtils";
import {dateDiff} from "../../../../../../App/utils/MonthCalendarUtils";

class MonthCalendar extends React.PureComponent{
    constructor(props){
        super(props)
        const currentMonth = new Date(this.props.currentMonth)
        this.state = {
            month: currentMonth.getMonth(),
            year: currentMonth.getFullYear(),
        }
    }
    onClickDay(date) {
        if (this.props.onClickDay) {
            this.props.onClickDay(date);
        }
    }

    returnCalendar(){
        const events = formatEvents(this.props.events, this.props.tasks)
        return (

            <Monthly
                month={this.state.month}
                year={this.state.year}
                monthy={this.props.currentMonth}
                monday_firstday={this.props.monday_firstday}
                timeformat={this.props.timeformat}
                isDesktop={this.props.isDesktop}
                selectedDay={this.props.selectedDay}
                events={events}
                onClickDay={this.props.onClickDay}
                onClickEvent={this.props.onClickEvent}
            />

        )
    }
    render() {
        return (
            <div className="calendarWrapper  w-full">
                {this.returnCalendar()}
            </div>
        );
    }
}
MonthCalendar.propTypes = {
    events: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            isTaskz: PropTypes.bool,
            color: PropTypes.object,
            description: PropTypes.string,
            from: PropTypes.string.isRequired,
            to: PropTypes.string.isRequired,
            summary: PropTypes.string.isRequired,
        }),
    ),
    tasks: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            isTaskz: PropTypes.bool,
            color: PropTypes.object,
            description: PropTypes.string,
            from: PropTypes.string.isRequired,
            to: PropTypes.string.isRequired,
            summary: PropTypes.string.isRequired,
        }),
    ),
    isDesktop: PropTypes.bool,
    timeformat: PropTypes.string,
    monday_firstday: PropTypes.number,
    currentMonth: PropTypes.string,
    selectedDay: PropTypes.string,
    onClickEvent: PropTypes.func,
    onClickDay: PropTypes.func,
};
export default MonthCalendar;

function formatEvents(events,tasks){
    let formattedEvents = {};
    var l = []
    l = l.concat(events,tasks)

    if (Array.isArray(l) && l.length) {
        l.forEach(event => {
            const from = new Date(event.from);
            const to = new Date(event.to);
            const fromDate = getNewDate(from);
            const toDate = getNewDate(to);
            const fromDateAsTime = fromDate.getTime();
            const toDateAsTime = toDate.getTime();
            // if the from date is the same as the to date

            if (fromDateAsTime === toDateAsTime) {
                if (!Array.isArray(formattedEvents[fromDateAsTime])) {
                    formattedEvents[fromDateAsTime] = [];
                }
                formattedEvents[fromDateAsTime].push({
                    ...event,
                    date: from,
                    from,
                    to,
                });
            } else {
                const daySpan = dateDiff(from, to);
                if (daySpan === 1) {
                    if (!Array.isArray(formattedEvents[fromDateAsTime])) {
                        formattedEvents[fromDateAsTime] = [];
                    }
                    if(from.getHours() === 0 && to.getHours() === 0) {
                        formattedEvents[fromDateAsTime].push({
                            ...event,
                            allDay: true,
                            date: from,
                            span: daySpan
                        });
                    }else {
                        formattedEvents[fromDateAsTime].push({
                            ...event,
                            date: from,
                            span: daySpan
                        });
                    }
                } else {
                    // loop over each day between the from - to date
                    for (let x = 0; x < daySpan; x++) {
                        const dateIteration = new Date(fromDateAsTime);
                        dateIteration.setDate(fromDate.getDate() + x);

                        // work out whether the event is positioned first, middle or last
                        let position;
                        if (x === 0) {
                            position = 'start';
                        } else if (x < daySpan - 1) {
                            position = 'middle';
                        } else {
                            position = 'end';
                        }

                        const dateTime = dateIteration.getTime();
                        if (!Array.isArray(formattedEvents[dateTime])) {
                            formattedEvents[dateTime] = [];
                        }
                        formattedEvents[dateTime].push({
                            ...event,
                            spread: true,
                            date: dateIteration,
                            position,
                            span: daySpan
                        });
                    }
                }
            }
        });
        // sort each event by date time
        Object.keys(formattedEvents).forEach(date => {
            formattedEvents[date] = formattedEvents[date].sort(
                (a, b) => new Date(a.from) - new Date(b.from),
            );
        });
    }

    return formattedEvents;
};
