import Layout from "../../../Layout";
import Content from "../../../Content";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
} from "@heroicons/react/16/solid";
import React, { useContext, useEffect, useState } from "react";
import {Selected_day_context} from "../../../../Contexts";
import {addMonths, format, formatRFC3339, parse, subMonths} from "date-fns";
import {useSession, useSupabaseClient} from "@supabase/auth-helpers-react";
import {
  checkToken,
  getCalendarColors,
  getgCalEvent,
  setTime,
  updategCalEvent
} from "../../../../../App/utils/CalendarUtils";
import {getCalendarInWeeks} from "../../../../../App/utils/MonthCalendarUtils";
import MonthCalendar from "./Month/MonthCalendar";
import {Bounce, toast} from "react-toastify";
import EventDetail from "./EventDetail";
import EventCreate from "./EventCreate";
import {DragDropContext} from "react-beautiful-dnd";
import {LuPlus} from "react-icons/lu";
import {useSelector} from "react-redux";
import {apiGetTask, apiGetTimeboxedBetween, apiUpdateTaskMonthcalDrag} from "../../../../../App/api/tasksApi";
import {formatTasksForCalendar} from "../../../../../App/utils/TaskUtils";
import TaskDetail from "../Tasks view/TaskDetail";
import TaskCreate from "../Tasks view/TaskCreate";

export default function Calendar() {
  const session =useSession()
  const supabase = useSupabaseClient()

  const [currentMonth, setCurrentMonth] = useState(new Date());
  const { selectedDay, setSelectedDay } = useContext(Selected_day_context);
  const [currentCalendar, setCurrentCalendar] = useState([]);
  const [events,setEvents] = useState([])
  const [taskzEvents,setTaskzEvents] = useState([])
  const [selectedDayEvents, setSelectedDayEvents] = useState([])
  const [oldCurrentMonth, setOldCurrentMonth] = useState(new Date())
  const [oldSelectedDay,setOldSelectedDay] = useState(selectedDay)
  const [isLoaded, setIsLoaded] = useState(false)
  const [changeTriggered,setChangeTriggered] = useState(false)
  const [changeTriggered2,setChangeTriggered2] = useState(false)
  const [changeTriggeredTaskz,setChangeTriggeredTaskz] = useState(false)
  const [changeTriggeredTaskz2,setChangeTriggeredTaskz2] = useState(false)

  const [modalOpen, setModalOpen] = useState(false);
  const [modalEvent, setModalEvent] = useState(null);
  const [modalTask,setModalTask] = useState(null)
  const [createTaskModalOpen,setCreateTaskModalOpen] = useState(false)
  const [createEventModalOpen, setCreateEventModalOpen] = useState(false);
  const [modalDate,setModalDate] = useState(null)
  const settings = useSelector(state => state.settings).settings
  const enabledCalendars = settings.enabled_calendars_google
  const monday_firstday = (settings.start_of_week_monday?1:0)
  const timeformat = settings.timeformat
  const [width, setWidth] = useState(window.innerWidth);


  async function getTaskzEvents(first_day,last_day){
    first_day= setTime(new Date(first_day),0,0,0)
    last_day=setTime(new Date(last_day),23,59,59)

    const res = await apiGetTimeboxedBetween(supabase,first_day,last_day)
    const tasks = formatTasksForCalendar(res)
    setTaskzEvents(tasks)
  }



  async function getEvents(first_day, last_day){
    first_day= setTime(new Date(first_day),0,0,0)
    last_day=setTime(new Date(last_day),23,59,59)


    var start = formatRFC3339(first_day)
    var end = formatRFC3339(last_day)
    let eventlist = []
    let list = []

    await checkToken(session)
    var token = session.provider_token
    if(!session.provider_token)return []

    let calendarlist = JSON.parse(enabledCalendars)
    calendarlist.map( async(calendar)=>{
     eventlist = [...eventlist,getEventByCalendar(calendar,token,end,start)]})

    var results = await Promise.all(eventlist)
    const merge = results.flat(1)
    setEvents(merge)
    return merge
  }
  async function getEventByCalendar(calendar,token,end,start){
    let list = []
    let ID = encodeURIComponent(calendar.id)
    if(calendar.primary){ID = 'primary'}
    let search = `https://www.googleapis.com/calendar/v3/calendars/${ID}/events?singleEvents=true&timeMax=${encodeURIComponent(end)}&orderBy=startTime&timeMin=${encodeURIComponent(start)}`

    return await fetch(search,{
      method:'GET',
      headers:{
        'Authorization': 'Bearer '+token
      },
    }).then((data)=>{
      return data.json()

    }).then((data)=> {
      data.items.map((item)=> {
        list.push({
          id: item.id,
          isTaskz:false,
          summary: item.summary,
          description: item.description,
          from: item.start.dateTime?item.start.dateTime:item.start.date,
          to: item.end.dateTime?item.end.dateTime:item.end.date,
          color: {backgroundColor: calendar.backgroundColor, foregroundColor: calendar.foregroundColor}
        })
      })
      return list
    })
  }
  async function goToNextMonth(){
    setCurrentMonth(addMonths(currentMonth, 1));
  }
  async function goToPreviousMonth(){
    setCurrentMonth(subMonths(currentMonth, 1));
  }
  async function setSelectedDate(date){
    setSelectedDay(new Date(date))
  }
  async function handleOnEventDragEnd(result){
    var draggableId = result.draggableId
    var split = draggableId.split("-")
    var id = split[0]
    var time = split[1].split(":")
    var destination = result.destination.droppableId
    var date = destination.split("-")
    var day = parse(date[1],"dd/MM/yyyy",new Date())
    day.setHours(time[0])
    day.setMinutes(time[1])

    if(split[2] === 'taskz'){
      let task = await apiGetTask(supabase,id)
      task.scheduled = await apiUpdateTaskMonthcalDrag(supabase,id,day)
      setChangeTriggeredTaskz(!changeTriggeredTaskz)
    }
    else {
      await checkToken(session)
      var token = session.provider_token
      if(!session.provider_token)return []

      var event = await getgCalEvent('primary',id,token)
      var options = null

      var end = new Date(event.end.dateTime)
      end.setFullYear(day.getFullYear())
      end.setMonth(day.getMonth())
      end.setDate(day.getDate())

      if(event.hasOwnProperty('extendedProperties')){
        options = {
          taskzId: event.extendedProperties.private.taskzId,
          lastSync: event.extendedProperties.private.lastSync
        }
      }
      await updategCalEvent('primary',id,day,end,event.summary,event.description,token, options)
      setChangeTriggered(!changeTriggered)
    }
  }
  async function onClickEvent(event){
    if(event.isTaskz){
      var task = await apiGetTask(supabase,event.id)
      setModalTask(task)
    }
    else{
      setModalEvent(event)
    }
    setModalOpen(true)
  }
  const closeModal=async(deleted,updated)=> {
    setModalOpen(false)
    setModalEvent(null)
    setModalTask(null)
    if (deleted) {
      toast.success('item deleted succesfully', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
    }
    if(deleted || updated){
      setChangeTriggered(!changeTriggered)
    }
  }
  const openCreateModal=()=>{
    if(!session.provider_token){
      setCreateTaskModalOpen(true)
    }
    else{
      setCreateEventModalOpen(true)
    }
    setModalDate(new Date())
  }
  const closeCreateModal=async(event)=>{
    setCreateEventModalOpen(false)
    setModalDate(null)
    if(event){
      var size = currentCalendar.length
      await getEvents(currentCalendar[0][0].date, currentCalendar[size-1][6].date)
    }
  }
  const closeCreateTaskModal=async(event)=>{
    setCreateTaskModalOpen(false)
    setModalDate(null)
    if(event){
      var size = currentCalendar.length
      await getTaskzEvents(currentCalendar[0][0].date, currentCalendar[size-1][6].date)
    }
  }

  useEffect( () => {
    const fetchEvents = async (calendar) => {
      var size = calendar.length
      await getEvents(calendar[0][0].date, calendar[size-1][6].date)
    }
    const fetchTaskzEvents = async (calendar) => {
        var size = calendar.length
        await getTaskzEvents(calendar[0][0].date, calendar[size-1][6].date)
    }
    function sizeUpdated() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", sizeUpdated);

    if(!isLoaded){
      var calendardays = getCalendarInWeeks(currentMonth, monday_firstday)
      setCurrentCalendar(calendardays)
      fetchEvents(calendardays)
      fetchTaskzEvents(calendardays)
      setOldCurrentMonth(currentMonth)
      setIsLoaded(true)
      setChangeTriggered2(changeTriggered)
    }
    else if(currentMonth !== oldCurrentMonth || changeTriggered !== changeTriggered2){
      var calendardays = getCalendarInWeeks(currentMonth, monday_firstday)
      setCurrentCalendar(calendardays)
      fetchEvents(calendardays)
      setOldCurrentMonth(currentMonth)
      setIsLoaded(true)
      setChangeTriggered2(changeTriggered)
    }
    if(changeTriggeredTaskz !== changeTriggeredTaskz2){
      fetchTaskzEvents(currentCalendar)
      setChangeTriggeredTaskz2(changeTriggeredTaskz)
    }
    return () => window.removeEventListener("resize", sizeUpdated);
  },[currentMonth,selectedDay,changeTriggered,changeTriggeredTaskz])

  return (
      <Layout>
        <Content>
          <DragDropContext onDragEnd={handleOnEventDragEnd}>
            <div className="lg:flex p-1 lg:p-10 lg:h-full lg:flex-col">
              <header className="flex items-center justify-between border-b border-text-bg-inverse px-6 py-4 lg:flex-none">
                <h1 className="text-base font-semibold leading-6 text-text-bg">
                  <time dateTime={format(currentMonth, "yyyy-MM")}>
                    {format(currentMonth, "MMMM yyyy")}
                  </time>
                </h1>
                <div className="flex items-center">
                  <div className="relative flex items-center rounded-md bg-modal-bg shadow-sm md:items-stretch">
                    <button
                        onClick={() => {
                          goToPreviousMonth()
                        }}
                        type="button"
                        className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-text-bg-inverse pr-1 text-icon-faded hover:text-chip-accent focus:relative md:w-9 md:pr-0 "
                    >
                      <span className="sr-only">Previous month</span>
                      <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    <button
                        type="button"
                        className="hidden border-y border-text-bg-inverse px-3.5 text-sm font-semibold text-gray-900 hover:bg-chip-accent focus:relative md:block"
                    >
                      This month
                    </button>
                    <span className="relative -mx-px h-5 w-px bg-text-bg-inverse md:hidden" />
                    <button
                        onClick={() => { goToNextMonth() }}

                        type="button"
                        className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-text-bg-inverse pl-1 text-icon-faded hover:text-chip-accent focus:relative md:w-9 md:pl-0 "
                    >
                      <span className="sr-only">Next month</span>
                      <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                  <button className="ml-2 bg-chip-bg text-chip-text rounded-full hover:bg-chip-accent h-9 w-9 flex justify-center items-center" onClick={()=>openCreateModal()}>
                    <LuPlus className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </header>
              <div className="border-text-bg-inverse shadow ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
                <div className="grid grid-cols-7 gap-px border-b border-text-bg-inverse bg-disabled-bg text-center text-xs font-semibold leading-6 text-text-bg lg:flex-none ">
                  {monday_firstday === 0 && (
                      <div className="bg-modal-bg py-2">
                        S<span className="sr-only sm:not-sr-only">un</span>
                      </div>
                  )}
                  <div className="bg-modal-bg py-2">
                    M<span className="sr-only sm:not-sr-only">on</span>
                  </div>
                  <div className="bg-modal-bg py-2">
                    T<span className="sr-only sm:not-sr-only">ue</span>
                  </div>
                  <div className="bg-modal-bg py-2">
                    W<span className="sr-only sm:not-sr-only">ed</span>
                  </div>
                  <div className="bg-modal-bg py-2">
                    T<span className="sr-only sm:not-sr-only">hu</span>
                  </div>
                  <div className="bg-modal-bg py-2">
                    F<span className="sr-only sm:not-sr-only">ri</span>
                  </div>
                  <div className="bg-modal-bg py-2">
                    S<span className="sr-only sm:not-sr-only">at</span>
                  </div>
                  {monday_firstday === 1 && (
                      <div className="bg-modal-bg py-2">
                        S<span className="sr-only sm:not-sr-only">un</span>
                      </div>
                  )}
                </div>
                <div className="flex leading-6 text-text-bg bg-disabled-text text-sm shadow ring-1 ring-disabled-text">
                  <div className=" w-full ">
                    <MonthCalendar events={events} tasks={taskzEvents} isDesktop={width>1024} monday_firstday={monday_firstday} timeformat={timeformat} currentMonth={currentMonth.toISOString()} selectedDay={selectedDay} onClickDay={setSelectedDate} onClickEvent={onClickEvent} />
                  </div>
                </div>
                <div className="px-4 py-10 sm:px-6 ">
                  <ol className="divide-y divide-disabled-bg overflow-hidden rounded-lg bg-modal-bg text-sm shadow  ring-black ring-opacity-5">
                    {events.concat(taskzEvents).filter((event)=> format(new Date(event.from),"dd/MM/yyyy") === format(selectedDay, "dd/MM/yyyy")).map((event)=> (
                        <li onClick={()=>onClickEvent(event)}
                            key={event.id}
                            className="group flex p-4 pr-6 focus-within:bg-modal-bg hover:bg-modal-bg cursor-pointer"
                        >
                          <div className="flex-auto">
                            <p className="font-semibold text-text-bg">
                              {event.summary}
                            </p>
                            <time
                                dateTime={
                                  format(new Date(event.from), (timeformat === "HH")?"dd/MM/yyyy HH:mm":"dd/MM/yyyy HH:mm a")
                                }
                                className="mt-2 flex items-center text-text-bg"
                            >
                              <ClockIcon
                                  className="mr-2 h-5 w-5 text-icon-faded"
                                  aria-hidden="true"
                              />
                              {format(new Date(event.from), (timeformat === "HH")?"HH:mm":"HH:mm a")}
                            </time>
                          </div>
                        </li>
                    ))}
                  </ol>
                </div>
              </div>
            </div>
          </DragDropContext>

          {modalOpen && modalEvent !== null && <EventDetail event={modalEvent} closeModal={closeModal}/>}
          {modalOpen && modalTask !== null && <TaskDetail task={modalTask} closeTaskModal={closeModal}/>}
          {createEventModalOpen && <EventCreate date={modalDate} closeModal={closeCreateModal}/>}
          {createTaskModalOpen && <TaskCreate date={modalDate} closeModal={closeCreateTaskModal}/>}
        </Content>
      </Layout>
  );
}
