import {
  ChevronRightIcon,
} from "@heroicons/react/16/solid";
import Estimated_time_fragment from "../../../../../fragments/Estimated_time_fragment";
import {useSupabaseClient} from "@supabase/auth-helpers-react";
import React, {useContext, useEffect, useState} from "react";
import {apiGetBacklogTasks} from "../../../../../../App/api/tasksApi";
import {LuX} from "react-icons/lu";
import {toast} from "react-toastify";
import {toastOptions} from "../../../../../Constants";
import TaskDetail from "../../../Main_page/Tasks view/TaskDetail";
import {Draggable, Droppable} from "react-beautiful-dnd";
import {DraggingContext} from "../../../../../Contexts";


export default function SidebarBacklog() {
  const supabase = useSupabaseClient()
  const [tasks, setTasks] = useState([]);
  const [search, setSearch] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTask, setModalTask] = useState({});
  const [updateMade, setUpdateMade] = useState(false)
  const {isDragged} = useContext(DraggingContext)
  async function getBacklogTasks(){
    var tasks = await apiGetBacklogTasks(supabase)
    setTasks(tasks)
  }
  const closeModal = (updated,deleted) => {
    setModalOpen(false)
    setModalTask({})
    if(updated) {
      setUpdateMade(!updateMade)
    }
    if(deleted){
      toast.success('Task deleted succesfully', toastOptions)
      setUpdateMade(!updateMade)
    }
  }
  const openModal = (task) => {
    setModalTask(task);
    setModalOpen(true)
  }
  useEffect(() => {
    getBacklogTasks()
  }, [updateMade, isDragged])
  return (
    <div className="text-text-bg flex-1">
      <div className="title-container mb-3">
        <div className="date flex-1 mt-10">
          <h5 className="mb-1 text-xl font-medium text-text-bg ">
            Backlog
          </h5>
        </div>
      </div>

        <label
          htmlFor="default-search"
          className="text-sm font-medium text-text-selected-day sr-only "
        >
          Search
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              aria-hidden="true"
              className="w-4 h-4 text-icon-faded "
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>
          <input
            type="search"
            id="sidebar-backlog-search"
            onChange={(e)=>setSearch(e.target.value)}
            value={search}
            className="block w-full p-2 pl-10 text-sm text-text-selected-day border border-disabled-text rounded-lg bg-input-bg focus:ring-chip-bg focus:border-chip-accent  "
            placeholder="Search..."
            required=""
          />
          <button
            onClick={()=>setSearch('')}
            className="text-chip-text absolute right-0 bottom-0 top-0 bg-chip-bg hover:bg-chip-accent focus:ring-4 focus:outline-none focus:ring-chip-ring font-medium rounded-r-lg text-sm px-4 py-2"
          >
            <LuX className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      <div className="">

        <Droppable droppableId={"sidebar-backlog"}>
          {(provided)=>(
          <ul role="list" {...provided.droppableProps} ref={provided.innerRef}>
            {tasks.filter((task)=> {
              if(search !== ''){
                  return task.title.includes(search)
              }else{return true}}).map((task,index) =>
                (

                    <Draggable key={task.id} draggableId={"task-"+task.id} index={index}>
                      {(provided, snapshot)=> (
                        <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                          key={task.id}
                          onClick={()=>openModal(task)}
                          className="relative flex items-center space-x-4 py-2 bg-card-bg rounded-2xl shadow-md ring-1 ring-inset ring-card-ring overflow-x-hidden my-5 p-4"
                        >
                          <div className="min-w-0 flex-auto">
                            <div className="flex items-center justify-between gap-x-3">
                              <div>
                                <h2 className="min-w-0 text-sm font-semibold leading-6 text-text-bg">
                                  <a href="#" className="flex gap-x-2">
                                    <span className="truncate">{task.title}</span>
                                    <span className="absolute inset-0" />
                                  </a>
                                </h2>

                                <div className="mt-2 flex items-center gap-x-2.5 text-xs leading-5 text-text-bg-inverse">
                                  <p className="truncate">{task.category}</p>
                                  <svg
                                    viewBox="0 0 2 2"
                                    className="h-0.5 w-0.5 flex-none fill-text-bg-inverse"
                                  >
                                    <circle cx={1} cy={1} r={1} />
                                  </svg>
                                </div>
                              </div>
                              <div className="flex flex-1 justify-end items-center">
                                <Estimated_time_fragment key={task.id} time_estimate={task.timeEstimate} />
                                <ChevronRightIcon
                                  className="h-5 w-5 flex-none text-icon-faded"
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                          </div>
                        </li>
                      )}
                    </Draggable>
            ))}
            {provided.placeholder}
          </ul>
          )}
        </Droppable>
      </div>
      {modalOpen && <TaskDetail task={modalTask} refe={"none"} closeTaskModal={closeModal}/>}
    </div>
  );
}
