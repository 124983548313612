import React, {Fragment, useContext, useEffect, useState,} from 'react'
import {Dialog,Transition} from '@headlessui/react'
import { getMobname} from "../../../../helpers";
import {apiGetQuestLoottable, apiStartQuest} from "../../../../../App/api/Quest/QuestApi";
import {useSupabaseClient} from "@supabase/auth-helpers-react";
import {getLootDescription} from "../../../../../App/utils/LoottableUtils";
import {apiDeleteScroll} from "../../../../../App/api/Inventory/InventoryApi";
import {toast} from "react-toastify";
import {toastOptions} from "../../../../Constants";
import {QuestContext} from "../../../../../App/utils/Contexts/QuestContext";
import {CombatContext} from "../../../../../App/utils/Contexts/CombatContext";
import {LevelContext} from "../../../../../App/utils/Contexts/LevelContext";

export default function QuestDetail({quest,closeQuestModal}){
    const [loottable, setLoottable] = useState([])
    const supabase = useSupabaseClient()
    const {character} = useContext(LevelContext)
    const {finishQuest} = useContext(QuestContext)


    function finish(){
        quest.rewards_collected = true
        finishQuest(quest,character)
    }

    useEffect(() => {
        async function fetchLoottable(){
            const loot = await apiGetQuestLoottable(supabase,quest.quest)
            const loot2 = getLootDescription(loot.pools)
            setLoottable(loot2)
        }
        fetchLoottable()
    },[])
     return (
         <>
             <Transition.Root show={true} as={Fragment}>
                 <Dialog as="div" className="relative z-10 " onClose={()=>closeQuestModal()}>
                     <Transition.Child
                         as={Fragment}
                         enter="ease-out duration-300"
                         enterFrom="opacity-0"
                         enterTo="opacity-100"
                         leave="ease-in duration-200"
                         leaveFrom="opacity-100"
                         leaveTo="opacity-0"
                     >
                         <div className="fixed inset-0 bg-dark-grey bg-opacity-75 transition-opacity" />
                     </Transition.Child>

                     <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                         <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                             <Transition.Child
                                 as={Fragment}
                                 enter="ease-out duration-300"
                                 enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                 enterTo="opacity-100 translate-y-0 sm:scale-100"
                                 leave="ease-in duration-200"
                                 leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                 leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                             >
                                 <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-card-bg px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                     <div>
                                         <div className="mt-3 text-center sm:mt-5">
                                             <div className=" text-text-bg  font-semibold dialog-title items-center flex justify-between ">
                                                 <div className="flex items-center">
                                                     <span> {quest.quest_type + " "+quest.quest_target_amount+" "+getMobname(quest.quest_target)}</span>
                                                     <img src={"assets/game/mobs/"+quest.quest_target+".png"} alt="mob" className="ml-3 w-8 h-8"/>
                                                 </div>
                                                 <div className="flex items-center">
                                                     <span> Progress: {quest.progress} / {quest.quest_target_amount}</span>
                                                 </div>

                                                 <div className=" flex items-center">
                                                     {quest.type + " quest"}
                                                 </div>
                                             </div>
                                             <div className="mt-4">
                                                 <span>Possible loot:</span>
                                                 <div className="flex flex-1 justify-center">
                                                     {loottable.map(loot => (
                                                         <div className="flex items-center mx-px">
                                                             <div className="w-10 h-10 rounded-full bg-text-faded flex items-center"  >
                                                                 <img src={"/assets/game/loottable_icons/"+loot.replace(":","_")+".png"} alt="item" className="w-7 h-7 m-auto"/>
                                                             </div>
                                                         </div>
                                                     ))}
                                                 </div>
                                                 {quest.progress >= quest.quest_target_amount && !quest.rewards_collected &&
                                                     <p className="mt-8 flex flex-1 justify-center  text-sm text-text-bg">
                                                         <button className="block px-3 py-1 text-sm font-semibold leading-6 bg-chip-bg text-chip-text hover:bg-chip-accent rounded-lg shadow-md hover:text-chip-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-chip-accent"
                                                                 onClick={() =>finish()}
                                                         >
                                                             Finish Quest!
                                                         </button>
                                                     </p>
                                                     }
                                             </div>
                                         </div>
                                     </div>
                                     <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">

                                     </div>
                                 </Dialog.Panel>
                             </Transition.Child>
                         </div>
                     </div>
                 </Dialog>
             </Transition.Root>
         </>
    )
 }
