import { Navigate } from "react-router-dom";
import {useSessionContext, useUser} from '@supabase/auth-helpers-react'

const UnauthenticatedRoute = ({ children }) => {
    const { isLoading } = useSessionContext()
    const user = useUser()
    if (isLoading) { return <></>}
    if (user) {
        // user is authenticated
        return <Navigate to="/plan" />;
    }
    return <>{children}</>
};

export default UnauthenticatedRoute
