import React, {useContext, useEffect, useState} from "react";
import {useSupabaseClient} from "@supabase/auth-helpers-react";
import {apiGetMobs, apiStartFight} from "../../../../../App/api/Quest/QuestApi";
import {getWeakness} from "../../../../../App/utils/LoottableUtils";
import {getRandomBetween} from "../../../../../App/utils/LoottableScrollsUtils";
import {CombatContext} from "../../../../../App/utils/Contexts/CombatContext";

export default function Forest() {
    const supabase = useSupabaseClient()
    const {combat,setCombat,hasActiveCombat,setHasActiveCombat,setUpdated,updated} = useContext(CombatContext)
    const [mobs, setMobs] = useState([])

    async function startFight(mob){
        var weakness = getWeakness(mob.weakness)
        var limit = getRandomBetween(mob.limit_min,mob.limit_max)
        var combat = {
            mob: mob.id,
            mob_name: mob.machine_name,
            mob_level:1,
            current_health: mob.health,
            weakness: weakness,
            limit_amount: limit,
        }
        await apiStartFight(supabase,combat)
        setUpdated(!updated)
    }
    useEffect(()=> {
        async function fetchMobs(){
            const mobs = await apiGetMobs(supabase,"forest")
            setMobs(mobs)
        }
        fetchMobs()
        console.log(hasActiveCombat)
    },[])
    return (
        <div
            style={{
                backgroundImage: "url(./assets/game/locations/forest_t.png)"}}
            className="flex flex-1 mt-4 justify-center bg-cover bg-no-repeat location-image">

            {/* Main 2 column grid */}
            <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-2 lg:gap-8 w-full  h-full">
                {/* Left column */}
                <div className="grid-cols-1 gap-4 flex flex-1 p-10 mb-10 h-full">
                    <div className=" lg:rounded-lg bg-modal-bg lg:shadow-lg flex flex-1 flex-col justify-center h-full mb-10">
                        <span className="text-lg text-text-bg font-semibod flex justify-center mt-8 mb-1">Available Mobs</span>
                        <span className="text-sm text-text-bg flex justify-center mb-8 mx-8">Choose a monster to fight. You can only fight one monster at a time</span>
                        <div className="flex flex-1 flex-col mob-container">
                            {mobs.map(mob => (
                                <div className="flex items-center justify-between px-10">
                                    <div className="flex items-center justify-center">
                                        <img src={"assets/game/mobs/"+mob.machine_name+".png"} alt="mob" className="w-20 h-20 mr-4"/>
                                        <div className="flex flex-col">
                                            <span className="text-text-bg font-semibold text-lg ml-2 mr-4">{mob.name}</span>
                                            <span className="text-text-bg text-sm ml-2 mr-4">Difficulty: Easy</span>
                                        </div>
                                    </div>
                                    {!hasActiveCombat &&
                                        <button className="p-2 bg-chip-bg text-chip-text rounded-lg hover:bg-chip-accent" onClick={()=>startFight(mob)}>Fight</button>
                                    }
                                </div>
                            ))}

                        </div>


                    </div>
                </div>
                {/* right column */}
                <div className="grid grid-cols-1 gap-4 h-full">
                    <div className="">
                        <div className="p-6 h-full">

                        </div>
                    </div>
                </div>


            </div>
        </div>

    );
}
