
import {format_time} from "../../App/utils/CombatUtils";



export default function Combat_time_fragment({time}){
    var has_ended = false
    if(time === "__:__"){
        has_ended = true
    }
    return (
        <>
            <div className="flex items-center py-1">
                <div className="time-container">
                    {!has_ended &&
                        <div className="text-icon-faded/80 flex-none py-1 px-1 text-base">{format_time(time)}</div>
                   }
                    {has_ended &&
                        <div className="text-chip-text bg-chip-bg/30 ring-chip-bg rounded-full flex-none py-1 px-2 text-xs font-medium ring-1 ring-inset">__:__</div>
                   }
                </div>
            </div>
        </>

    )
}
