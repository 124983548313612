
import MessageComponent from "./MessageComponent";
import {isSameDay, subDays} from "date-fns";
import {useContext, useEffect} from "react";
import {NotificationContext} from "../../../../../App/utils/Contexts/NotificationContext";
import {apiSetReadApp} from "../../../../../App/api/NotificationApi";
import {useSupabaseClient} from "@supabase/auth-helpers-react";

export default function Messages({notifications}) {
  const {notificationsUpdated,setNotificationsUpdated} = useContext(NotificationContext)
  const messages = notifications.filter(notification => notification.type !== 'game' && notification.archived ===false).sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
  const supabase = useSupabaseClient()

  useEffect(() => {
    async function setRead(){
      await apiSetReadApp(supabase)
    }
    if(messages.filter((notification) => notification.status === false).length > 0){
      setRead()
      setNotificationsUpdated(!notificationsUpdated)
    }
  },[])
  return (
    <div className="flex p-10 overflow-x-auto">
      <div className=" mt-10 flex-1 relative overflow-hidden">
        <div className="border-b dark:border-gray-700 mx-4">
          <div className="flex items-center justify-between md:space-x-4 py-3">
            <div className="w-full flex flex-col space-y-4 md:items-center">
              {messages
                .filter(
                  (filter) =>
                      isSameDay(new Date(filter.created_at), new Date())
                )
                .map((message) => (
                  <MessageComponent
                    key={message.id}
                    message={message}
                    old={false}
                  />
                ))}

              <h2
                tabIndex="0"
                className="focus:outline-none text-sm leading-normal pt-8 border-b pb-2 border-text-bg-inverse text-text-bg/70"
              >
                YESTERDAY
              </h2>
              {messages
                .filter(
                  (filter) =>
                      isSameDay(new Date(filter.created_at), subDays(new Date(), 1))
                )
                .map((message) => (
                  <MessageComponent
                    key={message.id}
                    message={message}
                    old={false}
                  />
                ))}
              <h2
                tabIndex="0"
                className="focus:outline-none text-sm leading-normal pt-8 border-b pb-2 border-text-bg-inverse text-text-bg/70"
              >
                OLDER
              </h2>
              {messages
                .filter(
                  (filter) =>
                    !isSameDay(new Date(filter.created_at), subDays(new Date(), 1)) &&
                    !isSameDay(new Date(filter.created_at), new Date())
                )
                .map((message) => (
                  <MessageComponent
                    key={message.id}
                    message={message}
                    old={true}
                  />
                ))}
              <div className="flex items-center justify-between">
                <hr className="w-full" />
                <p
                  tabIndex="0"
                  className="focus:outline-none text-sm flex flex-shrink-0 leading-normal px-3 py-16 text-text-bg/60"
                >
                  Thats it for now :)
                </p>
                <hr className="w-full" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
