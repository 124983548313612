import {createContext, useContext, useEffect, useState} from "react";
import {useSupabaseClient} from "@supabase/auth-helpers-react";
import {
    apiCollectedQuestReward,
    apiGetActiveQuests,
    apiGetQuestLoottable,
    apiIncreaseQuestProgress
} from "../../api/Quest/QuestApi";

import {toast} from "react-toastify";
import {toastOptions} from "../../../components/Constants";
import {apiGetStackables} from "../../api/Inventory/InventoryApi";
import {rollLoottable, sortLoot} from "../LoottableUtils";
import {apiUpdateGemstones, apiUpdateGold} from "../../api/characterApi";
import {handleCompanionLoot, handleStackableLoot} from "../CombatUtils";
import {LevelContext} from "./LevelContext";
import {NotificationContext} from "./NotificationContext";

export const QuestContext = createContext();

export const QuestContextProvider = ({ children }) => {
    const [quests,setQuests] = useState([])
    const supabase = useSupabaseClient()
    const [updated, setUpdated] = useState(false)
    const {addExp} = useContext(LevelContext)
    const {addGameNotification} = useContext(NotificationContext)

    function updateQuests(action,type){
        quests.map(q => {
            if(q.quest_type === action && q.quest_target === type && q.progress < q.quest_target_amount){
                q.progress +=1
                apiIncreaseQuestProgress(supabase,q.id,q.progress)
                if(q.progress === q.quest_target_amount){
                    toast.success("You've completed a quest!",toastOptions)
                    var notification = {
                        title: "Quest completed",
                        content: "You've completed a quest",
                        quicklink: "/quests",
                        icon: "quest"
                    }
                    addGameNotification(notification)
                }
            }
        })
    }
    async function finishQuest(quest,character){
        var loot = await apiGetQuestLoottable(supabase,quest.quest)
        const stackables = await apiGetStackables(supabase)
        const lootItems = rollLoottable(loot.pools,loot.roll_min, loot.roll_max, loot.roll_type)
        const looti = sortLoot(lootItems)
        looti.map((item)=> {
            var types = item.item.id.split(":")
            if(types[0] === "currency"){
                if(types[1] === "gold"){
                    apiUpdateGold(supabase, (character.gold + item.amount),character.id)
                }
                if(types[1] === "gemstone"){
                    apiUpdateGemstones(supabase, (character.gemstone + item.amount),character.id)
                }
            }
            if(types[0] === "gear"){

            }
            if(types[0] === "consumable"){

            }
            if(types[0] === "companion"){
                console.log("companion loot",item)
                var companionLoottableId = item.item.nbt.companion_roll_type_pool_id
                handleCompanionLoot(supabase,companionLoottableId)
            }
            if(types[0] === "mob_drops"){
                handleStackableLoot(item,stackables,supabase, types[0],types[1])
            }
        })
        var items = []
        looti.map((item)=>{
            items.push({item:item.item.id,amount:item.amount})
        })
        var notification = {
            title: "Quest completed",
            content: "You've completed the quest "+quest.quest+ "and received the following rewards",
            linked_items: JSON.stringify(items),
            icon: "quest"
        }
        addGameNotification(notification)


        await apiCollectedQuestReward(supabase,quest.id)
        await addExp(50)
    }
    useEffect(()=> {
        async function fetch(){
            const q = await apiGetActiveQuests(supabase)
            setQuests(q)

        }
        fetch()

    },[updated])
    return (
        <QuestContext.Provider
            value={{
             quests,
                setQuests,
                updated,
                setUpdated,
                updateQuests,
                finishQuest
            }}
        >
            {children}
        </QuestContext.Provider>
    );
};
