import React, {useContext, useEffect, useState} from "react";
import {DraggingContext} from "../../../../Contexts";
import {addDays} from "date-fns";
import {apiGetTasksBetween, apiGetTasksByDay} from "../../../../../App/api/tasksApi";
import {useSupabaseClient} from "@supabase/auth-helpers-react";
import TaskDetail from "../../Main_page/Tasks view/TaskDetail";
import TaskCreate from "../../Main_page/Tasks view/TaskCreate";
import useInfiniteScroll from "react-easy-infinite-scroll-hook";
import Weekday from "../../Main_page/Tasks view/Weekday";
import {compareDates} from "../../../../helpers";
import Today from "./Today";

function getTasksOfDay(tasklist, date) {
    return tasklist.filter((task) => {
        return compareDates(task.scheduled, date);
    });
}
export default function DailyplanList({date,updateMade,openModal,openCreateTaskModal,modalOpen,modalTask,closeModal,createTaskModalOpen,modalDay,closeCreateTaskModal}) {
    const [tasks, setTasks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const {isDragged} = useContext(DraggingContext)
    const [dateList, setDateList] = useState(getInitialDates(date));
    const supabase = useSupabaseClient()

    function getInitialDates(startdate) {
        const list = []
        list.push(startdate)
        for (let i = 1; i < 4; i++) {
            var date = addDays(startdate, i)
            list.push(date)
        }
        return list
    }
    const createnext = async (direction) => {
        try {
            setIsLoading(true)
            const newData = await loadMore(direction)
            setTasks((prev) => [...newData, ...prev])
        } finally {
            setIsLoading(false)
        }
    }
    const loadMore = async () => {
        var newdate = addDays(dateList[dateList.length - 1], 1)
        setDateList([...dateList, newdate])
        return await apiGetTasksByDay(supabase, newdate)
    }
    useEffect(() => {
        apiGetTasksBetween(supabase, dateList[0], dateList[dateList.length - 1]).then((list) => {
            setTasks(list);
        })
    }, [isDragged, updateMade])
    return (
        <>
            <InfinitePlanScroll isLoading={isLoading} items={tasks} dates={dateList} next={createnext}
                                openModal={openModal} openCreateTaskModal={openCreateTaskModal}/>
            {modalOpen && <TaskDetail task={modalTask} refe={"none"} closeTaskModal={closeModal}/>}
            {createTaskModalOpen && <TaskCreate day={modalDay} closeModal={closeCreateTaskModal}/>}
        </>
    )
}
function InfinitePlanScroll({isLoading,items,dates,next,openModal,openCreateTaskModal}){
    const ref = useInfiniteScroll({
        next,
        columnCount: dates.length,
        hasMore: {right: true},
    })
    return (
        <div id="scrollcontainer" className="flex flex-1 h-full" ref={ref}>
            <Today
                key={dates[0]}
                date={dates[0]}
                tasks={getTasksOfDay(items, dates[0])}
                openTaskModal={openModal}
                openCreateTaskModal={openCreateTaskModal}
            />
            {dates.map((date,index) => (
                <>
                    {index !== 0 &&
                        <Weekday
                            key={date}
                            date={date}
                            tasks={getTasksOfDay(items, date)}
                            openTaskModal={openModal}
                            openCreateTaskModal={openCreateTaskModal}
                        />
                    }
                </>

            ))}
            {isLoading && <div>Loading...</div>}
        </div>
    )
}
