export default function TownView() {
    return (
        <div
            style={{backgroundImage: "url(./assets/game/locations/farmland_1_t.png)"}}
            className="flex h-full flex-1 mt-4 justify-center bg-cover bg-no-repeat backdrop-opacity-10 backdrop-invert">


        </div>
    );
}
