import {setTime} from "../../App/utils/CalendarUtils";
import {format} from "date-fns";

export default function Estimated_time_fragment({time_estimate=null,time_actual=null, actual_set,showEstimate}){
    var timing = new Date()
    if (time_estimate !==null && time_estimate !== "00:00:00"){
        let split = time_estimate.split(':')
        timing = setTime(timing,Number(split[0]),Number(split[1]),0)
        if(Number(split[0])< 0 || Number(split[1]) < 0 || split[1].includes("-")){
            showEstimate = true
            actual_set = false
            time_estimate = "00:00:00"
        }
    }
    if(actual_set && time_actual){
        let split = time_actual.split(':')
        timing = setTime(timing,Number(split[0]),Number(split[1]),0)
    }
    return (
        <>
            <div className="flex items-center py-1">
                <div className="time-container">
                    {actual_set &&
                        <div className="text-chip-text bg-chip-bg/30 ring-chip-bg rounded-full flex-none py-1 px-2 text-xs font-medium ring-1 ring-inset">
                            {format(timing,"HH:mm")}
                        </div>

                    }
                    {!actual_set && time_estimate !== null && time_estimate !== "00:00:00" &&
                        <div className="text-icon-faded/80 flex-none py-1 px-1 text-s font-medium ">
                            {format(timing,"HH:mm")}
                        </div>
                    }
                    {!actual_set && showEstimate &&
                        <div className="text-chip-text bg-chip-bg/30 ring-chip-bg rounded-full flex-none py-1 px-2 text-xs font-medium ring-1 ring-inset">
                            __:__
                        </div>
                    }
                </div>
            </div>
        </>

    )
}
