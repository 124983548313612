import {createContext, useEffect, useState} from "react";
import {useSupabaseClient} from "@supabase/auth-helpers-react";

import {toast} from "react-toastify";
import {toastOptions} from "../../../components/Constants";
import {apiCreateNotification, apiGetNotifications} from "../../api/NotificationApi";

export const NotificationContext = createContext();

export const NotificationContextProvider = ({ children }) => {
    const [notifications,setNotifications] = useState([])
    const supabase = useSupabaseClient()
    const [notificationsUpdated, setNotificationsUpdated] = useState(false)


    async function addGameNotification(notification){
        notification.type = "game"
        await apiCreateNotification(supabase,notification)
        setNotificationsUpdated(!notificationsUpdated)
    }

    useEffect(()=> {
        async function fetch(){
            const q = await apiGetNotifications(supabase)
            setNotifications(q)
        }
        fetch()

    },[notificationsUpdated])
    return (
        <NotificationContext.Provider
            value={{
               notifications,setNotifications,notificationsUpdated,setNotificationsUpdated,addGameNotification
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};
