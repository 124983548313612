import { useContext} from "react";
import {SidebarContext} from "../Contexts";
import { joinClasses, classNames } from "../helpers";

export default function Content({ children }) {
  const { sidebarRight, sidebarLeft } = useContext(SidebarContext)
  return (
    <>
      <main
        className={joinClasses(
          classNames(
            (sidebarLeft !== null ? sidebarLeft : true) ? "lg:ml-80" : "",
          ),
          classNames(
            (sidebarRight !== null ? sidebarRight : true) ? "lg:mr-96" : "",
          ),
          "lg:pl-20 lg:pr-20 flex flex-1",
        )}
      >
        <div
          id="full-page-container"
          className="flex flex-col flex-1 bg-gradient min-h-full overflow-y-hidden overflow-x-none clearfix position-fixed inset-0 break-words z-0"
        >
          {children}
        </div>
      </main>
    </>
  );
}
