
export async function getUserID(supabase){
    const session = await supabase.auth.getUser()
    if (session.data.user === null) {
        return false
    }
    return session.data.user.id
}

export async function prepareUserForDeletion(supabase, id){
    const {data:res, error} = await supabase.from('User_deletion_requests').insert({user:id})
    if(!error){
        return res
    }
    return {}
}


export async function apiRequestUserData(supabase, id){
    const {data:res, error} = await supabase.from('User_data_requests').insert({user:id,status:"pending"})
    if(!error){
        return res
    }
    return {}
}

export async function apiGetUserDataRequests(supabase,id){
    const {data:res, error} = await supabase.from('User_data_requests').select('*').eq('user',id)
    if(!error){
        return res
    }
    return {}
}
