import {useDispatch, useSelector} from "react-redux";
import {useSession, useSupabaseClient} from "@supabase/auth-helpers-react";
import React, {useEffect, useState} from "react";
import {checkToken, fetchColors, getCalendarList} from "../../App/utils/CalendarUtils";
import {classNames} from "../helpers";
import {Switch} from "@headlessui/react";
import {updateSetting} from "../../App/SettingSlice";
import {toast} from "react-toastify";


export default function CalendarSettings(){
    const settings=useSelector(state => state.settings).settings
    const dispatch = useDispatch()
    const supabase = useSupabaseClient()
    const session = useSession()
    const user = session.user
    const [googleIdentity,setGoogleIdentity] = useState(getGoogleID(user.identities))
    const [calendarList,setCalendarList] = useState([])
    const [primaryCalendar,setPrimaryCalendar] = useState({})
    const [loading, setLoading] = useState(false)
    const [colors,setColors] = useState({})

    function getGoogleID(ids){
        var id = ids.filter((identity)=> identity.provider === "google")
        if(id.length > 0){return id[0]}
        return {}
    }
    async function authorizeGoogle(){
        setLoading(true)
        window.localStorage.setItem('Taskz_google_authorization', 'init')
        const { error} =await supabase.auth.signInWithOAuth({
            provider: 'google',
            options: {
                scopes: process.env.REACT_APP_GOOGLE_CALENDAR_SCOPES,
                redirectTo: 'http://app.taskz.io/auth/callback',
                queryParams: {
                    prompt: 'consent',
                    access_type: 'offline',
                },
            }})
        if (error) toast(error.message)
        setLoading(false)
    }
    async function listCalendars(){
        await checkToken(session)
        var token = session.provider_token
        if(!session.provider_token)return []
        var calendars = await getCalendarList(token)
        var primary = calendars.items.filter((calendar)=>calendar.primary)
        setPrimaryCalendar(primary[0])
        setCalendarList(calendars.items)
        return calendars.items
    }
    async function getColors(){
       if(localStorage.getItem('google_calendar_colors')){
           setColors(JSON.parse(localStorage.getItem('google_calendar_colors')))
       }
       else{
          await checkToken(session)
          var token = session.provider_token
          var colors = await fetchColors(token)
          localStorage.setItem('google_calendar_colors',JSON.stringify(colors))
          setColors(colors)
       }
    }
    function isCalendarEnabled(calendarId){
        if(settings.enabled_calendars_google === null) return false
       return JSON.parse(settings.enabled_calendars_google).filter((calendar)=>calendar.id === calendarId).length > 0
    }
    function toggleCalendar(calendar){
        var enabled = settings.enabled_calendars_google
        console.log(colors)
        if(enabled === null){
            enabled = [{
                'id':calendar.id,
                'backgroundColor':calendar.colorId? colors.calendar[calendar.colorId].background:calendar.backgroundColor,
                'foregroundColor':calendar.colorId? colors.calendar[calendar.colorId].foreground:calendar.foregroundColor,
            }]
        }
        else{
            enabled = JSON.parse(enabled)
            if (isCalendarEnabled(calendar.id)){
                enabled = enabled.filter((cal)=>cal.id !== calendar.id)
            }
            else{
                enabled.push({
                    'id':calendar.id,
                    'backgroundColor':calendar.colorId? colors.calendar[calendar.colorId].background:calendar.backgroundColor,
                    'foregroundColor':calendar.colorId? colors.calendar[calendar.colorId].foreground:calendar.foregroundColor,
                    })
            }
        }
        enabled = JSON.stringify(enabled);
        dispatch(updateSetting({supabase:supabase,setting_name:'enabled_calendars_google',setting_value:enabled}))

    }
    function checkEnabledPrimary(){
        if(!JSON.parse(settings.enabled_calendars_google).filter((calendar)=>calendar.primary).length > 0){
            var enabled = JSON.parse(settings.enabled_calendars_google)
            if(enabled === null){enabled = []}
            enabled.push({
                'id':primaryCalendar.id,
                'backgroundColor':primaryCalendar.colorId? colors.calendar[primaryCalendar.colorId].background:primaryCalendar.backgroundColor,
                'foregroundColor':primaryCalendar.colorId? colors.calendar[primaryCalendar.colorId].foreground:primaryCalendar.foregroundColor,
                'primary':true
            })
            enabled = JSON.stringify(enabled);
            dispatch(updateSetting({supabase:supabase,setting_name:'enabled_calendars_google',setting_value:enabled}))

        }
    }
    useEffect(()=>{
        listCalendars()
        getColors()
        checkEnabledPrimary()
    },[googleIdentity])

    return(
        <>
            <h1 className="font-semibold text-2xl text-text-bg mb-6">
                Calendar Integration
            </h1>
            {!googleIdentity &&
                <button
                    onClick={()=>authorizeGoogle()}
                    className="mb-6 flex items-center justify-center gap-3 rounded-md px-3 py-2 text-sm font-semibold bg-input-bg text-text-important shadow-sm ring-1 ring-inset ring-text-bg/60 hover:bg-chip-bg hover:text-chip-text focus-visible:ring-transparent"
                >
                    <svg className="h-5 w-5" aria-hidden="true" viewBox="0 0 24 24">
                        <path
                            d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                            fill="#EA4335"
                        />
                        <path
                            d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                            fill="#4285F4"
                        />
                        <path
                            d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                            fill="#FBBC05"
                        />
                        <path
                            d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                            fill="#34A853"
                        />
                    </svg>
                    <span className="text-sm font-semibold leading-6">Add Google Calendar</span>
                </button>
            }
            <hr className="border-text-bg-inverse mb-6" />
            {googleIdentity &&
                <div className="grid grid-cols-5 gap-y-2 w-full text-text-bg mb-10">
                    <div className="col-span-4">
                        <span className="text-lg text-text-bg">{googleIdentity.email}</span>
                    </div>
                    <div className="col-span-1"></div>

                    <div className="col-span-2">Calendar</div>
                    <div className="col-span-1"></div>
                    <div className="col-span-1"></div>
                    <div className="col-span-1">Enable</div>

                    <div className="col-span-2 text-sm flex items-center">
                        <div  style={{ backgroundColor: primaryCalendar.backgroundColor}}
                            className=' w-6 h-6 block rounded-full mr-2'></div>
                        {primaryCalendar.summary}
                    </div>
                    <div className="col-span-1"><span className="text-xs">Default for scheduling</span></div>
                    <div className="col-span-1"></div>
                    <div className="col-span-1">
                        <Switch.Group as="div">


                        <Switch
                            checked={true}
                            className= "bg-chip-bg flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-text-bg/30 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-chip-bg"
                            enabled={true}
                        >
                      <span
                          aria-hidden="true"
                          className="translate-x-3.5 h-4 w-4 transform rounded-full bg-toggle-bg shadow-sm ring-1 ring-text-bg/50 transition duration-200 ease-in-out"
                      />
                        </Switch>
                        </Switch.Group>
                    </div>
                    {calendarList.filter((calendar)=>!calendar.primary).map((calendar)=>(
                        <>
                            <div className="col-span-2 text-sm flex items-center">
                                <div className=' w-6 h-6 block rounded-full mr-2'
                                     style={{ backgroundColor: calendar.backgroundColor}}></div>
                                {calendar.summary}
                            </div>
                            <div className="col-span-1"></div>
                            <div className="col-span-1"></div>
                            <div className="col-span-1">
                                <Switch.Group>
                                    <Switch
                                        checked={isCalendarEnabled(calendar.id)}
                                        onChange={()=>toggleCalendar(calendar)}
                                        className={classNames(
                                            isCalendarEnabled(calendar.id)
                                                ? "bg-chip-bg"
                                                : "bg-dark-grey",
                                            "flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-text-bg/30 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-chip-bg",
                                        )}
                                    >
                                      <span
                                          aria-hidden="true"
                                          className={classNames(
                                              isCalendarEnabled(calendar.id)
                                                  ? "translate-x-3.5"
                                                  : "translate-x-0",
                                              "h-4 w-4 transform rounded-full bg-toggle-bg shadow-sm ring-1 ring-text-bg/50 transition duration-200 ease-in-out",
                                          )}
                                      />
                                    </Switch>
                                </Switch.Group>
                        </div>
                        </>
                    ))}

                </div>



            }
            <hr className="border-text-bg-inverse mb-6" />
        </>
    )
}
