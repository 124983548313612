import React from 'react'
import MonthWeek from "./MonthWeek";
import {getCalendarInWeeks, getEventsForCalendar} from "../../../../../../App/utils/MonthCalendarUtils";

export default class Monthly extends React.PureComponent{
    constructor(props) {
        super(props);
    }


    returnCalendar() {
        var date = new Date(this.props.monthy)
        let calendar = getCalendarInWeeks(date,this.props.monday_firstday)
        calendar = getEventsForCalendar(this.props.events, calendar);
        if (Array.isArray(calendar) && calendar.length) {
            return calendar.map((week, i) => {
                return (
                    <MonthWeek
                        key={i}
                        week={week}
                        timeformat={this.props.timeformat}
                        selectedDay={this.props.selectedDay}
                        isDesktop={this.props.isDesktop}
                        onClickDay={this.props.onClickDay}
                        onClickEvent={this.props.onClickEvent}
                        // onClickEvent={this.props.onClickEvent}
                    />
                );
            });
        }
    }


    render() {
        return (
            <div className="monthlyCalendar">
                {this.returnCalendar()}
            </div>
        );
    }
}
