import Layout from "../../../Layout";
import Content from "../../../Content";
import WorkloadTimeline from "../Components/WorkloadTimeline";
import DaylistContainer from "../Components/DaylistContainer";
import {useEffect, useState} from "react";
import {apiGetEstimates, apiGetPlan, apiUpdatePlan, apiInsertPlan} from "../../../../../App/api/Plan/DailyplanApi";
import {useSupabaseClient} from "@supabase/auth-helpers-react";
import {format, isSameDay} from "date-fns";
import {isArray} from "@craco/craco/dist/lib/utils";
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from "react-router-dom";
export default function Dailyplan() {
    const [planned,setPlanned] = useState(0)
    const [updateMade, setUpdateMade] = useState(false)
    const [date, setDate] = useState(new Date())
    const [isLoaded,setIsLoaded] = useState(false)
    const supabase =useSupabaseClient()
    const [mainGoal,setMainGoal] = useState('')
    const [notes,setNotes] = useState('')
    const [planExists,setPlanExists] = useState(false)
    const navigate = useNavigate();

    function checkSnoozeOption(){
        if(window.localStorage.getItem('lastPlan') !== null && isSameDay(new Date(window.localStorage.getItem('lastPlan')),new Date())){
            return false
        }
        return true
    }
    function setSnooze(){
        window.localStorage.setItem('lastPlan',format(new Date(),'yyyy-MM-dd'))
        navigate('/plan?daysnoozed')
    }
    async function onSavePlan(){
        var plan = {
            main_goal:mainGoal,
            notes:notes,
            time_planned:planned,
            date:format(date,'yyyy-MM-dd')
        }
        if(planExists){
            await apiUpdatePlan(supabase,plan)
        }
        else{
            await apiInsertPlan(supabase,plan)
        }
        window.localStorage.setItem('lastPlan',format(new Date(),'yyyy-MM-dd'))
        navigate('/plan?dayplanned')
    }

    useEffect(()=> {
        async function getEstimates(){
            const estimates = await apiGetEstimates(supabase,date)
            let estM = 0
            if(estimates && estimates !== {} && estimates.length !== 0){
                estimates.map((est)=>{
                    let split = est.timeEstimate.split(':')
                    estM += parseInt(split[0])*60 + parseInt(split[1])
                })
            }
            setPlanned(estM)
        }
        async function getPlanifExists(){
            var plan = await apiGetPlan(supabase,date)
            if(isArray(plan) && plan.length !== 0){
                setMainGoal(plan[0].main_goal)
                setNotes(plan[0].notes)
                setPlanned(plan[0].time_planned)
                setPlanExists(true)
            }
        }
        getEstimates()
        if(!isLoaded){
            getPlanifExists()
            setIsLoaded(true)
        }
    },[updateMade])
    return (
        <Layout>
            <Content>
                <div className="flex p-10 bg-modal-menu-bg shadow-xl rounded-lg m-5">
                    <div className="timeline-wrapper flex justify-center w-full">
                        <WorkloadTimeline planned={planned}/>
                    </div>
                </div>
                {!planExists && checkSnoozeOption() &&
                    <div className="pl-10 ">
                        <button className=" p-2 bg-chip-bg text-chip-text hover:bg-chip-accent rounded-lg" onClick={()=>setSnooze()}>Skip today</button>
                    </div>
                }
                <div className="flex flex-1 p-5 justify-center w-full">

                    {isLoaded &&
                    <DaylistContainer updateMade={updateMade} setUpdateMade={setUpdateMade} onSavePlan={onSavePlan} mainGoal={mainGoal} setMainGoal={setMainGoal} notes={notes} setNotes={setNotes}/>
                    }
                </div>
            </Content>
        </Layout>
    )
}
