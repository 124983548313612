import React from 'react'
import PropTypes from "prop-types";
import Daily from "./Daily";
import {getNewDate} from "../../../../../../App/utils/CalendarUtils";
import {dateDiff} from "../../../../../../App/utils/MonthCalendarUtils";


class DayCalendar extends React.PureComponent{
    returnCalendar(){
        const events = formatEvents(this.props.events, this.props.tasks)
        return (

                <Daily
                    events={events}
                    timeformat={this.props.timeformat}
                    onClickEvent={this.props.onClickEvent}
                    onClickTimeLine={this.props.onClickTimeLine}
                    onDragStop={this.props.onDragStop}
                    onResizeStop={this.props.onResizeStop}
                />

        )
    }
    render() {
        return (
            <div className="dailyCalendarContainer w-full z-20">
                {this.returnCalendar()}
            </div>
        );
    }
}
DayCalendar.propTypes = {
    events: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            color: PropTypes.object,
            description: PropTypes.string,
            from: PropTypes.string.isRequired,
            to: PropTypes.string.isRequired,
            summary: PropTypes.string.isRequired,
        }),
    ),
    tasks: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            color: PropTypes.object,
            description: PropTypes.string,
            from: PropTypes.string.isRequired,
            to: PropTypes.string.isRequired,
            summary: PropTypes.string.isRequired,
        }),
    ),
    timeformat: PropTypes.string,
    onClickEvent: PropTypes.func,
    onClickTimeLine:PropTypes.func,
    onDragStop: PropTypes.func,
    onResizeStop: PropTypes.func,
};
export default DayCalendar;


function formatEvents(events,tasks) {
    let list = []
    var l = []
    l = l.concat(events,tasks)
    if (Array.isArray(l) && l.length) {
        l.forEach(event => {
            const from = new Date(event.from);
            const to = new Date(event.to);
            const fromDate = getNewDate(from);
            const toDate = getNewDate(to);
            const fromDateAsTime = fromDate.getTime();
            const toDateAsTime = toDate.getTime();
            // if the from date is the same as the to date
            if (fromDateAsTime === toDateAsTime) {
               list.push({
                    ...event,
                    date: from,
                    from,
                    to,
                });
            }
            else{
                const daySpan = dateDiff(from, to);
                if(daySpan === 1){
                    if(from.getHours() === 0 && to.getHours() === 0) {
                       list.push({
                            ...event,
                            allDay: true,
                            date: from,
                            span: daySpan
                        });
                    }else {
                        list.push({
                            ...event,
                            date: from,
                            span: daySpan
                        });
                    }
                }
            }
        });
        list.sort(function(a,b){
            return new Date(a.from) - new Date(b.from)
        })
    return list;
}}


