import {addHours, addMinutes, format} from "date-fns";


export function formatTasksForCalendar(tasks){
    let formatted = []
    tasks.map((task)=>{
        let scheduled = task.scheduled
        let sched = format(scheduled,'yyyy-MM-dd')
        let to = sched
        let toFormatted = format(scheduled,'yyyy-MM-dd')
        if(task.scheduled_time){
            var split = task.scheduled_time.split(':')
            scheduled = new Date(scheduled)
            scheduled.setHours(split[0])
            scheduled.setMinutes(split[1])
            to = scheduled
            sched = format(scheduled,'yyyy-MM-dd HH:mm')
            toFormatted = format(scheduled,'yyyy-MM-dd HH:mm')
            if(task.timeEstimate){
                var spl = task.timeEstimate.split(':')
                to = addHours(to,Number(spl[0]))
                to = addMinutes(to,Number(spl[1]))
                toFormatted = format(to,'yyyy-MM-dd HH:mm')
            }
        }
        let item = {
            id:task.id+'',
            isTaskz:true,
            summary:task.title,
            description:task.description,
            from:sched,
            to:toFormatted,
            color: {backgroundColor: '#2AA9A9', foregroundColor: '#000000'}
        }
        formatted.push(item)
    })
    return formatted
}
export function formatTaskForCalendar(task){
    let scheduled = task.scheduled
    let sched = format(scheduled,'yyyy-MM-dd')
    let to = sched
    let toFormatted = format(scheduled,'yyyy-MM-dd')
    if(task.scheduled_time){
        var split = task.scheduled_time.split(':')
        scheduled = new Date(scheduled)
        scheduled.setHours(split[0])
        scheduled.setMinutes(split[1])
        to = scheduled
        sched = format(scheduled,'yyyy-MM-dd HH:mm')
        toFormatted = format(scheduled,'yyyy-MM-dd HH:mm')
        if(task.timeEstimate){
            var spl = task.timeEstimate.split(':')
            to = addHours(to,Number(spl[0]))
            to = addMinutes(to,Number(spl[1]))
            toFormatted = format(to,'yyyy-MM-dd HH:mm')
        }
    }
    let item = {
        id:task.id+'',
        isTaskz:true,
        summary:task.title,
        description:task.description,
        from:sched,
        to:toFormatted,
        color: {backgroundColor: '#2AA9A9', foregroundColor: '#000000'}
    }

return item
}
