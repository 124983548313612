import {createContext, useContext, useEffect, useState} from "react";
import {useSupabaseClient} from "@supabase/auth-helpers-react";
import {apiGetCharacter} from "../../api/characterApi";
import {apiEndFight, apiGetActiveCombat} from "../../api/Quest/QuestApi";
import {getEndTime} from "../CombatUtils";
import {isAfter} from "date-fns";
import {toast} from "react-toastify";
import {toastOptions} from "../../../components/Constants";
import {NotificationContext} from "./NotificationContext";
import {getMobname} from "../../../components/helpers";

export const CombatContext = createContext();

export const CombatContextProvider = ({ children }) => {
    const [combat, setCombat] = useState({});
    const [hasActiveCombat, setHasActiveCombat] = useState(false);
    const supabase = useSupabaseClient()
    const [endTime, setEndTime] = useState(null)
    const [updated, setUpdated] = useState(false)
    const {addGameNotification} = useContext(NotificationContext)

    useEffect(()=> {

        async function fetch(){
            var char = await apiGetCharacter(supabase)
            const c = await apiGetActiveCombat(supabase)
            if(c.length > 0) {
                setCombat(c[0])
                setHasActiveCombat(true)
                var e = getEndTime(c[0],char.dexterity)
                setEndTime(e)
                if(isAfter(new Date(),e)){
                    if(c[0].current_health > 0){
                        toast("You have lost the battle",toastOptions)
                        await apiEndFight(supabase,c[0].id)
                        var notification = {
                            title: "Battle lost",
                            content: "You've lost the battle against the "+getMobname(c[0].mob_name),
                            icon: "combat"
                        }
                        addGameNotification(notification)

                        setCombat({})
                        setHasActiveCombat(false)


                    }
                }
            }
            else{
                setCombat({})
                setHasActiveCombat(false)
            }
        }
        fetch()

    },[updated])
    return (
        <CombatContext.Provider
            value={{
                combat,
                setCombat,
                endTime,
                setEndTime,
                hasActiveCombat,
                setHasActiveCombat,
                updated,
                setUpdated
            }}
        >
            {children}
        </CombatContext.Provider>
    );
};
