import {getDays} from "../../components/helpers";

export const dateDiff = (start, end) => {
    const from = new Date(start.getUTCFullYear(), start.getUTCMonth(), start.getUTCDate());
    const to = new Date(end.getUTCFullYear(), end.getUTCMonth(), end.getUTCDate());

    const timeDiff = Math.abs(from.getTime() - to.getTime());
    const dayDiff = timeDiff / (1000 * 3600 * 24);
    let value =  dayDiff + 1;
    if (end.getUTCHours() === 0 && end.getUTCMinutes() === 0 && end.getUTCSeconds() === 0) {
        value -= 1;
    }
    return Math.ceil(value);
};


export const getCalendarInWeeks = (date,mondayFirst)=> {
    var calendar = []
    var list = getDays(date,mondayFirst)
    for(let x = 0;  x<list.length / 7; x++){
        var week = []
        for(let y = 0; y<7; y++){
            var weekday = {
                current: list[x*7+y].isCurrentMonth,
                date: new Date(list[x*7+y].date),
            }
            week.push(weekday)
        }
        calendar.push(week)
    }
    return calendar
}
export const getEventsForCalendar = (events, calendar) => {
    if (events && Object.keys(events)) {
        return calendar.map(week => {
            return week.map(day => {
                let dayEvents;
                if (events[day.date.getTime()]) {
                    dayEvents = events[day.date.getTime()];
                }
                return {
                    ...day,
                    events: dayEvents,
                };
            });
        });
    }
    return calendar;
};


