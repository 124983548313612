import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/16/solid";
import React, { useContext, useEffect, useState } from "react";

import {getDays, classNames, compareDates} from "../../../../../helpers";
import {DraggingContext, Selected_day_context} from "../../../../../Contexts";
import {addMonths, differenceInMinutes, format, formatRFC3339, subMonths} from "date-fns";
import {isArray} from "@craco/craco/dist/lib/utils";
import {useSession, useSupabaseClient} from "@supabase/auth-helpers-react";
import {
  checkToken,
  returnHourHeaderHeight,
  returnHourWrapperHeight, setTime,
  updategCalEvent
} from "../../../../../../App/utils/CalendarUtils";
import DayCalendar from "../../../Main_page/Calendar view/Day/DayCalendar";
import {Bounce, toast} from "react-toastify";
import EventCreate from "../../../Main_page/Calendar view/EventCreate";
import EventDetail from "../../../Main_page/Calendar view/EventDetail";
import {checkForUpdates} from "../../../../../../App/utils/SyncUtils";
import {useSelector} from "react-redux";
import {
  apiGetTask,
  apiGetTimeboxedOn,
  apiUpdateTaskDailycalDrag,
  apiUpdateTaskDailycalResize
} from "../../../../../../App/api/tasksApi";
import {formatTasksForCalendar} from "../../../../../../App/utils/TaskUtils";
import TaskDetail from "../../../Main_page/Tasks view/TaskDetail";
import TaskCreate from "../../../Main_page/Tasks view/TaskCreate";


export default function SidebarCalendar() {
  //const container = useRef(null)
  //const containerOffset = useRef(null)
  const supabase = useSupabaseClient()
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const { selectedDay, setSelectedDay } = useContext(Selected_day_context);
  const settings = useSelector(state => state.settings).settings
  const monday_firstday = (settings.start_of_week_monday?1:0)
  const timeformat = settings.timeformat
  const currentDays = getDays(currentMonth, monday_firstday);
  const session =useSession()
  const [events, setEvents] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalEvent, setModalEvent] = useState(null);
  const [createEventModalOpen, setCreateEventModalOpen] = useState(false);
  const [modalDate,setModalDate] = useState(null)
  const [changeTriggered,setChangeTriggered] = useState(false)
  const {isDragged} = useContext(DraggingContext)
  const enabledCalendars = settings.enabled_calendars_google
  const [modalTask,setModalTask] = useState(null)
  const [createTaskModalOpen,setCreateTaskModalOpen] = useState(false)
  const [taskzEvents,setTaskzEvents] = useState([])
  const [isLoading,setIsLoading] = useState(false)
  async function getEventsByDay(){
    var start = formatRFC3339(setTime(selectedDay,0,0,0))
    var end = formatRFC3339(setTime(selectedDay,23,59,59))
    let eventlist = []
    await checkToken(session)
    var token = session.provider_token
    if(!session.provider_token)return []

    let calendarlist = JSON.parse(enabledCalendars)
    calendarlist.map( async(calendar)=>{
      eventlist = [...eventlist,getEventByCalendar(calendar,token,end,start)]
    })

    var results = await Promise.all(eventlist)
    const merge = results.flat(1)
    checkForUpdates(merge,supabase,token)
    setEvents(merge)

    return merge
  }
  async function getTaskzEvents(){
    const res = await apiGetTimeboxedOn(supabase,selectedDay)
    const tasks = formatTasksForCalendar(res)
    setTaskzEvents(tasks)
  }
  async function getEventByCalendar(calendar,token,end,start){
    let list = []
    let ID = encodeURIComponent(calendar.id)
    if(calendar.primary){ID = 'primary'}
    let search = `https://www.googleapis.com/calendar/v3/calendars/${ID}/events?singleEvents=true&timeMax=${encodeURIComponent(end)}&orderBy=startTime&timeMin=${encodeURIComponent(start)}`

    return await fetch(search,{
      method:'GET',
      headers:{
        'Authorization': 'Bearer '+token
      },
    }).then((data)=>{
      return data.json()

    }).then((data)=> {
      data.items.map((item)=> {
        list.push({
          id: item.id,
          summary: item.summary,
          isTaskz:false,
          description: item.description,
          from: item.start.dateTime?item.start.dateTime:item.start.date,
          to: item.end.dateTime?item.end.dateTime:item.end.date,
          color: {backgroundColor: calendar.backgroundColor, foregroundColor: calendar.foregroundColor}
        })
      })
      return list
    })
  }
  function onClickEvent(event){
    openModal(event)
  }
  function onClickTimeline(event){
    const timeHours = Math.floor(event)
    const timeMinutes = (event - timeHours) <! 0?30:0
    const date = setTime(selectedDay,timeHours,timeMinutes,0)
    openCreateModal(date)
  }
  const openCreateModal=(date)=>{
    if(!session.provider_token){
      setCreateTaskModalOpen(true)
    }
    else{
      setCreateEventModalOpen(true)
    }
    setModalDate(date)
  }
  const closeCreateModal=async(event)=>{
    setCreateEventModalOpen(false)
    setCreateTaskModalOpen(false)
    setModalDate(null)
    if(event){
      setChangeTriggered(!changeTriggered)
    }
  }
  async function openModal(event){
    if(event.isTaskz){
      var task = await apiGetTask(supabase,event.id)
      setModalTask(task)
    }
    else{
      setModalEvent(event)
    }
    setModalOpen(true)
  }
  const closeModal=async(deleted,updated)=> {
    setModalOpen(false)
    setModalEvent(null)
    setModalTask(null)
    if (deleted) {
      toast.success('item deleted succesfully', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
    }
    if(updated || deleted){
      setChangeTriggered(!changeTriggered)
    }
  }
  async function onDragStop(event, data, item, startPosition, startHeight) {

    var HourWrapperHeight = returnHourWrapperHeight();
    var HourHeaderHeight = (returnHourHeaderHeight()/2);
    setIsLoading(true)
    var topPosition = startPosition + data.y
    var from = (topPosition - HourHeaderHeight + 10) / HourWrapperHeight
    var fromHours = Math.floor(from)
    var fromMinutes = (from - fromHours) * 60
    var start = new Date(item.from)

    var endPosition = topPosition + startHeight
    var to = (endPosition - HourHeaderHeight + 10) / HourWrapperHeight
    var toHours = Math.floor(to)
    var toMinutes = (to - toHours) * 60
    var end = new Date(item.to)

    const startdate = setTime(start, fromHours, fromMinutes, 0)
    const enddate = setTime(end,toHours,toMinutes,0)

    if(item.isTaskz){
      var updatedTask = await apiUpdateTaskDailycalDrag(supabase,item.id,startdate)
     /* updatedTask = formatTaskForCalendar(updatedTask)
      const newTasklist = taskzEvents.filter((task) => ""+task.id !== item.id)
      newTasklist.push(updatedTask)
      setTaskzEvents(newTasklist)*/
    }
    else{
      await checkToken(session)
      var token = session.provider_token
      if (!session.provider_token) return []
      const updatedEvent =await updategCalEvent('primary', item.id,startdate, enddate,item.summary,item.description, token)
     /*const newEventlist = events.filter((event) => ""+event.id !== item.id)
      newEventlist.push(updatedEvent)
      setEvents(newEventlist)*/
    }


    setChangeTriggered(!changeTriggered)
  }
  async function onResizeStop(event,direction,ref,delta,position,item,startPosition,startheight){
    var HourWrapperHeight = returnHourWrapperHeight();
    var HourHeaderHeight = (returnHourHeaderHeight()/2);
    var startDate = new Date(item.from)
    var endDate = new Date(item.to)

    if(direction === 'top'){
      // change start time
      var topPosition = startPosition - delta.height;
      var from = (topPosition - HourHeaderHeight + 10)/HourWrapperHeight
      var fromHours = Math.floor(from)
      var fromMinutes = (from - fromHours)*60
      startDate = setTime(startDate,fromHours,fromMinutes,0)
    }
    else{
      // change end time
      var startBottom = startPosition + startheight
      var newBottom = startBottom + delta.height
      var to = (newBottom - HourHeaderHeight + 10)/HourWrapperHeight
      var toHours = Math.floor(to)
      var toMinutes = (to - toHours)*60
      endDate = setTime(endDate,toHours,toMinutes,0)
    }
    if(item.isTaskz){
      // update taskz event
      var diff = differenceInMinutes(endDate,startDate)
      var diffHours = Math.floor(diff/60)
      var diffMinutes = (diff - (diffHours*60))

      var estimate = diffHours+':'+diffMinutes+":00"

      var updatedTask = await apiUpdateTaskDailycalResize(supabase,item.id,startDate,estimate)
     /*  updatedTask = formatTaskForCalendar(updatedTask)
      const newTasklist = taskzEvents.filter((task) => ""+task.id !== item.id)
      newTasklist.push(updatedTask)
      setTaskzEvents(newTasklist)*/
    }
    else{
      await checkToken(session)
      var token = session.provider_token
      if (!session.provider_token) return []

      const updatedEvent = await updategCalEvent('primary',item.id,startDate,endDate,item.summary,item.description,token)
     /* const newEventlist = events.filter((event) => ""+event.id !== item.id)
      newEventlist.push(updatedEvent)
      setEvents(newEventlist)*/
    }
    setChangeTriggered(!changeTriggered)
  }
  async function updateSelected(date) {
    setSelectedDay(date)
  }
  useEffect( () => {
    async function fetch(){
        await getEventsByDay()
        await getTaskzEvents()

    }
   fetch()

  }, [selectedDay,changeTriggered,isDragged]);
  return (
    <div className="sidebar-container flex-1">
      <div id="month-view">
        <div className="flex items-center text-text-bg">
          <button
            type="button"
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-icon-faded hover:text-chip-accent"
            onClick={() => {
              setCurrentMonth(subMonths(currentMonth, 1));
            }}
          >
            <span className="sr-only">Previous month</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <div className="flex-auto text-sm text-center font-semibold">
            {format(currentMonth, "MMMM yyyy")}
          </div>
          <button
            type="button"
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-icon-faded hover:text-chip-accent"
            onClick={() => {
              setCurrentMonth(addMonths(currentMonth, 1));
            }}
          >
            <span className="sr-only">Next month</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div className="mt-6 grid grid-cols-7 text-xs text-center leading-6 text-text-bg">
          {monday_firstday === 0 && <div>S</div>}
          <div>M</div>
          <div>T</div>
          <div>W</div>
          <div>T</div>
          <div>F</div>
          <div>S</div>
          {monday_firstday === 1 && <div>S</div>}
        </div>
        <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-disabled-text text-sm shadow ring-1 ring-disabled-text">
          {isArray(currentDays) && currentDays.map((day, dayIdx) => (
            <button
              onClick={() => updateSelected(day.date)}
              key={format(day.date, "dd/MM/yyyy")}
              type="button"
              className={classNames(
                "py-1.5 hover:bg-card-hover hover:text-text-selected-day focus:z-10",
                day.isCurrentMonth ? "bg-modal-bg" : "bg-disabled-bg",
                (compareDates(selectedDay, day.date) || day.isToday) &&
                  "font-semibold",
                day.isToday && "text-white",
                !compareDates(selectedDay, day.date) &&
                  day.isCurrentMonth &&
                  !day.isToday &&
                  "text-text-bg",
                !compareDates(selectedDay, day.date) &&
                  !day.isCurrentMonth &&
                  !day.isToday &&
                  "text-text-bg/80",
                dayIdx === 0 && "rounded-tl-lg",
                dayIdx === 6 && "rounded-tr-lg",
                dayIdx === currentDays.length - 7 && "rounded-bl-lg",
                dayIdx === currentDays.length - 1 && "rounded-br-lg",
              )}
            >
              <time
                dateTime={format(day.date, "dd/MM/yyyy")}
                className={classNames(
                  "mx-auto flex h-7 w-7 items-center justify-center rounded-full",
                  compareDates(selectedDay, day.date) &&
                    day.isToday &&
                    "bg-chip-accent",
                  compareDates(selectedDay, day.date) &&
                    !day.isToday &&
                    "bg-card-hover text-text-selected-day",
                  day.isToday &&
                    !compareDates(selectedDay, day.date) &&
                    "bg-chip-bg",
                )}
              >
                {day.date.getDate()}
              </time>
            </button>
          ))}
        </div>
      </div>
      <div id="agenda-view" className="mt-10 ">
        <div>
          <div className="date-label-container mb-4 p-2">
            <div className="date flex-1">
              <div>
                <span className="cursor-pointer text-xl font-semibold text-text-bg">
                  {format(selectedDay, "EEEE")}
                </span>
              </div>
              <span className="cursor-pointer text-lg text-text-bg">
                {format(selectedDay, "MMMM, d")}
              </span>
            </div>
          </div>
        </div>
        <div className="flex w-full bg-sidebar-bg flex-auto overflow-y-auto ">

            <DayCalendar events={events}
                         tasks={taskzEvents}
                         timeformat={timeformat}
                         onClickEvent={onClickEvent}
                         onClickTimeLine={onClickTimeline}
                         onDragStop={onDragStop}
                         onResizeStop={onResizeStop}
            />

        </div>
      </div>
      {modalOpen && modalEvent !== null && <EventDetail event={modalEvent} closeModal={closeModal}/>}
      {modalOpen && modalTask !== null && <TaskDetail task={modalTask} closeTaskModal={closeModal}/>}
      {createEventModalOpen && <EventCreate date={modalDate} closeModal={closeCreateModal}/>}
      {createTaskModalOpen && <TaskCreate date={modalDate} closeModal={closeCreateModal}/>}
    </div>
  );
}
