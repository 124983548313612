import { useState } from "react";
import {
    sidebarnav_context,
} from "../Contexts";
import Sidebar_navigation_left from "./Content/Nav/Sidebar_left/Sidebar_navigation_left";
import Main_nav from "./Content/Nav/Main_nav";
import Sidebar_navigation_right from "./Content/Nav/Sidebar_right/Sidebar_navigation_right";




export default function Layout({ children }) {
  const [sidebarNav, setSidebarNav] = useState(null);

  return (
    <>
          <sidebarnav_context.Provider value={{ sidebarNav, setSidebarNav }}>
                <Sidebar_navigation_left />

                <div id="content" className="flex flex-col min-h-max">
                  <div id="navigation">
                    <Main_nav />
                  </div>
                  <div id="page_contents" className="flex flex-col flex-1">
                    {children}
                  </div>
                </div>
                <Sidebar_navigation_right />
          </sidebarnav_context.Provider>
    </>
  );
}
