
import React, {useContext, useEffect, useState} from "react";
import {CombatContext} from "../../../../../App/utils/Contexts/CombatContext";
import {LevelContext} from "../../../../../App/utils/Contexts/LevelContext";
import {
    calcBattleTimeRemaining, calcDamage,
    format_time,
    getCurrentPercentage,
    getPercentageRemaining
} from "../../../../../App/utils/CombatUtils";
import {getMobname} from "../../../../helpers";
import Content from "../../../Content";
import Layout from "../../../Layout";



export default function Combat() {
    const {combat,hasActiveCombat} = useContext(CombatContext)
    const {character} = useContext(LevelContext)
    const [visibleDamage, setVisibleDamage] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState(hasActiveCombat?calcBattleTimeRemaining(combat,character.dexterity):null)

    const interval = setInterval(() => {
        setTimeRemaining(calcBattleTimeRemaining(combat,character.dexterity));
    }, 1000);

    useEffect(()=> {
        if(hasActiveCombat){
            setTimeRemaining(calcBattleTimeRemaining(combat,character.dexterity))
        }
    },[])
    return (
        <Layout>
            <Content>
                <div className="flex flex-1">
                    {!hasActiveCombat &&
                        <div className="combatInfoContainer flex flex-1 items-center justify-center text-text-faded">
                            No active battle
                        </div>

                    }
                    {hasActiveCombat &&
                        <div className="combatInfoContainer flex flex-col flex-1 items-center justify-center w-full">
                            <div className="combatTime flex mb-8 w-full justify-center">
                        <span className="text-text-bg font-semibold">
                            {format_time(timeRemaining)}
                        </span>
                            </div>
                            <div className="enemyName flex mb-8 w-full justify-center">
                                <span className="text-text-bg font-semibold">{getMobname(combat.mob_name)}</span>
                            </div>
                            <div className="enemyImage flex w-48 h-48 rounded-full bg-text-faded items-center justify-center mb-8">
                                <img src={"assets/game/mobs/"+combat.mob_name+".png"} alt="mob" className="ml-3 w-36 h-36"/>
                            </div>

                            <div className="enemyStats flex">
                                <div className="min-w-48 h-4 bg-text-bg-inverse rounded-full ">
                                    <div
                                        className="h-4 bg-color-success rounded-full text-xs font-semibold text-chip-text text-center"
                                        style={{marginRight: getPercentageRemaining(getCurrentPercentage(combat.current_health, combat.mob.health),) + "%"}}

                                    >
                                        {combat.current_health+ "/" + combat.mob.health}
                                    </div>
                                </div>
                            </div>
                            <div className="enemyWeakness flex flex-col justify-center items-center mb-8 cursor-pointer" data-tooltip-target="tooltip-damage" onClick={()=>setVisibleDamage(true)}>
                        <span className="text-text-bg">
                            This monster is weak to
                        </span>
                                <span className="font-semibold text-chip-bg"> {combat.weakness === "completion"?"completing tasks":"planning tasks"}</span>
                            </div>
                            <div id="tooltip-damage" role="tooltip"
                                 className="absolute z-50 invisible inline-block px-3 py-2 text-sm font-medium text-text-bg transition-opacity duration-300 bg-disabled-bg rounded-lg shadow-sm opacity-0 tooltip">
                                ?
                                <div className="tooltip-arrow" data-popper-arrow=""></div>
                            </div>
                            {
                                visibleDamage &&
                                <div className="cursor-pointer flex flex-col justify-start text-text-bg px-1.5 overflow-x-hidden mb-5 p-2 min-h-20" onClick={()=>setVisibleDamage(false)}>

                                    <div className="flex flex-col justify-center items-center">
                                <span className="text-text-bg">
                                    For every task you {combat.weakness === "completion"?"complete":"plan"} you will deal
                                </span>
                                        <span className="font-semibold text-chip-bg">{calcDamage({strength:character.strength,wisdom:character.wisdom,intelligence:character.intelligence})} damage</span>

                                    </div>
                                </div>
                            }
                        </div>

                    }
                </div>
            </Content>
        </Layout>

    );
}
