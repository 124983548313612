import {apiGetActiveGoogleTasks, apiGetTask, apiRemoveGoogle, apiUpdateTask} from "../api/tasksApi";
import {addHours, addMinutes, format,isAfter} from "date-fns";
import {checkToken, getgCalEvent, updategCalEvent} from "./CalendarUtils";





export async function updateEventFromTask(task,token){
    const start = new Date(task.scheduled)
    const startTime = task.google_scheduled_time.split(':')
    start.setHours(Number(startTime[0]))
    start.setMinutes(Number(startTime[1]))

    const estimate = task.estimatedTime.split(':')
    var end = new Date(start)
    end = addHours(end,Number(estimate[0]))
    end = addMinutes(end,Number(estimate[1]))

    const options = {
        taskzId : task.id,
        lastSync : new Date().toISOString()
    }
    return await updategCalEvent('primary',task.google_calendar_id,start,end,task.title,task.description,task.id,token, options)
}


export async function checkForUpdates(eventList,supabase,token){
    eventList.map(async (event) => {
        if (event.hasOwnProperty('extendedProperties')) {
            var updated = new Date(event.updated)
            var lastSync = new Date(event.extendedProperties.private.lastSync)
            if (isAfter(addMinutes(updated, 3), lastSync) || !event.extendedProperties.private.hasOwnProperty("lastSync")){
                // event has been updated after last sync, so task isn't up to date.
                updateTask(event,supabase)
                setLastSync('primary', event, token)
            }
        }
    })
}
export async function setLastSync(calId, event, token) {
    const string = `https://www.googleapis.com/calendar/v3/calendars/${calId}/events/${encodeURIComponent(event.id)}`
    var body = {
        start: {
            "dateTime": event.start.dateTime
        },
        end: {
            "dateTime": event.end.dateTime
        },
        summary: event.summary,
        extendedProperties: {
            private: {
                taskzId: event.extendedProperties.private.taskzId,
                lastSync: new Date().toISOString()
            }
        },
    }
    return await fetch(string, {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(body)
    }).then((data) => {
        return data.json()
    })
}
export async function updateTask(event,supabase){
    const task = await apiGetTask(supabase,event.extendedProperties.private.taskzId)
    task.title = event.summary

    var start = new Date(event.start.dateTime)
    var end = new Date(event.end.dateTime)
    var hourStart = start.getHours()
    var minuteStart = start.getMinutes()
    var hourEnd = end.getHours()
    var minuteEnd = end.getMinutes()
    var hourDiff = hourEnd - hourStart
    var minuteDiff = minuteEnd - minuteStart

    task.scheduled = format(start,'yyyy-MM-dd')
    task.google_scheduled_time = format(start,'HH:mm:ss')
    task.timeEstimate = hourDiff + ':' + minuteDiff+':00'
    apiUpdateTask(supabase,task)
}

export async function checkLoginSync(supabase,session){
    await checkToken(session)
    var token = session.provider_token
    if(!session.provider_token)return
    const tasks = await apiGetActiveGoogleTasks(supabase)
    tasks.map((task)=>{
        getgCalEvent('primary',task.google_calendar_id,token).then((event)=>{
            if(event.status ==='cancelled'){
                apiRemoveGoogle(supabase,task.id)
            }
            else{
                var updated = new Date(event.updated)
                var lastSync = new Date(event.extendedProperties.private.lastSync)
                if (isAfter(addMinutes(updated, 3), lastSync) || !event.extendedProperties.private.hasOwnProperty("lastSync")){
                    // event has been updated after last sync, so task isn't up to date.
                    updateTask(event,supabase)
                    setLastSync('primary', event, token)
                }
            }
        })
    })
}
