import { LuArrowRightToLine, LuArrowLeftToLine } from "react-icons/lu";
import { useContext, useEffect } from "react";
import {
 SidebarContext,
  sidebarnav_context,
} from "../../../../Contexts";
import SidebarCalendar from "./Sidebar_Calendar/SidebarCalendar";
import SidebarBacklog from "./Sidebar_backlog/SidebarBacklog";
import { classNames } from "../../../../helpers";
import { right_sidebar_navigation } from "../../../../Constants";
import SidebarCombat from "./Sidebar_combat/SidebarCombat";

export default function Sidebar_navigation_right() {
  const { sidebarRight,setSidebarRight,toggleSidebarRight } = useContext(SidebarContext)
  const { sidebarNav, setSidebarNav } = useContext(sidebarnav_context);

  function openSidebarRight(ref) {
    setSidebarRight(true);
    setSidebarNav(ref);
  }
  useEffect(() => {
    setSidebarNav("calender");
  }, []);
  return (
    <>
      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:right-0 lg:z-50 lg:block lg:w-20 lg:overflow-y-auto lg:bg-sidebar-nav-bg lg:pb-4">
        <nav className="mt-8">
          <ul role="list" className="flex flex-col items-center space-y-1">
            <li>
              <button
                id="toggleRightSidebarButton"
                className="text-text-bg-inverse hover:text-white hover:bg-sidebar-nav-bg group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold"
                onClick={() => toggleSidebarRight()}
              >
                {sidebarRight == null || sidebarRight ? (
                  <LuArrowRightToLine
                    className="h-6 w-6 shrink-0"
                    aria-hidden="true"
                  />
                ) : (
                  <LuArrowLeftToLine
                    className="h-6 w-6 shrink-0"
                    aria-hidden="true"
                  />
                )}
                <span className="sr-only">Close sidebar</span>
              </button>
            </li>
            {right_sidebar_navigation.map((item) => (
              <li key={item.name}>
                <button
                  onClick={() => openSidebarRight(item.href)}
                  className={classNames(
                      item.href === sidebarNav && (sidebarRight !== null ? sidebarRight : true)
                          ? "text-chip-accent"
                          : "text-text-bg-inverse/90 hover:text-chip-bg",
                      "group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold",
                  )}
                >
                  <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                  <span className="sr-only">{item.name}</span>
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </div>

      <aside
        className={classNames(
          (sidebarRight !== null ? sidebarRight : true) ? "lg:flex" : "",
          " bg-sidebar-bg fixed bottom-0 right-20 top-0 hidden w-96 overflow-y-auto border-l border-text-bg-inverse px-4 py-6 sm:px-6 lg:px-8",
        )}
      >
        {renderSidebar(sidebarNav)}
      </aside>
    </>
  );
}

function renderSidebar(state) {
  if (state === "backlog") {
    return <SidebarBacklog />;
  }
  else if (state === "combat") {
  return <SidebarCombat />;
  }
  else{
    return <SidebarCalendar />;
  }
}

