'use client'

import { ThemeProvider } from 'next-themes'
import {useSelector} from "react-redux";


export const Theme = ({ children }) => {
    const settings=useSelector(state => state.settings).settings
    var theme = 'dark'
    if(settings.theme_light){theme = 'light'}
    return (
        <ThemeProvider attribute="class">
            <div className={theme}>
                {children}
            </div>

        </ThemeProvider>
    );
};
