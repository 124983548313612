import React from 'react'
import Event from "./Event";
import {returnHourHeaderHeight, returnHourWrapperHeight} from "../../../../../../App/utils/CalendarUtils";
import {Droppable} from "react-beautiful-dnd";
import {isAfter, isBefore} from "date-fns";

export default class Daily extends React.PureComponent{
    constructor(props) {
        super(props);
        this.onClickTimeLine = this.onClickTimeLine.bind(this);
    }
    componentDidMount() {
        this.getHourPosition();
        this.getCurrentHourPosition();
    }
    componentDidUpdate() {
        this.getHourPosition();
        this.getCurrentHourPosition()
    }
    getTimeLineEvents() {
        const { events } = this.props;
        if (Array.isArray(events) && events.length) {
            return events.filter(e => (
                e.position !== 'middlePosition' &&
                !e.allDay && e.span !== 1 &&
                !(e.position === 'endPosition' && this.getTimeInHours(new Date(e.to)) === 0)
            ));
        }
        return [];
    }
    getAllDayEvents() {
        const { events } = this.props;
        if (Array.isArray(events) && events.length) {
            return events.filter(e => (
                e.position === 'middlePosition' ||
                e.allDay || e.span === 1 ||
                (e.position === 'endPosition' && this.getTimeInHours(new Date(e.to)) === 0)
            ));
        }
        return [];
    }
    getHourPosition() {
        const { events } = this.props;
        if (Array.isArray(events) && events.length) {
            const dayEvents = this.getTimeLineEvents();
            const hourWrapperHeight = returnHourWrapperHeight();
            const hourHeaderHeight = returnHourHeaderHeight() / 2;

            // const eventWidthHandled = [];
            dayEvents.forEach((event, index) => {

                let overlap = this.checkOverlap(event,dayEvents)

                const id = `dailyEvent-${event.id}-${event.date.getDate()}`;
                const eventPosition = this.calcEventPosition(event,hourWrapperHeight, hourHeaderHeight);
                const eventHeight = this.calcEventHeight(event,hourWrapperHeight);
                document.getElementById(id).style.top = `${eventPosition}px`;
                document.getElementById(id).style.height = `${eventHeight}px`;
                document.getElementById(id).style.width = `calc((100% / ${overlap + 1}) - 10px)`;
                document.getElementById(id).style.left = `calc((100% / ) * ${index})`;
            });
        }
    }
    calcEventPosition(event, hourWrapperHeight, hourHeaderHeight){
        let fromDate = new Date(event.from);
        let fromHour = this.getTimeInHours(fromDate);
        if (event.position === 'endPosition') {
            fromHour = 0;
        }
        return  (fromHour * hourWrapperHeight + hourHeaderHeight)-10;
    }
    calcEventHeight(event,hourWrapperHeight){
        let fromDate = new Date(event.from);
        let toDate = new Date(event.to);
        let fromHour = this.getTimeInHours(fromDate);
        let toHour = this.getTimeInHours(toDate);
        if (event.position === 'endPosition') {
            fromHour = 0;
            if (toHour === 0) {
                toHour = 24;
            }
        }
        if (event.position === 'startPosition' || (event.span === 1 && toHour === 0)) {
            toHour = 24;
        }
        const timeDiff = toHour - fromHour;
        return timeDiff * hourWrapperHeight;
    }
    getCurrentHourPosition(){
        const currentTime = new Date();
        const currenthour = currentTime.getHours()
        const currentMinute = currentTime.getMinutes()
        const hourWrapperHeight = returnHourWrapperHeight();
        const hourHeaderHeight = returnHourHeaderHeight() / 2;

        var linePosition = (currenthour * hourWrapperHeight + hourHeaderHeight)-10
        const nextHourPosition = ((currenthour + 1) * hourWrapperHeight + hourHeaderHeight)-10
        const difference = nextHourPosition - linePosition
        const distance = Math.floor(difference/4)
        if( currentMinute >= 45) {
            linePosition = linePosition + (distance*3)
        }
        else if(currentMinute>=30) {linePosition = linePosition + (distance*2)}
        else if(currentMinute>=15) {linePosition = linePosition + distance}
        document.getElementById('currentHourLine').style.top = `${linePosition}px`;
    }
    returnCurrentHour(){
        return(
            <div id="currentHourLine" className=" h-px w-full absolute bg-chip-bg z-40"/>
        )
    }
    getTimeInHours(date) {
        return date.getHours() + date.getMinutes() / 60 + date.getSeconds() / 3600000;
    }
    getTimesHH(start, end) {
        // Convert to number of hours
        start = parseInt(start)
        end = parseInt(end)
        // Produce series
        return Array.from({length: end - start}, (_, i) =>
            (((i + start) >> 0) + ":00"))
    }
    getTimesAM(start, end) {
        start = "00:00"
        end = "24:00"
        // Convert to number of hours
        start = parseInt(start)
        end = parseInt(end)
        return Array.from({length: end - start}, (_, i) =>
            [i + start >> 0,0]
        ).map(([h]) =>
            `${h % 12 || 12} ${"AP"[+(h > 11)]}M`.replace(/\b\d\b/g, "0$&")
        );
    }
    returnHours() {
        const start = "00:00"
        const end = "24:00"

        const hours = this.props.timeformat === "HH" ? this.getTimesHH(start, end) : this.getTimesAM(start, end);
        return hours.map((hour, i) => {
            return (
                <div key={i} className="dailyHourWrapper h-10 z-30">
                    <div className="dailyHour relative h-5">
                        <span>{hour}</span>
                    </div>
                </div>
            );
        });
    }
    returnHoursLine() {
        const hourlineList = []
        for(let i = 1; i < 25; i++){
                hourlineList.push(
                    <div key={i} id={"wrapperhour-"+i}className="dailyHourWrapper h-10 z-30">
                        <div id={"hour-"+i} className="dailyHour h-7">
                            <div className="dailyHourLine h-px w-full bg-text-bg/50"/>
                        </div>
                    </div>
                )
        }
        return hourlineList
    }
    checkOverlap(event,events){
        let eventFrom = new Date(event.from)
        let eventTo = new Date(event.to)
        let eventID = event.id
        let overlap = 0;
        events.map((e)=> {
            if(e.id !== eventID){
                let eFrom = new Date(e.from)
                let eEnd = new Date(e.end)
                if(isBefore(eFrom,eventFrom) && isAfter(eEnd,eventFrom)){
                    overlap +=1
                }
                else if(isAfter(eFrom,eventFrom) && isBefore(eFrom,eventTo)){
                    overlap +=1
                }
            }
        })
        return overlap
    }
    returnEvents() {
        const dayEvents = this.getTimeLineEvents();
        if (Array.isArray(dayEvents) && dayEvents.length) {
            return dayEvents.map(event => {

                return (

                    <div
                        key={event.id}
                        id={`dailyEvent-${event.id}-${event.date.getDate()}`}
                        className="dayEvent absolute box-border w-full z-50"
                    >
                        <Event
                            startPosition = {this.calcEventPosition(event,returnHourWrapperHeight(), (returnHourHeaderHeight()/2))}
                            startheight = {this.calcEventHeight(event,returnHourWrapperHeight())}
                            id = {event.id}
                            eventType={dayEvents.length>1?'dayEvent':'singleDayEvent'}
                            date={event.date.getDate()}
                            color={event.color}
                            summary={event.summary}
                            overlap = {this.checkOverlap(event,dayEvents)}
                            onClick={() => this.onClickEvent(event)}
                            onDragStop={(e,d,startPosition,startHeight)=>this.onDragStop(e,d,event,startPosition,startHeight)}
                            onResizeStop={(e,direction,ref,delta,position,startPosition,startHeight)=>this.onResizeStop(e,direction,ref,delta,position,event,startPosition,startHeight)}
                        />
                    </div>
                );
            });
        }
    }
    onClickEvent(event) {
        if (this.props.onClickEvent) {
            this.props.onClickEvent(event);
        }
    }
    onDragStop(e,d,event,startPosition,startHeight){
        if(this.props.onDragStop){
            this.props.onDragStop(e,d,event,startPosition,startHeight)
        }
    }
    onResizeStop(e,direction,ref,delta,position,event,startPosition,startHeight){
        if(this.props.onResizeStop){
            this.props.onResizeStop(e,direction,ref,delta,position,event,startPosition,startHeight)
        }
    }
    onClickTimeLine(event) {
        if (this.props.onClickTimeLine) {
            const scrollTop = document.getElementById('dailyTimeLine').scrollTop;
            const clientY = event.clientY;
            let rect = document.getElementById('dailyTimeLine').getBoundingClientRect();
            const positionY = clientY + scrollTop - rect.top - (returnHourHeaderHeight() / 2);
            let hourPosition = positionY / returnHourWrapperHeight();
            let hour = Math.round(hourPosition * 2) / 2;
            if (hour <= 0) {
                hour = 0;
            }
            if (hour > 24) {
                hour = 24;
            }
            this.props.onClickTimeLine(hour);
        }
    }
    returnTimeLine() {
        return (
            <Droppable droppableId="dailyTimeLine" renderClone={(provided,snapshot,rubric)=> (
                <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                    <></>
                </div>
            )}>
                {(provided)=>(
                    <>
                    <div id='dailyTimeLine' className="dailyTimeLineWrapper relative overflow-y-scroll z-30" onClick={this.onClickTimeLine} {...provided.droppableProps} ref={provided.innerRef}>
                        <div className="dailyHourTextWrapper absolute bg-modal-bg text-text-bg px-2 pt-2 rounded-lg">{this.returnHours()}</div>
                            <div className="dailyTimeLine absolute" >
                                <div>
                                    {this.returnEvents()}
                                    {this.returnCurrentHour()}
                                </div>
                                {this.returnHoursLine()}
                            </div>
                    </div>
                    {provided.placeholder}
                    </>
                )}
            </Droppable>
        );
    }
    returnAllDayEvents() {
        const dailyEvents = this.getAllDayEvents();
        if (Array.isArray(dailyEvents) && dailyEvents.length) {
            return dailyEvents.map(event => {
                return (
                    <div key={event.id} className="allDayEvent min-h-5 box-border z-50 flex-1">
                        <Event
                            id = {event.id}
                            eventType={dailyEvents.length>1?'allDayEvent':'singleAllDayEvent'}
                            overlap = {0}
                            color={event.color}
                            summary={event.summary}
                        />
                    </div>
                );
            });
        }
    }

    render() {
        return (
            <div className="dailyWrapper">
                <div className="flex mb-2 gap-3 justify-between">{this.returnAllDayEvents()}</div>
                {this.returnTimeLine()}
            </div>
        );
    }
}
