import React, {useEffect, useState} from "react";
import {
    apiChoosteRotationScroll,
    apiCreateRotation,
    apiGetQuests,
    apiGetRotationLoottable,
    apiGetScrollRotation, apiUpdateRotation
} from "../../../../../App/api/Guild/GuildApi";
import {useSupabaseClient} from "@supabase/auth-helpers-react";
import {apiGetCharacter} from "../../../../../App/api/characterApi";
import {calcCurrentLevel, getMobname} from "../../../../helpers";
import {
    createRotation,
    getCommonRotation,
    getQuestIds,
    getUncommonRotation
} from "../../../../../App/utils/LoottableScrollsUtils";
import {addHours, isAfter} from "date-fns";
import {apiAddScroll} from "../../../../../App/api/Inventory/InventoryApi";
import {toast} from "react-toastify";
import {toastOptions} from "../../../../Constants";

export default function Guild() {
    const [rotation, setRotation] = useState([])
    const supabase = useSupabaseClient()
    const [hasChosen, setHasChosen] = useState(false)

    function checkRotaRefresh(date,time){
        var d = new Date()
        var rotaDate = new Date(date)
        var t = time.split(':')
        rotaDate.setHours(t[0])
        rotaDate.setMinutes(t[1])
        if(isAfter(d,addHours(rotaDate,8))){
            return true
        }
        return false
    }
    async function acceptQuest(quest){
        setHasChosen(true)
        await apiChoosteRotationScroll(supabase)
        await apiAddScroll(supabase,quest)
        toast.success("Quest accepted. The scroll has been added to your inventory",toastOptions)
    }
    useEffect(()=> {
        async function getRotation(){
            const rota = await apiGetScrollRotation(supabase)
            if(rota.length === 0 || checkRotaRefresh(rota[0].rolled_date,rota[0].rolled_time)){
                const character = await apiGetCharacter(supabase)
                var level = calcCurrentLevel(character.experience)
                const loottable = await apiGetRotationLoottable(supabase,level)
                var common = getCommonRotation(loottable[0].common_pool)
                var uncommon = getUncommonRotation(loottable[0].uncommon_pool)

                var questIds = getQuestIds(common,uncommon)
                var quests = await apiGetQuests(supabase,questIds)

                var roti = createRotation(common,uncommon,quests)
                if(rota.length === 0){
                    await apiCreateRotation(supabase,roti)
                }
                else{
                    await apiUpdateRotation(supabase,roti)
                }
                setRotation(roti)
                setHasChosen(roti.has_chosen === undefined?false:roti.has_chosen)
            }
            else{
                setRotation(rota[0])
                setHasChosen(rota[0].has_chosen === undefined?false:rota[0].has_chosen)
            }
        }
        getRotation()

    },[])
    return (
        <div
            style={{
                backgroundImage: "url(./assets/game/locations/guildhall_1_t.png)"}}
            className="flex flex-1 mt-4 justify-center bg-cover bg-no-repeat location-image">

                    {/* Main 2 column grid */}
                    <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-2 lg:gap-8 w-full  h-full">
                        {/* Left column */}
                        <div className="grid-cols-1 gap-4 flex flex-1 p-10 mb-10 h-full">
                            <div className=" lg:rounded-lg bg-modal-bg lg:shadow-lg flex flex-1 flex-col justify-center h-full mb-10">
                                <span className="text-lg text-text-bg font-semibod flex justify-center mt-8 mb-1">Available Quests</span>
                                <span className="text-sm text-text-bg flex justify-center mb-8 mx-8">Choose a scroll to accept. The list refreshes every 8 hours</span>
                                <div className="flex flex-1 flex-col scroll-container">
                                    <div className="flex items-center justify-between mb-8 mx-4" >
                                        <div className="flex items-center">
                                            <div className="w-16 h-16 rounded-full bg-text-faded flex items-center mr-2">
                                                <img src="/assets/game/items/quest_scroll.png" alt="scroll" className="w-12 h-12 m-auto"/>
                                            </div>
                                            <div className="flex flex-col">
                                                <span> {rotation.scroll_1_type + " "+rotation.scroll_1_amount+" "+getMobname(rotation.scroll_1_target)}{rotation.scroll_1_amount>1 && ""}</span>
                                                <span className="text-sm"> {rotation.scroll_1_rarity} quest</span>
                                            </div>

                                        </div>
                                        {!hasChosen &&
                                            <button className="p-2 bg-chip-bg text-chip-text rounded-lg hover:bg-chip-accent" onClick={()=>acceptQuest({
                                                quest:rotation.scroll_1_quest,
                                                quest_target_amount:rotation.scroll_1_amount,
                                                quest_type:rotation.scroll_1_type,
                                                scroll_rarity:rotation.scroll_1_rarity,
                                                quest_target:rotation.scroll_1_target
                                            })}>
                                                Accept quest
                                            </button>
                                        }
                                    </div>
                                    <div className="flex items-center justify-between mb-8 mx-4" >
                                        <div className="flex items-center">
                                            <div className="w-16 h-16 rounded-full bg-text-faded flex items-center mr-2">
                                                <img src="/assets/game/items/quest_scroll.png" alt="scroll" className="w-12 h-12 m-auto"/>
                                            </div>
                                            <div className="flex flex-col">
                                                <span> {rotation.scroll_2_type + " "+rotation.scroll_2_amount+" "+getMobname(rotation.scroll_2_target)}{rotation.scroll_2_amount>1 && ""}</span>
                                                <span className="text-sm"> {rotation.scroll_2_rarity} quest</span>
                                            </div>

                                        </div>
                                        {!hasChosen &&
                                            <button className="p-2 bg-chip-bg text-chip-text rounded-lg hover:bg-chip-accent"onClick={()=>acceptQuest({
                                                quest:rotation.scroll_2_quest,
                                                quest_target_amount:rotation.scroll_2_amount,
                                                quest_type:rotation.scroll_2_type,
                                                scroll_rarity:rotation.scroll_2_rarity,
                                                quest_target:rotation.scroll_2_target
                                            })}>
                                                Accept quest
                                            </button>
                                        }
                                    </div>
                                    <div className="flex items-center justify-between mb-8 mx-4" >
                                        <div className="flex items-center">
                                            <div className="w-16 h-16 rounded-full bg-text-faded flex items-center mr-2">
                                                <img src="/assets/game/items/quest_scroll.png" alt="scroll" className="w-12 h-12 m-auto"/>
                                            </div>
                                            <div className="flex flex-col">
                                                <span> {rotation.scroll_3_type + " "+rotation.scroll_3_amount+" "+getMobname(rotation.scroll_1_target)}{rotation.scroll_3_amount>1 && ""}</span>
                                                <span className="text-sm"> {rotation.scroll_3_rarity} quest</span>
                                            </div>

                                        </div>
                                        {!hasChosen &&
                                            <button className="p-2 bg-chip-bg text-chip-text rounded-lg hover:bg-chip-accent"onClick={()=>acceptQuest({
                                                quest:rotation.scroll_3_quest,
                                                quest_target_amount:rotation.scroll_3_amount,
                                                quest_type:rotation.scroll_3_type,
                                                scroll_rarity:rotation.scroll_3_rarity,
                                                quest_target:rotation.scroll_3_target
                                            })}>
                                                Accept quest
                                            </button>
                                        }
                                    </div>
                                    <div className="flex items-center justify-between mb-8 mx-4" >
                                        <div className="flex items-center">
                                            <div className="w-16 h-16 rounded-full bg-text-faded flex items-center mr-2">
                                                <img src="/assets/game/items/quest_scroll.png" alt="scroll" className="w-12 h-12 m-auto"/>
                                            </div>
                                            <div className="flex flex-col">
                                                <span> {rotation.scroll_4_type + " "+rotation.scroll_4_amount+" "+getMobname(rotation.scroll_4_target)}{rotation.scroll_4_amount>1 && ""}</span>
                                                <span className="text-sm"> {rotation.scroll_4_rarity} quest</span>
                                            </div>

                                        </div>
                                        {!hasChosen &&
                                            <button className="p-2 bg-chip-bg text-chip-text rounded-lg hover:bg-chip-accent"onClick={()=>acceptQuest({
                                                quest:rotation.scroll_4_quest,
                                                quest_target_amount:rotation.scroll_4_amount,
                                                quest_type:rotation.scroll_4_type,
                                                scroll_rarity:rotation.scroll_4_rarity,
                                                quest_target:rotation.scroll_4_target
                                            })}>
                                                Accept quest
                                            </button>
                                        }
                                    </div>
                                </div>


                            </div>
                        </div>
                        {/* right column */}
                        <div className="grid grid-cols-1 gap-4 h-full">
                            <div className="">
                                <div className="p-6 h-full">

                                </div>
                            </div>
                        </div>


                    </div>
                </div>

    );
}
