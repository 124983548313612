
import {useLocation} from 'react-router-dom'
import {useEffect } from 'react'

//import {supabase} from './supabase.ts'
import {useNavigate} from 'react-router-dom'
import {useSupabaseClient} from "@supabase/auth-helpers-react";
import {apiSetUserProviderToken} from "../utils/CalendarUtils";

function getCredentials(location){
    const {hash} = location;
    if(!hash) return {success:false}
    const urlParams = new URLSearchParams(hash.replace('#',''));
    const { access_token, refresh_token, provider_token,provider_refresh_token, expires_in,token_type} = Object.fromEntries(urlParams);
    if(!access_token || !refresh_token) return {success:false}
    return {
        success:true,
        access_token: access_token,
        refresh_token: refresh_token,
        provider_refresh_token: provider_refresh_token,
        provider_token: provider_token,
        expires_in: expires_in,
        token_type: token_type
    }
}
function useOnAuthStateChange(){
    const location = useLocation();
    const navigate = useNavigate();
    const supabase = useSupabaseClient()
    useEffect(() => {
        const {
            data: { subscription: listener },
        } = supabase.auth.onAuthStateChange((event, session) => {
            if (event === 'INITIAL_SESSION') {
                const data = getCredentials(location);
                if(data.success){
                    handleSignInToken(supabase,data.access_token,data.refresh_token);
                    window.localStorage.setItem('oauth_provider_token', data.provider_token)
                    window.localStorage.setItem('oauth_provider_refresh_token', data.provider_refresh_token)
                }
            }
            if(event === 'SIGNED_IN'){
                if(window.localStorage.getItem('Taskz_google_authorization') === 'init'){
                    navigate('/settings?Google_authorization_done')
                }
                navigate('/plan')
            }
        })

        return () => {
            listener?.unsubscribe()
        }
    }, [])
}
async function handleSignInToken(supabase,accesstoken,refreshToken){
    const { data, error } = await supabase.auth.setSession({access_token:accesstoken,refresh_token:refreshToken})
    if(!error){
        var acc = localStorage.getItem('oauth_provider_token')
        var ref = localStorage.getItem('oauth_provider_refresh_token')
        data.session.provider_token = acc
        data.session.provider_refresh_token = ref
        apiSetUserProviderToken(supabase,"google",acc,ref)
    }
}

export function useSignIn() {
    return { useOnAuthStateChange }
}
