import React from 'react'
import {classNames} from "../../../../../helpers";
import MonthEvent from "./MonthEvent";
import {getNewDate, getElementHeight} from "../../../../../../App/utils/CalendarUtils";
import {isSameDay,format} from "date-fns";
import {Draggable, Droppable} from "react-beautiful-dnd";

export default class MonthDay extends React.PureComponent{
    constructor(props) {
        super(props);
        this.state = {
            eventNumber: 1,
        };
    }

    componentDidMount() {
        this.shouldShowRemainder();
    }

    componentDidUpdate() {
        this.shouldShowRemainder();
    }
    isCurrentDate() {
        const date = this.props.date.getTime();
        const currentDate = getNewDate(new Date()).getTime();
        return date === currentDate;
    }
    shouldShowRemainder() {
        const { events } = this.props;
        if (Array.isArray(events) && events.length) {
            const dayCell = document.getElementById('dayCell');
            const dayCellHeight = getElementHeight(dayCell);

            const dayHeader = document.getElementById('dayHeader');
            const dayHeaderHeight = getElementHeight(dayHeader);

            const eventsList = document.getElementsByClassName('dayCellEvent');
            const singleEvent = eventsList[0];
            const singleEventHeight = getElementHeight(singleEvent);

            const remainingTextHeight = 16;

            const eventGroupHeight =
                dayCellHeight - dayHeaderHeight - remainingTextHeight;

            const numberOfEventsToDisplay = Math.floor(
                eventGroupHeight / singleEventHeight
            );

            this.setState({
                eventNumber: numberOfEventsToDisplay,
            });
        }
    }
    returnEventList() {
        const { events } = this.props;
        if (Array.isArray(events) && events.length) {
            let displayEvents = events.slice(0, this.state.eventNumber);
            return (
                <div className="dayCellEventWrapper">
                    {this.returnEvents(displayEvents)}
                </div>
            );
        }
    }
    returnEvents(events) {
        return events.map((event,i) => {
            return (
                <MonthEvent
                    index={i}
                    height={16}
                    inactive={!this.props.current}
                    key={event.id}
                    id={event.id}
                    isTaskz={event.isTaskz}
                    timeformat={this.props.timeformat}
                    start={event.from}
                    color={event.color}
                    summary={event.summary}
                    position={event.position}
                    isDesktop={this.props.isDesktop}
                    onClick={() => this.onClickEvent(event)}
                />
            );
        });
    }
    onClickEvent(event) {
        if (this.props.onClickEvent) {
            this.props.onClickEvent(event);
        }
    }
    returnEventRemainder() {
        const { events } = this.props;
        const { eventNumber } = this.state;
        if (Array.isArray(events) && events.length > eventNumber) {
            const remainder = events.length - eventNumber;
            return (
                <span className="dayEventsRemaining">
          {`${remainder} more...`}
        </span>
            );
        }
    }
    render() {
        return (
            <Droppable droppableId={"calendarDay-"+format(this.props.date,"dd/MM/yyyy")}  >
                {(provided,snapshot) => (
                    <div
                        ref= {provided.innerRef}
                        {...provided.droppableProps}
                        id="dayCell"
                        className="dayCell inline-block box-border relative text-right overflow-hidden cursor-pointer p-0.5 h-36 w-divided-7"
                        onClick={this.props.onClickDay}
                    >
                        <div id="dayHeader" className={classNames('dayDate justify-end', this.props.current? '': 'inactiveDay')}>
                            <div className={classNames(
                                (isSameDay(new Date(this.props.selectedDay),this.props.date)|| this.isCurrentDate() )&&"font-semibold rounded-full",
                                (isSameDay(new Date(this.props.selectedDay),this.props.date)&& this.isCurrentDate() )&& "bg-chip-accent text-chip-text",
                                (isSameDay(new Date(this.props.selectedDay),this.props.date)&& !this.isCurrentDate() )&& "bg-card-hover text-text-selected-day",
                                (this.isCurrentDate() && !isSameDay(new Date(this.props.selectedDay),this.props.date))&& "bg-chip-bg text-chip-text",
                                "dayText flex items-center"
                            )}
                            >
                                <span className="items-center">{this.props.date.getDate()}</span>
                            </div>
                        </div>
                        {this.returnEventList()}
                        {this.returnEventRemainder()}
                        {provided.placeholder}
                    </div>
                )}

            </Droppable>

        );
    }

}
