import { GiHealthPotion } from "react-icons/gi";
import { format } from "date-fns";
import {LuScroll, LuSwords, LuX} from "react-icons/lu";
import {NotificationContext} from "../../../../../App/utils/Contexts/NotificationContext";
import React, {useContext} from "react";
import {useSupabaseClient} from "@supabase/auth-helpers-react";
import {apiArchiveNotification} from "../../../../../App/api/NotificationApi";
import {useNavigate} from "react-router-dom";

export default function MessageComponent({ message, old }) {
  const {setNotificationsUpdated, notificationsUpdated} = useContext(NotificationContext)
  const supabase =useSupabaseClient()
  const navigate = useNavigate()
  async function archiveNotification(){
    await apiArchiveNotification(supabase,message.id)
    setNotificationsUpdated(!notificationsUpdated)
  }
  async function triggerQuicklink(){
    if(message.quicklink){
        navigate(message.quicklink)
    }
  }
  function renderIcon(icon){
    if(icon === "combat"){
      return (
          <LuSwords
              className="h-8 w-8 shrink-0 text-primary-600 items-center justify-center flex flex-1"
              aria-hidden="true"
          />
      )
    }
    else if(icon === "quest"){
      return (
          <LuScroll
              className="h-8 w-8 shrink-0 text-primary-600 items-center justify-center flex flex-1"
              aria-hidden="true"
          />
      )
    }
    return (
        <GiHealthPotion
            className="h-8 w-8 shrink-0 text-primary-600 items-center justify-center flex flex-1"
            aria-hidden="true"
        />
    )

  }
  return (
    <div className="message-container w-full" onClick={()=>triggerQuicklink()}>
      <span className="flex justify-start flex-1 items-start w-full text-text-bg text-xs ml-8">
        {old && format(new Date(message.created_at), "dd/MM/YYY")}
        {format(new Date(message.created_at), "HH:mm")}
      </span>
      <div className="w-full p-3 bg-modal-bg px-1.5 rounded-2xl shadow-md ring-1 ring-inset ring-card-ring flex flex-shrink-0">
        <div className="flex flex-1 justify-between">
          <div className="flex-1 flex">
            <div className="flex items-center justify-center">
              <div
                tabIndex="0"
                aria-label="group icon"
                role="img"
                className="focus:outline-none w-12 h-12 border rounded-full border-text-bg-inverse flex flex-shrink-0 items-center justify-center"
              >
                {renderIcon(message.icon)}

              </div>
            </div>
            <div className="flex flex-col ml-6">
              <span className="text-text-bg text-s flex flex-1">
                {message.title}
              </span>
              <span className="text-text-bg text-xs flex items-center flex-1">
                {message.content}
              </span>
              {message.linked_items &&
                  <span className="text-text-bg text-xs flex items-center flex-1">
                    {/*message.linked_items.map(item => (
                        <div className="flex items-center mx-px">
                          <div className="w-10 h-10 rounded-full bg-text-faded flex items-center"  >
                            <img src={"/assets/game/loottable_icons/"+item+".png"} alt="item" className="w-7 h-7 m-auto"/>
                          </div>
                        </div>
                    ))*/}
                  </span>


              }
            </div>
          </div>
          <div className="flex flex-none justify-end items-center">
            <div
              tabIndex="0"
              aria-label="close icon"
              role="button"
              className="focus:outline-none cursor-pointer flex items-center"
            >
              <LuX onClick={() => archiveNotification()}
                className="h-8 w-8 shrink-0 text-icon-faded hover:text-chip-accent items-center justify-center flex flex-1"
                aria-hidden="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
