import DailyGoal from "./DailyGoal";
import {useState} from "react";
import {LuGoal, LuX} from "react-icons/lu";
import DailyplanList from "./DailyplanList";
import DailyplanBacklog from "./DailyplanBacklog";
import {toast} from "react-toastify";
import {toastOptions} from "../../../../Constants";


export default function DaylistContainer({updateMade,setUpdateMade, onSavePlan, mainGoal, setMainGoal, notes, setNotes}){

    //const [showGoal, setShowGoal] = useState(true)
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTask, setModalTask] = useState({});
    const [modalDay, setModalDay] = useState(null);
    const [createTaskModalOpen, setCreateTaskModalOpen] = useState(false);

    const closeModal = (updated, deleted) => {
        setModalOpen(false)
        setModalTask({})
        if (updated) {
            setUpdateMade(!updateMade)
        }
        if (deleted) {
            toast.success('Task deleted succesfully', toastOptions)
            setUpdateMade(!updateMade)
        }
    }
    const openModal = (task) => {
        setModalTask(task);
        setModalOpen(true)
    }
    const openCreateTaskModal = (day) => {
        setCreateTaskModalOpen(true)
        setModalDay(day)
    }
    const closeCreateTaskModal = (created = false) => {
        setCreateTaskModalOpen(false)
        setModalDay(null)
        if (created) {
            setUpdateMade(!updateMade)
        }
    }
    return (
        <main className="pb-8 w-full">
            <div className="mx-auto w-full h-full">
                <h1 className="sr-only text-text-bg">Plan your day</h1>
                {/* Main 3 column grid */}
                <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-4 lg:gap-8 w-full  h-full">
                    {/* Left column */}
                    <div className="grid grid-cols-1 gap-4 lg:col-span-2  h-full">
                            <div className="overflow-x-scroll">
                                <div className="p-6 h-full">
                                    <DailyplanList date={new Date()} openModal={openModal} openCreateTaskModal={openCreateTaskModal}
                                                   modalOpen={modalOpen} modalTask={modalTask} closeModal={closeModal}
                                                   createTaskModalOpen={createTaskModalOpen} modalDay={modalDay}
                                                   closeCreateTaskModal={closeCreateTaskModal} updateMade={updateMade}/>
                                </div>
                            </div>
                    </div>

                    {/* goals column */}
                    <div className="grid grid-cols-1 gap-4 h-full">
                            {
                            <section aria-labelledby="section-2-title">
                                <div className="overflow-hidden lg:rounded-lg bg-modal-bg lg:shadow-lg h-full">
                                    <DailyGoal notes={notes} goal={mainGoal} setGoal={setMainGoal} setNotes={setNotes} onSavePlan={onSavePlan}/>
                                </div>
                            </section>

                                /*
                            {showGoal?
                            <section aria-labelledby="section-2-title">
                                <div className="overflow-hidden lg:rounded-lg bg-modal-bg lg:shadow-lg h-full">
                                    <div className="flex justify-end">
                                        <button onClick={()=>setShowGoal(false)}
                                                className=" m-2 bg-chip-bg text-chip-text hover:bg-chip-accent w-8 h-8 rounded-full flex justify-center items-center">
                                            <LuX className="w-6 h-6"/>
                                        </button>
                                    </div>
                                    <DailyGoal notes={notes} goal={mainGoal} setGoal={setMainGoal} setNotes={setNotes}/>
                                </div>
                            </section>
                            :
                            <div className="flex justify-end">
                                <button onClick={()=>setShowGoal(true)}
                                    className=" m-2 bg-chip-bg text-chip-text hover:bg-chip-accent w-8 h-8 rounded-full flex justify-center items-center">
                                    <LuGoal className="w-6 h-6" />
                                </button>
                            </div>

                            }*/
                        }
                    </div>
                    {/* backlog column */}
                    <div className="grid grid-cols-1 gap-4 h-full">
                        <section aria-labelledby="section-2-title">
                            <div className="overflow-hidden lg:rounded-lg bg-modal-bg lg:shadow-lg h-full">
                                <div className="p-6 h-full">
                                    <DailyplanBacklog updateMade={updateMade} openModal={openModal} modalOpen={modalOpen} modalTask={modalTask} closeModal={closeModal}/>

                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </main>
    )
}
