import {Navigate} from "react-router-dom";
import {useSession, useSessionContext, useSupabaseClient, useUser} from '@supabase/auth-helpers-react'
import {useDispatch, useSelector} from "react-redux";
import {apiGetUserSettings} from "../api/Settings/SettingsApi";
import {initSettings} from "../SettingSlice";
import {checkGoogleAccess} from "../utils/CalendarUtils";
import {isAfter, isSameDay} from "date-fns";
import {QuestContextProvider} from "../utils/Contexts/QuestContext";
import {CombatContextProvider} from "../utils/Contexts/CombatContext";
import {LevelContextProvider} from "../utils/Contexts/LevelContext";
import {NotificationContextProvider} from "../utils/Contexts/NotificationContext";
import {apiGetTasksToRollover, apiRollOverAllTasks, apiTaskRollover, apiUpdateTaskToBacklog} from "../api/tasksApi";
import {apiCreateNotification} from "../api/NotificationApi";
import React from "react";
import {CompanionContextProvider} from "../utils/Contexts/CompanionContext";

const ProtectedRoute = ({ children }) => {
    const { isLoading } = useSessionContext()
    const session = useSession()
    const user = useUser()
    const supabase = useSupabaseClient()
    const settings=useSelector(state => state.settings)
    const dispatch = useDispatch()

    if (isLoading) { return <></>}
    if (!user) {
        // user is not authenticated
        return <Navigate to="/login" />;
    }
    if(settings.settings === [] || settings.lastFetch === null){
        getSettings(supabase,dispatch)
   }
    checkGoogleAccess(supabase,session)

    if(settings.settings !== []){
        taskRollOver(supabase, settings.settings)
        if(checkDailyPlan(settings.settings) && checkDailyPlanTime(settings.settings)){
            if(window.location.pathname !== '/planday'){
                return <Navigate to="/planday" />
            }
        }
        if(checkDailyReview(settings.settings) && checkDailyReviewTime(settings.settings)){
            if(window.location.pathname !== '/reviewday'){
                return <Navigate to="/reviewday" />
            }
        }
    }
    return (
        <NotificationContextProvider>
            <LevelContextProvider>
                <CompanionContextProvider>
                    <QuestContextProvider>
                        <CombatContextProvider>
                            {children}
                        </CombatContextProvider>
                    </QuestContextProvider>
                </CompanionContextProvider>
             </LevelContextProvider>
        </NotificationContextProvider>
        )
};
export default ProtectedRoute

async function getSettings(supabase,dispatch){
    var settingsList = await apiGetUserSettings(supabase)
    dispatch(initSettings(settingsList))
}

async function taskRollOver(supabase, settings){
    if(settings.rollover_limit === null) return
    if(settings.rollover_limit === -1){
        await apiRollOverAllTasks(supabase)
    }
    if(window.localStorage.getItem('lastRolloverCheck') === null || !isSameDay(new Date(window.localStorage.getItem('lastRolloverCheck')),new Date())){
        var tasks = await apiGetTasksToRollover(supabase)
        tasks.map((task)=>{
            if(task.rollover < settings.rollover_limit){
                apiTaskRollover(supabase,task.id,task.rollover+1)
                var notification = {
                    title: "Task: "+task.title+" wasn't completed on the scheduled day",
                    content: "The task has been scheduled for today. The task has rolled over "+(task.rollover+1)+" times",
                    type: "app",
                }
                apiCreateNotification(supabase,notification)
            }
            else{
                apiUpdateTaskToBacklog(supabase,task.id)
                var notification = {
                    title: "Task: "+task.title+" has been moved to your backlog",
                    content: "The task was not completed on the scheduled day" + settings.rollover_limit + " times and has been moved to your backlog as a result",
                    type: "app",
                }
                apiCreateNotification(supabase,notification)
            }
        })
        window.localStorage.setItem('lastRolloverCheck',new Date())
    }
}


function checkDailyPlan(settings){
    if(!settings.enable_daily_plan){
        return false
    }
    if(window.localStorage.getItem('lastPlan') !== null && isSameDay(new Date(window.localStorage.getItem('lastPlan')),new Date())){
        return false
    }
    if(settings.daily_plan_evening === true){
        if(window.localStorage.getItem('lastReview') !== null && isSameDay(new Date(window.localStorage.getItem('lastReview')),new Date())){
            return false
        }
    }
    return true
}

function checkDailyPlanTime(settings){
    if(settings.daily_plan_evening === false){
        if(settings.daily_plan_time === null || settings.daily_plan_time === ''){
            return false
        }
        var time = settings.daily_plan_time.split(':')
        var now = new Date()
        var planTime = new Date()
        planTime.setHours(time[0])
        planTime.setMinutes(time[1])
        if(isAfter(now,planTime)){
            return true
        }
    }
    else{
        if(window.localStorage.getItem('lastReview') !== null && isSameDay(new Date(window.localStorage.getItem('lastReview')),new Date())){
            if(window.localStorage.getItem('lastPlan') !== null && isSameDay(new Date(window.localStorage.getItem('lastPlan')),new Date())){
                return false
            }
            return true
        }
    }
    return false
}

function checkDailyReview(settings){
    if(!settings.enable_daily_review){
        return false
    }
    if(window.localStorage.getItem('lastReview') !== null && isSameDay(new Date(window.localStorage.getItem('lastReview')),new Date())){
        return false
    }
    return true
}
function checkDailyReviewTime(settings){
    if(settings.daily_review_time === null || settings.daily_review_time === ''){
        return false
    }
    var time = settings.daily_review_time.split(':')
    var now = new Date()
    var planTime = new Date()
    planTime.setHours(time[0])
    planTime.setMinutes(time[1])
    if(isAfter(now,planTime)){
        return true
    }
    return false
}
