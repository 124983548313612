import {getUserID} from "../ApiConfig";


export async function apiGetQuestLoottable(supabase,questId){

    const {data:loottable,error} = await supabase.from('Quests').select(`details,loottable (name,pools,roll_min,roll_max,roll_type)`).eq('id',questId)
    if(!error){
        return loottable[0].loottable
    }
    return []
}
export async function apiGetMobLoottable(supabase,mobId){

    const {data:loottable,error} = await supabase.from('Mobs').select(`id,loottable (name,pools,roll_min,roll_max,roll_type)`).eq('id',mobId)
    if(!error){
        return loottable[0].loottable
    }
    return []
}

export async function apiStartQuest(supabase,scroll){
    const id = await getUserID(supabase)
    if (!id) return {}

    const {data:s, error} = await supabase.from('Active_quests').insert({user:id,type:scroll.scroll_rarity,quest_type:scroll.quest_type,quest:scroll.quest,quest_target:scroll.quest_target,quest_target_amount:scroll.quest_target_amount}).select()
    if(!error){
        return s
    }
    return {}
}


export async function apiGetActiveQuests(supabase){

    const {data:quests, error} = await supabase.from('Active_quests').select('*')
    if(!error){
        return quests
    }
    return []
}


export async function apiGetActiveCombat(supabase){

    const {data:combat, error} = await supabase.from('Active_battles').select(`id,mob_name,mob_level,current_health,weakness,limit_amount,created_at,mob (id,health,defence,limit_type)`)
    if(!error){
        return combat
    }
    return []
}

export async function apiGetMobs(supabase,location){

    const {data:mobs, error} = await supabase.from('Mobs').select(`id,name,machine_name,health,attack,defence,speed,limit_type,limit_min,limit_max,weakness,loottable (name,pools)`).eq('location', location)
    if(!error){
        return mobs
    }
    return []
}

export async function apiStartFight(supabase,combat){
    const id = await getUserID(supabase)
    if (!id) return []

    combat.user = id
    const {data:fight,error} = await supabase.from('Active_battles').insert(combat).select()
    if(!error){
        return fight
    }
    return []
}
export async function apiEndFight(supabase,combatid){

    const {data,error} = await supabase.from('Active_battles').delete().eq('id',combatid)
    if(!error){
        return data
    }
    return []
}

export async function apiUpdateMobHealth(supabase, combatid,newHealth){

    const {data,error} = await supabase.from('Active_battles').update({current_health:newHealth}).eq('id',combatid)
    if(!error){
        return data
    }
    return []
}

export async function apiIncreaseQuestProgress(supabase,questid,newprogress){

    const {data,error} = await supabase.from('Active_quests').update({progress:newprogress}).eq('id',questid)
    if(!error){
        return data
    }
    return []
}

export async function apiCollectedQuestReward(supabase,quest){

    const {data,error} = await supabase.from('Active_quests').update({rewards_collected:true}).eq('id',quest)
    if(!error){
        return data
    }
    return []
}
