import {formatRFC3339, setHours, setMinutes, setSeconds} from "date-fns";
import {useState} from "react";
import {getUserID} from "../api/ApiConfig";

export const getElementHeight = element => {
    let height = element.offsetHeight;
    height += parseInt(
        window.getComputedStyle(element).getPropertyValue('margin-top')
    );
    height += parseInt(
        window.getComputedStyle(element).getPropertyValue('margin-bottom')
    );
    height += parseInt(
        window.getComputedStyle(element).getPropertyValue('padding-top')
    );
    height += parseInt(
        window.getComputedStyle(element).getPropertyValue('padding-bottom')
    );

    return height;
};

export const getElementWidth = element => {
    let width = element.offsetWidth;
    width += parseInt(
        window.getComputedStyle(element).getPropertyValue('margin-left')
    );
    width += parseInt(
        window.getComputedStyle(element).getPropertyValue('margin-right')
    );
    width += parseInt(
        window.getComputedStyle(element).getPropertyValue('padding-left')
    );
    width += parseInt(
        window.getComputedStyle(element).getPropertyValue('padding-right')
    );

    return width;
};

export const getNewDate = date => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};
export function setTime(date,hours,minutes,seconds){
    var res = setHours(date,hours)
    res = setMinutes(res,minutes)
    res = setSeconds(res,seconds)
    return res
}
export function returnHourWrapperHeight() {
    const hourWrappers = document.getElementsByClassName('dailyHourWrapper');
    const wrapper = getElementHeight(hourWrappers[0]);
    return wrapper || 0;
}
export function returnHourHeaderHeight() {
    const hourHeader = document.getElementsByClassName('dailyHour');
    const header = getElementHeight(hourHeader[0]);
    return header || 0;
}


export function getCalendarColors(settings,calendar){
    if(settings === null) return {}
    settings = JSON.parse(settings)
    let settingItem = {}
    if(calendar === "primary"){
        settingItem = settings.filter((cal)=>cal.primary)
    }
    else {
        settingItem = settings.filter((cal)=>cal.id === calendar)
    }
    return {
        backgroundColor: settingItem[0].backgroundColor,
        foregroundColor: settingItem[0].foregroundColor
    }
}


export async function checkToken(session) {
    if (!session.provider_token && localStorage.getItem('oauth_provider_refresh_token')) {
        const payload = {
            'grant_type': 'refresh_token',
            'refresh_token': localStorage.getItem('oauth_provider_refresh_token'),
            'client_id': process.env.REACT_APP_GOOGLE_ACCESS_TOKEN,
            'client_secret': process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
        }
        await fetch('https://oauth2.googleapis.com/token', {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'content-type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                session.provider_token = data.access_token
            })
    }
}

export async function updategCalEvent(calId, eventId, eventStart, eventEnd,summary,description, token,options=null) {
    const string = `https://www.googleapis.com/calendar/v3/calendars/${calId}/events/${encodeURIComponent(eventId)}`
    var Start = formatRFC3339(eventStart)
    var End = formatRFC3339(eventEnd)
    var body = {
        start: {
            "dateTime": Start
        },
        end: {
            "dateTime": End
        },
        summary: summary,
        description:description,
    }
    if(options !== null){
        body.extendedProperties.private.taskzId = options.taskzId
        body.extendedProperties.private.lastSync = options.lastSync
    }
    return await fetch(string, {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(body)
    }).then((data) => {
        return data.json()

    }).then((data) => {

        const event = {
            id: data.id,
            summary: data.summary,
            description: data.description,
            from: data.start.dateTime,
            to: data.end.dateTime,
            color: (data.color ? data.color : ''),
        }
        return event
    })
}
export async function deletegCalEvent(calId, eventId, token){
    const string = `https://www.googleapis.com/calendar/v3/calendars/${calId}/events/${encodeURIComponent(eventId)}`
    return await fetch(string, {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((data) => {
        return data
    })
}
export async function insertgCalEvent(calId,event,token){
    const string= `https://www.googleapis.com/calendar/v3/calendars/${calId}/events`
    return await fetch(string,{
        method:'POST',
        headers: {
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(event)
    }).then((data) => {
        return data.json()

    }).then((data) => {

        const event = {
            id: data.id,
            summary: data.summary,
            description: data.description,
            from: data.start.dateTime,
            to: data.end.dateTime,
            color: (data.color ? data.color : ''),
            extendedProperties: {
                private: {
                    taskzId: null,
                    lastSync:null,
                }
            }
        }
        if(data.extendedProperties !== undefined){
            event.extendedProperties.private.taskzId = data.extendedProperties.private.taskzId
            event.extendedProperties.private.lastSync = data.extendedProperties.private.lastSync
        }
        return event
    })

}
export async function getgCalEvent(calId,eventId,token){
    const string= `https://www.googleapis.com/calendar/v3/calendars/${calId}/events/${eventId}`
    return await fetch(string, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((data)=>{
        return data.json()
    })
}

export async function getCalendarList(token){
    const string = 'https://www.googleapis.com/calendar/v3/users/me/calendarList'
    return await fetch(string, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((data)=>{
        return data.json()
    })
}
export async function fetchColors(token){
    const string = 'https://www.googleapis.com/calendar/v3/colors'
    return await fetch(string, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((data)=>{
        return data.json()
    })
}

export async function checkGoogleAccess(supabase,session){
    if(!session.provider_token && !localStorage.getItem('oauth_provider_refresh_token')){
        const user = session.user
        var id = user.identities.filter((identity)=> identity.provider === "google")
        if(id.length > 0){
            const googleIdentity = id[0]
            const tokens = await apiGetUserProviderTokens(supabase,"google")
            if(tokens !== {}){
                session.provider_token = tokens.access_token
                localStorage.setItem('oauth_provider_refresh_token',tokens.refresh_token)
                localStorage.setItem('oauth_provider_token',tokens.access_token)
            }

            if(!localStorage.getItem('google_calendar_colors')){
                await checkToken(session)
                var colors = await fetchColors(session.provider_token)
                localStorage.setItem('google_calendar_colors',JSON.stringify(colors))
            }
        }
    }
}

export async function apiGetUserProviderTokens(supabase,provider) {
    const id = await getUserID(supabase)
    if (!id) return {}
    const {data: tokens, error} = await supabase.from('Calendar_integration').select('*').eq('user', id).eq('provider', provider)
    if(!error){
        if(tokens.length === 0){
            return {}
        }
        return tokens[0]
    }
    return {
        // TODO: error handling
    }
}

export async function apiSetUserProviderToken(supabase,provider,token,refreshToken){
    const id = await getUserID(supabase)
    if (!id) return {}
    const {err} =  await supabase.from('Calendar_integration').delete().eq('user',id).eq('provider',provider)
    const {data,error} = await supabase.from('Calendar_integration').insert({user:id,provider:provider,access_token:token,refresh_token:refreshToken}).select()
    if(!error){
        return true
    }
    return {
        // TODO: error handling
    }
}
