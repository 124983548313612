import {getUserID} from "../ApiConfig";


export async function apiAddScroll(supabase,scroll){
    const id = await getUserID(supabase)
    if (!id) return {}

    scroll.user = id
    const {data:s, error} = await supabase.from('Inventory_scrolls').insert(scroll).select()
    if(!error){
        return s
    }
    return {}
}

export async function apiGetScrolls(supabase){

    const {data:s, error} = await supabase.from('Inventory_scrolls').select()
    if(!error){
        return s
    }
    return []
}

export async function apiDeleteScroll(supabase,scrollid){

    const {data:s, error} = await supabase.from('Inventory_scrolls').delete().eq('id',scrollid)
    if(!error){
        return s
    }
    return {}
}

export async function apiGetStackables(supabase){

    const {data:backpack, error} = await supabase.from('Inventory_stackable').select(`item_name,count,item (id,machine_name,type,description,category)`)
    if(!error){
        return backpack
    }
    return []
}

export async function apiUpdateStackable(supabase,stackable,amount){

    const {data:s, error} = await supabase.from('Inventory_stackable').update({count: amount}).eq('item_name',stackable)
    if(!error){
        return s
    }
    return {}
}

export async function apiAddStackable(supabase,stackable){
    const id = await getUserID(supabase)
    if (!id) return {}
    stackable.user = id
    const {data:s, error} = await supabase.from('Inventory_stackable').insert(stackable).select()
    if(!error){
        return s
    }
    return {}
}

export async function apiGetItemID(supabase,id){
    const {data:item, error} = await supabase.from('Items').select('id').eq('machine_name',id)
    if(!error){
        console.log(item)
        return item[0].id
    }
    return {}
}
