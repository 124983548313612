

export default function DailyReviewnotes({reviewnotes,setReviewNotes,saveReview}){

    return(
        <div className="dailyreview-container h-full">
            <div className="flex flex-1 flex-col  h-full">
                <span className="text-lg text-text-bg font-semibod flex justify-center mb-4 ">Review</span>
                <textarea
                    name="reviewnotes"
                    id="reviewnotes"
                    className="h-full resize-none flex-1 block w-full rounded-md border-0 py-1.5 bg-input-bg md:text-base text-dark-grey shadow-sm ring-0 border-transparant focus:ring-0 focus:outline-none sm:text-sm sm:leading-6"
                    defaultValue={reviewnotes}
                    onChange={(e)=>setReviewNotes(e.target.value)}
                />
                    <div className="flex justify-center">
                        <button className="bg-chip-bg text-chip-text m-2 p-2 rounded-lg hover:bg-chip-accent" onClick={()=>saveReview()}>Save review</button>
                    </div>
            </div>



        </div>
    )
}
