import {combineReducers, configureStore } from '@reduxjs/toolkit'
import currentPageReducer from './NavigationSlice'
import settingsReducer from './SettingSlice'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";

const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2
}

const rootReducer = combineReducers({
    currentPage: currentPageReducer,
    settings: settingsReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)
export const store =  configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware ) => getDefaultMiddleware ({
        serializableCheck: false
    })
})


export const persistor = persistStore(store)
