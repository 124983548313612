import {createContext, useEffect, useState} from "react";
import {useSupabaseClient} from "@supabase/auth-helpers-react";

import {toast} from "react-toastify";
import {toastOptions} from "../../../components/Constants";
import {apiAddExp, apiGetCharacter, apiUpdateGemstones, apiUpdateGold} from "../../api/characterApi";
import {calcCurrentLevel} from "../../../components/helpers";


export const LevelContext = createContext();

export const LevelContextProvider = ({ children }) => {
    const [character,setCharacter] = useState([])
    const [currentLevel,setCurrentLevel] = useState(0)
    const supabase = useSupabaseClient()
    const [levelUpdated, setLevelUpdated] = useState(false)


    async function addExp(exp){
        var currentExp = character.experience
        var newExp = currentExp + exp
        var newLevel = calcCurrentLevel(newExp)
        await apiAddExp(supabase,newExp,character.id)
        if(newLevel > currentLevel){
            setCurrentLevel(newLevel)
            toast.success("You've leveled up!",toastOptions)
            await apiUpdateGold(supabase,character.gold + 20,character.id)
            await apiUpdateGemstones(supabase,character.gemstones + 1,character.id)
        }
        setLevelUpdated(!levelUpdated)
    }
    useEffect(()=> {
        async function fetch(){
            const q = await apiGetCharacter(supabase)
            setCharacter(q)
            setCurrentLevel(calcCurrentLevel(q.experience))
        }
        fetch()

    },[levelUpdated])
    return (
        <LevelContext.Provider
            value={{
                character,setCharacter,currentLevel,setCurrentLevel,levelUpdated,setLevelUpdated,addExp
            }}
        >
            {children}
        </LevelContext.Provider>
    );
};
