import Nav from "./Nav";
import Registration_form from "./Registration_form";
import Footer from "./Footer";
import {useSelector} from "react-redux";
import {classNames} from "../../../../helpers";
import {useState} from "react";
import Registration_access from "./Registration_access";

export default function Registration_page() {
    const settings=useSelector(state => state.settings).settings
    var theme = 'dark'
    if(settings.theme_light){theme = 'light'}
    const [code, setCode] = useState('')
  return (
    <div className={classNames("bg-gradient",{theme})}>
      <Nav />
        {code !== process.env.REACT_APP_REGISTRATION_ACCESS_CODE &&
            <Registration_access setCode={setCode}/>
        }
        {code === process.env.REACT_APP_REGISTRATION_ACCESS_CODE &&

            <Registration_form />
        }
      <Footer />
    </div>
  );
}
