import {format} from "date-fns";


export function getCommonRotation(loottable){
    var table = loottable
    var result = []

    for(var i = 0; i < 3; i++) {
        var rolls = getRandom(100)
        var pool = []
        var poolfound = false
        var checkStart = 0

        table.map((poolgroup) => {
            if (rolls <= checkStart + poolgroup.weight && poolfound === false) {
                // this pool
                pool = poolgroup.pool
                poolfound = true
            }
            checkStart += poolgroup.weight
        })
        var poolRoll = {}
        var poolRollFound = false
        var checkStartRoll = 0
        var roll = getRandom(100)
        pool.map((item) => {
            if (roll <= checkStartRoll + item.weight && poolRollFound === false) {
                poolRoll = item
                poolRollFound = true
            }
            checkStartRoll += item.weight
        })
        result.push({quest: poolRoll.quest_id, type: poolRoll.scroll_type})
    }
    return result
}
export function getUncommonRotation(loottable){
    var table = loottable

    var rolls = getRandom(100)
    var pool = []
    var poolfound = false
    var checkStart = 0

    table.map((poolgroup) => {
        if (rolls <= checkStart + poolgroup.weight && poolfound === false) {
            // this pool
            pool = poolgroup.pool
            poolfound = true
        }
        checkStart += poolgroup.weight
    })

    var poolRoll = {}
    var poolRollFound = false
    var checkStartRoll = 0
    var roll = getRandom(100)
    pool.map((item) => {
        if (roll <= checkStartRoll + item.weight && poolRollFound === false) {
            poolRoll = item
            poolRollFound = true
        }
        checkStartRoll += item.weight
    })

    return {quest: poolRoll.quest_id, type: poolRoll.scroll_type}
}

export function getRandom(max){
    return Math.floor(Math.random() * max)
}
export function getRandomBetween(min,max){
    const minCeiled = Math.ceil(min);
    const maxFloored = Math.floor(max);
    return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled);
}


export function getQuestIds(common,uncommon){
    var checker = common.concat(uncommon)
    var result = []

    checker.map((item)=>{
        if(!result.includes(item.quest)){
            result.push(item.quest)
        }
    })
    return result
}

export function createRotation(common,uncommon,quests){
    var result = {}

    common.map((item,index)=>{
        var quest = quests.find((quest)=> quest.id === item.quest)
        var details = quest.details[0]
        var target = details.quest_target_name[0].name
        var min = details.quest_target.min
        var max = details.quest_target.max
        var amount = getRandomBetween(min,max)

        if(index === 0){
            result.scroll_1_type = details.quest_type
            result.scroll_1_quest = item.quest
            result.scroll_1_target = target
            result.scroll_1_amount = amount
            result.scroll_1_rarity = item.type
        }
        if(index === 1){
            result.scroll_2_type = details.quest_type
            result.scroll_2_quest = item.quest
            result.scroll_2_target = target
            result.scroll_2_amount = amount
            result.scroll_2_rarity = item.type
        }
        if(index === 2){
            result.scroll_3_type = details.quest_type
            result.scroll_3_quest = item.quest
            result.scroll_3_target = target
            result.scroll_3_amount = amount
            result.scroll_3_rarity = item.type
        }
    })
    var q = quests.find((quest)=> quest.id === uncommon.quest)
    var details = q.details[0]
    var target = details.quest_target_name[0].name
    var min = details.quest_target.min
    var max = details.quest_target.max
    var amount = getRandomBetween(min,max)

    result.scroll_4_type = details.quest_type
    result.scroll_4_quest = uncommon.quest
    result.scroll_4_target = target
    result.scroll_4_amount = amount
    result.scroll_4_rarity = uncommon.type

    var date = new Date()
    result.rolled_date = format(date,'yyyy-MM-dd')
    result.rolled_time = format(date,'HH:mm:ss')

    return result
}

