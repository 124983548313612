import {useState} from "react";
import Layout from "../../Layout";
import Content from "../../Content";
import {classNames} from "../../../helpers";
import TownView from "./TownView";
import Guild from "./Guild/Guild";
import {useSearchParams} from "react-router-dom";
import Forest from "./Forest/Forest";


const tabs = [
    { name: "Town", ref: "0" },
    { name: "Guild hall", ref: "1" },
    { name: "Forest", ref: "2" },
    //{ name: "Market", ref: "3" },
    //{ name: "Smith", ref: "4" },
    //{ name: "General store", ref: "5" },
    //{ name: "Mage tower", ref: "6" },
];

export default function Town() {
    const [searchParams,setSearchParams] = useSearchParams()
    const [activeTab, setActiveTab] = useState(getDefaultTab());

    function getDefaultTab(){
        if(searchParams.has('guild')){
            return "1"
        }
        else if(searchParams.has('forest')){
            return "2"
        }
        return "0"
    }
    return (
        <Layout>
            <Content>
                <div className="w-full h-full flex flex-1 flex-col">
                    <div className="sm:hidden ">
                        <label htmlFor="tabs" className="sr-only">
                            Select a tab
                        </label>
                        <select
                            id="tabs"
                            name="tabs"
                            className="block w-full rounded-md border-text-bg-inverse py-2 pl-3 pr-10 text-base focus:border-chip-bg focus:outline-none focus:ring-chip-bg sm:text-sm"
                            defaultValue={tabs.find((tab) => tab.ref === activeTab)}
                            onChange={(tab) => setActiveTab(tab.target.value)}
                        >
                            {tabs.map((tab) => (
                                <option value={tab.ref}>{tab.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="hidden sm:block">
                        <div className="border-b border-text-bg-inverse">
                            <nav className="ml-32 -mb-px flex space-x-8" aria-label="Tabs">
                                {tabs.map((tab) => (
                                    <button
                                        key={tab.name}
                                        className={classNames(
                                            tab.ref === activeTab
                                                ? "border-chip-bg text-chip-bg font-semibold"
                                                : "border-transparent text-text-bg hover:border-chip-accent hover:text-chip-accent",
                                            "flex whitespace-nowrap border-b-2 py-4 text-sm font-medium",
                                        )}
                                        onClick={() => setActiveTab(tab.ref)}
                                        aria-current={tab.ref === activeTab ? "page" : undefined}
                                    >
                                        {tab.name}
                                    </button>
                                ))}
                            </nav>
                        </div>
                    </div>
                    <div className="flex flex-1">
                        {activeTab === "0" && <TownView />}
                        {activeTab === "1" && <Guild />}
                        {activeTab === "2" && <Forest />}
                    </div>

                </div>
            </Content>
        </Layout>
    );
}
