import {
  LuAlignVerticalJustifyEnd, LuArchive,
  LuBackpack, LuBug,
  LuCalendarDays,
  LuCat, LuClock,
  LuGlobe,
  LuLayoutDashboard, LuScroll,
  LuSquareStack,
  LuStore,
  LuSwords,
  LuUser2,
} from "react-icons/lu";
import { addDays, format, subDays } from "date-fns";
import {
  CreditCardIcon,
  CubeIcon,
  UserCircleIcon,
} from "@heroicons/react/16/solid";
import {Bounce} from "react-toastify";

/*
Navigation Constants
 */
export const navigation = [
  { name: "Plan", href: "/plan", current: 'plan' },
  { name: "Calendar", href: "/calendar", current: 'calendar' },
  { name: "Taskz", href: "/tasks", current: 'tasks' },
];
export const userNavigation = [
  { name: "Settings", href: "/settings", signout:false },
  { name: "Plan your day", href: "/planday", signout:false},
  { name: "Review your day", href: "/reviewday", signout:false},
  { name: "Sign out", href: "#", signout: true },
];
export const mobile_navigation = [
  { name: "Dashboard", href: "/plan", icon: LuLayoutDashboard, current: 'plan' },
  { name: "Calendar", href: "/calendar", icon: LuCalendarDays, current: 'calendar' },
  { name: "Tasks", href: "/tasks", icon: LuAlignVerticalJustifyEnd, current: 'tasks',},
  { name: "Quests", href: "/quests", icon: LuScroll, current: 'quests' },
  { name: "Combat", href: "/combat", icon: LuSwords, current: 'combat' },
  { name: "Town", href: "/town", icon: LuStore, current: 'town' },
  { name: "Backpack", href: "/inventory", icon: LuBackpack, current: 'backpack' },
  { name: "Bugs", href: "/bugs", icon: LuBug, current: 'bugs' },
  { name: "Companions", href: "/companions", icon: LuCat, current: 'companions' },
  //{ name: "Character", href: "#", icon: LuUser2, current: 'character' },
];
export const right_sidebar_navigation = [
  {
    name: "Calendar_google",
    href: "calender",
    icon: LuCalendarDays,
    current: true,
  },
  {
    name: "Backlog",
    href: "backlog",
    icon: LuAlignVerticalJustifyEnd,
    current: false,
  },
  { name: "Combat", href: "combat", icon: LuSwords, current: false },
];
export const left_sidebar_navigation = [
  { name: "Quests", href: "/quests", icon: LuScroll, current: 'quests' },
  { name: "Town", href: "/town", icon: LuStore, current: 'town' },
  { name: "Backpack", href: "/inventory", icon: LuBackpack, current: 'backpack' },
  { name: "Companions", href: "/companions", icon: LuCat, current: 'companions' },
  { name: "Bugs", href: "/bugs", icon: LuBug, current: 'bugs' },
  //{ name: "Character", href: "#", icon: LuUser2, current: 'character' },
];

export const toastOptions = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
  transition: Bounce,
}
export const settingsNavigation = [
  { name: "General", href: "#", icon: CubeIcon, current: true },
  { name: "Planning", href: "#", icon: LuCalendarDays, current: false },
  { name: "Timeboxing", href: "#", icon: LuClock , current: false },
  { name: "Account", href: "#", icon: UserCircleIcon, current: false },
  { name: "Calendar settings", href: "#", icon: LuCalendarDays, current: false },
  { name: "Categories", href: "#", icon: LuSquareStack, current: false },
  { name: "Workspaces", href: "#", icon: LuArchive, current: false },
  { name: "Billing", href: "#", icon: CreditCardIcon, current: false },
];

export const settings_dummylist = [
  {
    id: 1,
    category: 'General',
    name:'Time zone',
    machine_name:'timezone',
    description: 'Choose your timezone',
    display: 'dropdown',
    enabled: true,
  },
  {
    id: 2,
    category: 'General',
    name:'Time format',
    machine_name: 'timeformat',
    description: 'Choose the format to display time in',
    display: 'dropdown',
    displayOptions:[
      {
        label: '0-23',
        value: 'HH'
      },
      {
        label: 'AM, PM',
        value: 'a..aa'
      }
    ],
    enabled: true,
  },
  {
    id: 3,
    category: 'General',
    name:'Start of week',
    description: 'Choose the start of the week',
    machine_name: 'start_of_week_monday',
    display: 'dropdown',
    displayOptions:[
      {
        label: 'Monday',
        value: true
      },
      {
        label: 'Sunday',
        value: false
      }
    ],
    enabled: true,
  },
  {
    id: 4,
    category: 'General',
    name:'Estimated time as actual',
    description: 'Can the estimated time be counted as actual time when the task is completed without specifying the actual time?',
    machine_name: 'estimated_actual',
    enabled: false,
    display: 'toggle',
  },
  {
    id: 5,
    category: 'General',
    name:'Task rollover position',
    description: 'Choose the position rollover tasks should be displayed at',
    display: 'dropdown',
    displayOptions:[
      {
        label: 'Top',
        value: true
      },
      {
        label: 'Bottom',
        value: false
      }
    ],
    machine_name: 'rollover_position_top',
    enabled: false,
  },
  {
    id: 6,
    category: 'General',
    name:'Rollover limit',
    description: 'How many times should a task roll over before it is moved to the backlog',
    display: 'dropdown',
    displayOptions:[
      {
        label: '1',
        value: 1,
      },
      {
        label: '2',
        value: 2,
      },
      {
        label: '3',
        value: 3,
      },
      {
        value: -1,
        label: 'No limit'
      },
      {
        value: null,
        label: 'Never'
      }
    ],
    machine_name: 'rollover_limit',
    enabled: true,
  },
  {
    id: 7,
    category: 'General',
    name:'Workload threshold',
    description: 'What is your workload threshold?',
    display: 'dropdown',
    displayOptions:[
      {
        label: '1hr',
        value: 1,
      },
      {
        label: '2hrs',
        value: 2,
      },
      {
        label: '3hrs',
        value: 3,
      },
      {
        label: '4hrs',
        value: 4,
      },
      {
        label: '5hrs',
        value: 5,
      },
      {
        label: '6hrs',
        value: 6,
      },
      {
        label: '7hrs',
        value: 7,
      },
      {
        label: '8hrs',
        value: 8,
      },
      {
        label: '9hrs',
        value: 9,
      },
      {
        label: '10hrs',
        value: 10,
      },
    ],
    machine_name: 'workload_threshold',
    enabled: false,
  },
  {
    id: 8,
    category: 'Calendar settings',
    name:'Calendar event color',
    description: 'Choose the color in which tasks should be displayed on the calendar',
    display: 'dropdown',
    displayOptions:[
      {
        label: 'Task category color',
        value: true,
      },
      {
        label: 'Calendar default',
        value: false,
      },
    ],
    machine_name: 'event_color_app',
    enabled: true,
  },
  {
    id: 9,
    category: 'General',
    name:'Hide completed tasks in list',
    description: 'Hide the completed tasks in the overviews?',
    machine_name: 'hide_completed_list',
    display: "toggle",
    enabled: false,
  },
  {
    id: 10,
    category: 'General',
    name:'Hide completed tasks in calendar',
    description: 'Hide completed tasks in the calendar?',
    machine_name: 'hide_completed_calendar',
    display: "toggle",
    enabled: false,
  },
  {
    id: 11,
    category: 'General',
    name:'Theme',
    description: 'What theme should the application use',
    display: 'dropdown',
    displayOptions:[
      {
        label: 'Light Theme',
        value: true
      },
      {
        label: 'Dark Theme',
        value: false
      }

    ],
    machine_name: 'theme_light',
    enabled: true,
  },
  {
    id:12,
    category: 'General',
    name:'Game overlay',
    machine_name: 'game_overlay',
    description: 'Should some aspects of the UI be replaced with game related art?',
    display: 'toggle',
    enabled:false,
  },
  {
    id: 13,
    category: 'General',
    name:'Gamification style',
    machine_name: 'gamification_game',
    description: 'Do you want to enable the full gamification features, or want a more neutral experience?',
    display: 'toggle',
    enabled:false

  },
  {
    id: 14,
    category: 'Planning',
    name:'Daily planning session',
    description: 'Do you want to start the daily planning session automatically?',
    machine_name: 'enable_daily_plan',
    display: 'toggle',
    enabled: true,
  },
  {
    id: 15,
    category: 'Planning',
    name: 'Daily planning session date',
    description: 'When do you want the daily planning session to happen?',
    machine_name: 'daily_plan_evening',
    display: 'dropdown',
    displayOptions:[
      {
        label: 'In the morning',
        value: false
      },
      {
        label: 'The previous evening',
        value: true
      }
    ],
    enabled: true,

  },
  {
    id: 16,
    category: 'Planning',
    name:'Daily planning session time',
    description: 'When do you want the daily planning session to start?',
    machine_name: 'daily_plan_time',
    display: 'dropdown',
    displayOptions:[
      {
        label: true?'6:00':'6AM',
        value: '6:00'
      },
      {
        label: true?'6:15':'6:15AM',
        value: '6:15'
      },
      {
        label: true?'6:30':'6:30AM',
        value: '6:30'
      },
      {
        label: true?'6:45':'6:45AM',
        value: '6:45'
      },
      {
        label: true?'7:00':'7:00AM',
        value: '7:00'
      },
      {
        label: true?'7:15':'7:15AM',
        value: '7:15'
      },
      {
        label: true?'7:30':'7:30AM',
        value: '7:30'
      },
      {
        label: true?'7:45':'7:45AM',
        value: '7:45'
      },
      {
        label: true?'8:00':'8:00AM',
        value: '8:00'
      },
      {
        label: true?'8:15':'8:15AM',
        value: '8:15'
      },
      {
        label: true?'8:30':'8:30AM',
        value: '8:30'
      },
      {
        label: true?'8:45':'8:45AM',
        value: '8:45'
      },
      {
        label: true?'9:00':'9:00AM',
        value: '9:00'
      },
      {
        label: true?'9:15':'9:15AM',
        value: '9:15'
      },
      {
        label: true?'9:30':'9:30AM',
        value: '9:30'
      },
      {
        label: true?'9:45':'9:45AM',
        value: '9:45'
      },
      {
        label: true?'10:00':'10:00AM',
        value: '10:00'
      },
      {
        label: true?'10:15':'10:15AM',
        value: '10:15'
      },
      {
        label: true?'10:30':'10:30AM',
        value: '10:30'
      },
      {
        label: true?'10:45':'10:45AM',
        value: '10:45'
      },

    ],
    dependsOn: 15,
    dependValue: false,
    enabled: true,
  },
  {
    id: 17,
    category: 'Planning',
    name:'Daily review  session',
    description: 'Do you want to start the daily review  session automatically?',
    machine_name: 'enable_daily_review',
    display: 'toggle',
    enabled: true,
  },
  {
    id: 18,
    category: 'Planning',
    name:'Daily review session time',
    description: 'When do you want the daily review session to start?',
    machine_name: 'daily_review_time',
    display: 'dropdown',
    displayOptions:[
      {
        label: true?'15:00':'3PM',
        value: '15:00'
      },
      {
        label: true?'15:15':'3:15PM',
        value: '15:15'
      },
      {
        label: true?'15:30':'3:30PM',
        value: '15:30'
      },
      {
        label: true?'15:45':'3:45PM',
        value: '15:45'
      },
      {
        label: true?'16:00':'4:00PM',
        value: '16:00'
      },
      {
        label: true?'16:15':'4:15PM',
        value: '16:15'
      },
      {
        label: true?'16:30':'4:30PM',
        value: '16:30'
      },
      {
        label: true?'16:45':'4:45PM',
        value: '16:45'
      },
      {
        label: true?'17:00':'5:00PM',
        value: '17:00'
      },
      {
        label: true?'17:15':'5:15PM',
        value: '17:15'
      },
      {
        label: true?'17:30':'5:30PM',
        value: '17:30'
      },
      {
        label: true?'17:45':'5:45PM',
        value: '17:45'
      },
      {
        label: true?'18:00':'6:00PM',
        value: '18:00'
      },
      {
        label: true?'18:15':'6:15PM',
        value: '18:15'
      },
      {
        label: true?'18:30':'6:30PM',
        value: '18:30'
      },
      {
        label: true?'18:45':'6:45PM',
        value: '18:45'
      },
      {
        label: true?'19:00':'7:00PM',
        value: '19:00'
      },
      {
        label: true?'19:15':'7:15PM',
        value: '19:15'
      },
      {
        label: true?'19:30':'7:30PM',
        value: '19:30'
      },
      {
        label: true?'19:45':'7:45PM',
        value: '19:45'
      },

    ],
    dependsOn: 17,
    dependValue: true,
    enabled: true,
  },
  {
    id: 19,
    category: 'Planning',
    name: 'Weekly planning session',
    description: 'Do you want the weekly planning session to start automatically?',
    machine_name: 'enable_weekly_plan',
    display: 'toggle',
    enabled: false,
  },
  {
    id:20,
    category: 'Planning',
    name: 'Weekly planning session time',
    description: 'When do you want the weekly planning session to start?',
    machine_name: 'weekly_plan_time',
    display: 'dropdown',
    displayOptions: [
      {
        label: 'Before the daily planning on the first day of the week',
        value: 1
      },
      {
        label: 'After the daily review session on the last day of the week',
        value: 0
      }
    ],
    enabled: false
  },
  {
    id:21,
    category: 'Planning',
    name: 'Weekly planning date',
    description: 'When do you want the weekly planning session to start?',
    machine_name: 'weekly_plan_evening',
    display: 'dropdown',
    displayOptions: [
      {
        label: 'In the morning of the first day of the week',
        value: false
      },
      {
        label: 'In the evening of the last day of the week',
        value: true
      }
    ],
    enabled:false,

  },
  {
    id:22,
    category: 'Timeboxing',
    name:'Calendar reminders',
    description: 'Do you want reminders for your timeboxed tasks?',
    display: 'toggle',
    machine_name: 'timebox_reminder',
    enabled: false
  },
  {
    id:23,
    category: 'Timeboxing',
    name: 'Calendar availability',
    description: 'What availability status should be shown for your timeboxed tasks?',
    display: 'dropdown',
    displayOptions: [
      {label: 'Available',value: true},
      {label: 'Busy',value: false}
    ],
    machine_name: 'timebox_availability',
    enabled: false
  },
  {
    id:24,
    category: 'Timeboxing',
    name: 'Default task duration',
    description: 'Default sheduling duration if the task has no estimated time set?',
    machine_name:'timebox_duration',
    display:'dropdown',
    displayOptions: [
      {label: '15 minutes',value: 15},
      {label: '30 minutes', value: 30},
      {label: '45 minutes', value: 45},
      {label: '60 minutes',value: 60},
    ],
    enabled:false
  },
  {
    id:25,
    category:'Timeboxing',
    name:'Auto reshedule by conflict',
    description:'Should tasks be resheduled automatically when they conflict with another event?',
    machine_name: 'timebox_reshedule_conflict',
    display: 'toggle',
    enabled: false
  },
  {
    id:26,
    category:'Timeboxing',
    name:'Auto reshedule when task is completed sooner',
    description:'Should tasks be resheduled automatically when previous tasks are completed sooner?',
    machine_name: 'timebox_reshedule_done',
    display: 'toggle',
    enabled: false
  }

]

export const companion_dummylist = [
  {
    id: 1,
    machine_name: "companion_cat",
    name: "Cat",
    rarity: "common",
    companion_variants:[
      {
        weight: 60,
        rarity: "common",
        name: "tabby",
        machine_name: "tabby",
        description: "Tabby cat",
      },
      {
        weight: 20,
        rarity: "uncommon",
        name: "ginger",
        machine_name: "ginger",
        description: "Ginger cat",
      },
      {
        weight: 12,
        rarity: "rare",
        name: "siamese",
        machine_name: "siamese",
        description: "Siamese cat",
      },
      {
        weight: 6,
        rarity: "epic",
        name: "white",
        machine_name: "white",
        description: "White cat",
      },
      {
        weight: 2,
        rarity: "legendary",
        name: "black",
        machine_name: "black",
        description: "Black cat",
      },
    ],
    hatchtime: {
      type: "hour",
      amount: 8
    }
  },
  {
    id: 2,
    machine_name: "companion_dog",
    name: "Dog",
    rarity: "common",
    companion_variants:[
      {
        weight: 100, // 60
        rarity: "common",
        name: "labrador",
        machine_name: "labrador",
        description: "Labrador",
      },
      {
        weight: 20,
        rarity: "uncommon",
        name: "bulldog",
        machine_name: "bulldog",
        description: "Bulldog",
      },
      {
        weight: 12,
        rarity: "rare",
        name: "dalmatian",
        machine_name: "dalmatian",
        description: "Dalmatian",
      },
      {
        weight: 6,
        rarity: "epic",
        name: "poodle",
        machine_name: "poodle",
        description: "Poodle",
      },
      {
        weight: 2,
        rarity: "legendary",
        name: "husky",
        machine_name: "husky",
        description: "Husky",
      },
    ],
    hatchtime: {
      type: "hour",
      amount: 24
    }
  },
  {
    id: 3,
    machine_name: "companion_phoenix",
    name: "Phoenix",
    rarity: "epic",
    companion_variants:[
      {
        weight: 100, // 60
        rarity: "common",
        name: "golden",
        machine_name: "gold",
        description: "Golden phoenix",
      },
      {
        weight: 20,
        rarity: "uncommon",
        name: "red",
        machine_name: "red",
        description: "Red phoenix",
      },
      {
        weight: 12,
        rarity: "rare",
        name: "white",
        machine_name: "white",
        description: "White phoenix",
      },
      {
        weight: 6,
        rarity: "epic",
        name: "blue",
        machine_name: "blue",
        description: "Blue phoenix",
      },
      {
        weight: 2,
        rarity: "legendary",
        name: "black",
        machine_name: "black",
        description: "Black phoenix",
      },
    ],
    hatchtime: {
      type: "day",
      amount: 2
    }
  },
  {
    id: 4,
    machine_name: "companion_dragon",
    name: "Dragon",
    rarity: "legendary",
    companion_variants:[
      {
        weight: 100, // 60
        rarity: "common",
        name: "green",
        machine_name: "green",
        description: "Green dragon",
      },
      {
        weight: 20,
        rarity: "uncommon",
        name: "red",
        machine_name: "red",
        description: "Red dragon",
      },
      {
        weight: 12,
        rarity: "rare",
        name: "blue",
        machine_name: "blue",
        description: "Blue dragon",
      },
      {
        weight: 6,
        rarity: "epic",
        name: "gold",
        machine_name: "gold",
        description: "Golden dragon",
      },
      {
        weight: 2,
        rarity: "legendary",
        name: "black",
        machine_name: "black",
        description: "Black dragon",
      },
    ],
    hatchtime: {
      type: "day",
      amount: 5
    }
  }
]


export const potion_dummylist = [
  {
    id:1,
    machine_name: "potion_health",
    tier: 1,
    name: "Health potion",
    description: "A potion that restores health",
    effect: {
      stat: "health",
      bonus: {
        min: 1,
        max: 2,
      },
      duration: {
        type: "minute",
        min: 5,
        max: 10,
      }
    }
  }
]
export const gear_dummylist = [
  {
    id: 1,
    category: "helmet",
    name: "Basic helmet",
    description: "A basic helmet",
    rarity: "common",
    bonus_stat_range: {
      stat: "health",
      min:1,
      max:1,
    }
  },
  {
    id: 2,
    category: "chestplate",
    name: "Basic chestplate",
    description: "A basic chestplate",
    rarity: "common",
    bonus_stat_range: {
      stat: "health",
      min:1,
      max:1,
    }
  },
  {
    id: 3,
    category: "leggings",
    name: "Basic leggings",
    description: "Basic leggings",
    rarity: "common",
    bonus_stat_range: {
      stat: "health",
      min:1,
      max:1,
    }
  },
  {
    id: 4,
    category: "boots",
    name: "Basic boots",
    description: "Basic boots",
    rarity: "common",
    bonus_stat_range: {
      stat: "health",
      min:1,
      max:1,
    }
  }
]
export const gear_roll_type_pools = [
  {
    id: 1,
    type: "gear:helmet",
    name: "common",
    pool: [
      {
        weight: 100,
        gear_id:1,
      }
    ]
  },
  {
    id: 2,
    type: "gear:chestplate",
    name: "common",
    pool: [
      {
        weight: 100,
        gear_id:2,
      }
    ]
  },
  {
    id: 3,
    type: "gear:leggings",
    name: "common",
    pool: [
      {
        weight: 100,
        gear_id:3,
      }
    ]
  },
  {
    id: 4,
    type: "gear:boots",
    name: "common",
    pool: [
      {
        weight: 100,
        gear_id:4,
      }
    ]

  }
]

export const mob_dummylist =  [
    {
        mob_name: 'mutated rat',
        machine_name: 'mutated_rat',
    },
    {
      mob_name: 'mutated pigeon',
      machine_name: 'mutated_pigeon',
    },
    {
      mob_name: 'mutated chicken',
      machine_name: 'mutated_chicken',
    },
    {
      mob_name: 'mutated fox',
      machine_name: 'mutated_fox',
    },
    {
      mob_name: 'mutated dragonling',
      machine_name: 'mutated_dragonling',
    },
    {
      mob_name: 'mutated lion',
      machine_name: 'mutated_lion',
    }
]
