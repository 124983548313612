import { createSlice } from '@reduxjs/toolkit'
import {apiUpdateUserSetting} from "./api/Settings/SettingsApi";

const initialState = {
    settings: [],
    lastFetch: null
};
export const SettingSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        updateSetting: (state, action) => {
           state.settings[action.payload.setting_name] = action.payload.setting_value
           var setting = {}
            setting[action.payload.setting_name] = action.payload.setting_value
            apiUpdateUserSetting(action.payload.supabase,setting)
        },
        initSettings:(state,action)=>{
            var list = action.payload
            state.settings = list
            state.lastFetch = new Date()
        }
    }
})

export const {updateSetting,initSettings} = SettingSlice.actions;

export default SettingSlice.reducer



