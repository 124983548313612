import {
  LuHeart,
  LuDumbbell,
  LuBrain,
  LuBookOpen,
  LuClover,
} from "react-icons/lu";
import { MdDirectionsRun } from "react-icons/md";
import Estimated_time_fragment from "../../../../../fragments/Estimated_time_fragment";
import React, {useContext, useEffect, useState} from "react";
import {
  calcCurrentLevel,
  calcExpCurrent,
  calcExpNeeded,
  calcExpNeededCurrent,
  getMobname
} from "../../../../../helpers";
import {
  calcBattleTimeRemaining,
  getCurrentPercentage,
  getPercentageRemaining
} from "../../../../../../App/utils/CombatUtils";
import Combat_time_fragment from "../../../../../fragments/Combat_time_fragment";
import {CombatContext} from "../../../../../../App/utils/Contexts/CombatContext";
import {QuestContext} from "../../../../../../App/utils/Contexts/QuestContext";
import QuestDetail from "../../../Main_page/Quests/QuestDetail";
import {LevelContext} from "../../../../../../App/utils/Contexts/LevelContext";

export default function SidebarDashboard() {
  const {combat,hasActiveCombat} = useContext(CombatContext)
  const {character,setCharacter} = useContext(LevelContext)
  const {quests,setQuests} = useContext(QuestContext)
  const [timeRemaining, setTimeRemaining] = useState(hasActiveCombat?calcBattleTimeRemaining(combat,character.dexterity):null)
  const [questModalOpen,setQuestModalOpen] = useState(false);
  const [modalQuest,setModalQuest] = useState({});


  const closeQuestModal = ()=>{
    setQuestModalOpen(false)
    setModalQuest({})
  }
  const openQuestModal = (quest) =>{
    setModalQuest(quest);
    setQuestModalOpen(true)
  }

  const interval = setInterval(() => {
    setTimeRemaining(calcBattleTimeRemaining(combat,character.dexterity));
  }, 1000);

  useEffect(()=> {
    if(hasActiveCombat){
      setTimeRemaining(calcBattleTimeRemaining(combat,character.dexterity))
    }
  },[])
  return (
    <div className="text-text-bg">
      <div className="user-info">
        <div className="userAvatar ">
          <div className="flex flex-col items-center pb-1">
            <div className="username h-16 text-center items-center">
              <span className="text-text-bg  font-semibold">

              </span>
            </div>
            <img
              className=" h-44 mb-3 rounded-full"
              src={"assets/game/characters/avatar_placeholder.png"}
              alt="Avatar"
            />
            <span className="text-sm text-text-bg/60 ">
              Wizard
            </span>
          </div>
          <div className="flex flex-col items-center pb-5">
            <div className="text-m text-text-bg ">
              Level {calcCurrentLevel(character.experience)}
            </div>
            <div className="w-full h-4 mb-4 bg-text-bg-inverse rounded-full ">
              <div
                className="h-4 bg-chip-bg rounded-full  text-xs text-chip-text text-center "
                style={{
                  marginRight:
                    getPercentageRemaining(
                      getCurrentPercentage(calcExpCurrent(character.experience), calcExpNeededCurrent(character.experience)),
                    ) + "%",
                }}
              >
              </div>
              <span className="text-sm">{calcExpCurrent(character.experience)} / {calcExpNeeded(calcCurrentLevel(character.experience)+1)}</span>
            </div>
          </div>
        </div>
        <div className="userStats">
          <div className="flex flex-col items-center pb-1 ">
            <div className="grid gap-x-1 grid-cols-2 w-44 text-gray-700">
              <div className="stat_health flex  items-center justify-center my-1">
                <LuHeart
                  className="w-6 text-text-bg hover:text-chip-accent flex pr-2"
                  aria-hidden="true"
                />
                <span className="flex text-text-bg font-semibold">{character.constitution}</span>
              </div>
              <div className="stat_wisdom flex  items-center justify-center my-1 ">
                <LuBrain
                  className="w-6 text-text-bg hover:text-chip-accent  pr-2"
                  aria-hidden="true"
                />
                <span className="text-text-bg">{character.intelligence}</span>
              </div>
              <div className="stat_strength flex  items-center justify-center  my-1">
                <LuDumbbell
                  className="w-6 text-text-bg hover:text-chip-accent flex  pr-2"
                  aria-hidden="true"
                />
                <span className="flex text-text-bg">{character.strength}</span>
              </div>
              <div className="stat_knowledge flex items-center justify-center my-1 ">
                <LuBookOpen
                  className="w-6 text-text-bg hover:text-chip-accent  pr-2"
                  aria-hidden="true"
                />
                <span className="text-text-bg">{character.wisdom}</span>
              </div>
              <div className="stat_dexterity flex  items-center justify-center my-1">
                <MdDirectionsRun
                  className="w-6 text-text-bg hover:text-chip-accent  pr-2"
                  aria-hidden="true"
                />
                <span className="text-text-bg">{character.dexterity}</span>
              </div>
              <div className="stat_charisma flex  items-center justify-center my-1 ">
                <LuClover
                  className="w-6 text-text-bg hover:text-chip-accent pr-2"
                  aria-hidden="true"
                />
                <span className="text-text-bg">{character.charisma}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="game-info">
        {/*<div className="active-potions">
          <div className="flex flex-col justify-start text-text-bg bg-card-bg px-1.5 rounded-2xl shadow-md ring-1 ring-inset ring-card-ring overflow-x-hidden my-5 p-2 min-h-20">
            <div className="flex-1 grid grid-cols-5 px-1">
              <div className="row-span-2 flex items-center">
                <img src={"assets/game/items/potion_health.png"} alt="mob" className="pr-2 w-10 h-10"/>
              </div>
              <div className="col-span-3">
                <span className="text-text-bg text-s font-semibold">
                  Greater potion of Health
                </span>
              </div>
              <div className="row-span-2 flex items-center">
                <Estimated_time_fragment key="estimated_time_battle" time_estimate="12:38" actual_set={false}/>
              </div>
              <div className="col-span-3 text-text-bg flex justify-start items-center">
                <LuHeart
                  className="w-7 h-7 text-text-bg flex items-center pr-2"
                  aria-hidden="true"
                />
                <span className="text-text-bg text-m flex items-center font-semibold">
                  +2
                </span>
              </div>
            </div>
          </div>
        </div> */}
        <div className="active-battle">
          {hasActiveCombat &&
          <div className="flex flex-col justify-start text-text-bg bg-card-bg px-1.5 rounded-2xl shadow-md ring-1 ring-inset ring-card-ring overflow-x-hidden my-5 p-2 min-h-20">
            <div className="flex-1 grid grid-cols-5 px-1">
              <div className="row-span-2 flex items-center">
                <img src={"assets/game/mobs/"+combat.mob_name+".png"} alt="mob" className="pr-2 w-10 h-10"/>
              </div>
              <div className="col-span-3 ">
                <span className="text-text-bg text-s font-semibold">
                  {getMobname(combat.mob_name)}
                </span>
              </div>
              <div className="row-span-2 flex items-center">
                <Combat_time_fragment key="estimated_time_battle" time={timeRemaining}/>
              </div>
              <div className="col-span-3  flex justify-start items-center">
                <div className="enemyStats flex">
                  <div className="min-w-24 h-4 bg-sidebar-bg rounded-full ">
                    <div
                        className="h-4 bg-color-success rounded-full text-xs font-semibold text-chip-text text-center "
                        style={{
                          marginRight:
                              getPercentageRemaining(
                                  getCurrentPercentage(combat.current_health, combat.mob.health),
                              ) + "%",
                        }}
                    >
                      {combat.current_health+ "/" + combat.mob.health}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          }
        </div>
        <div className="saved-quests">
          {quests.filter((q)=>!q.rewards_collected).map(quest => (
              <div onClick={()=>openQuestModal(quest)}
                  className="cursor-pointer flex flex-col justify-start text-text-bg bg-card-bg px-1.5 rounded-2xl shadow-md ring-1 ring-inset ring-card-ring overflow-x-hidden my-5 p-2 min-h-20">
                <div className="flex-1 grid grid-cols-4 px-1">
                  <div className="row-span-2 flex items-center">
                    <img src={"assets/game/mobs/"+quest.quest_target+".png"} alt="mob" className="pr-2  h-10"/>
                  </div>
                  <div className="col-span-3 ">
                <span className="text-text-bg text-s font-semibold">
                  {quest.quest_type + " "+quest.quest_target_amount+" "+getMobname(quest.quest_target)}
                </span>
                  </div>
                  <div className="col-span-3  flex justify-start items-center">
                <span className="text-text-bg text-m flex items-center font-semibold">
                 {quest.progress} / {quest.quest_target_amount}
                </span>
                  </div>
                </div>
              </div>
          ))}
        </div>
      </div>
      {questModalOpen && <QuestDetail quest={modalQuest} closeQuestModal={closeQuestModal} />}
    </div>
  );
}
