import { Fragment, useContext, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon } from "@heroicons/react/24/outline";
import { LuArrowLeftToLine } from "react-icons/lu";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  Selected_day_context,
  SidebarContext,
} from "../../../Contexts";
import {
  mobile_navigation,
  navigation,
  userNavigation,
} from "../../../Constants";
import { joinClasses, classNames } from "../../../helpers";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentPage} from "../../../../App/NavigationSlice";
import {supabase} from "../../../../App/lib/supabase.ts";
import {LevelContext} from "../../../../App/utils/Contexts/LevelContext";
import {RiCoinLine, RiVipDiamondLine} from "react-icons/ri";
import {NotificationContext} from "../../../../App/utils/Contexts/NotificationContext";

export default function Main_nav() {
  const [mobileNav, setMobilenavOpen] = useState(false);
  const { sidebarRight,sidebarLeft } = useContext(SidebarContext)
  const { setSelectedDay } = useContext(Selected_day_context);
  const currentPage = useSelector(state => state.currentPage.value)
  const dispatch = useDispatch()
  const {character} = useContext(LevelContext)
  const {notifications} = useContext(NotificationContext)
  const unreadApp = notifications.filter((notification) => notification.type !== 'game' && notification.archived ===false && notification.status === false).length
  const unreadGame = notifications.filter((notification) => notification.type === 'game' && notification.archived ===false && notification.status===false).length
  async function logout() {
    await supabase.auth.signOut({scope: 'local'})
  }
  return (
    <>
      <Transition.Root show={mobileNav} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={()=>setMobilenavOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-sidebar-nav-bg/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      id="toggleLeftSidebarButton"
                      className="-m-2.5 p-2.5"
                      onClick={() => setMobilenavOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>

                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-sidebar-nav-bg px-6 pb-2 ring-1 ring-white/10">
                  <div className="flex h-16 shrink-0 items-center">
                    <img
                        className="h-8 w-auto"
                        src="Icon_32x32.png"
                        alt="Taskz"
                    />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="-mx-2 flex-1 space-y-1">
                      {mobile_navigation.map((item) => (
                        <li key={item.name}>
                          <a
                            href={item.href}
                            onClick={()=>dispatch(setCurrentPage(item.current))}
                            className={classNames(
                              item.current === currentPage
                                ? "text-chip"
                                : "text-text-bg hover:text-chip-bg",
                              "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold",
                            )}
                          >
                            <item.icon
                              className="h-6 w-6 shrink-0"
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Separator */}
      <div
        className={joinClasses(
          classNames(
            (sidebarLeft !== null ? sidebarLeft : true) ? "lg:ml-80" : "",
          ),
          classNames(
            (sidebarRight !== null ? sidebarRight : true) ? "lg:mr-96" : "",
          ),
          "lg:pl-20 lg:pr-20 bg-nav-bg",
        )}
      >
        <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-text-bg-inverse px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8 xl:max-2xl:px-4">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-text-bg lg:hidden"
            onClick={() => setMobilenavOpen(true)}
          >
            <span className="sr-only">Open menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div
            className="h-6 w-px bg-text-bg/10 lg:hidden"
            aria-hidden="true"
          />
          <div className="hidden fgap-x-4 self-stretch lg:gap-x-4 lg:max-2xl:gap-x-2 ml-2 lg:block">
            <button onClick={() => setSelectedDay(new Date())} className="">
              <LuArrowLeftToLine
                className="cursor-pointer absolute inset-y-0 left-0 h-full w-6 text-text-bg hover:text-chip-accent lg:mx-4 xl:max-2xl:mx-2"
                aria-hidden="true"
              />
            </button>
          </div>
          <div className="flex flex-1 gap-x-4 items-center self-stretch lg:gap-x-6 pl-8 lg:mx-8 xl:max-2xl:gap-x-4 xl:max-2xl:pl-2 xl:max-2xl:mx-2">
            <div className="hidden lg:flex lg:gap-x-12 xl:max-2xl:gap-x-4">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  onClick={()=>dispatch(setCurrentPage(item.current))}
                  className={classNames(item.current === currentPage? 'text-chip-bg ':'text-text-bg', "font-semibold text-base leading-8")}
                >
                  {item.name}
                </a>
              ))}
            </div>
          </div>
          <div className="flex items-center gap-x-4 lg:gap-x-6 lg:max-2xl:gap-x-4 right-0 self-stretch">
            <span className="-m-2.5 p-2.5 text-icon-faded hover:text-chip-accent flex"
            >

              <RiCoinLine  className="h-6 w-6 mr-1" aria-hidden="true" />
              <span className="">{character.gold}</span>
            </span>

            <span
                className="-m-2.5 p-2.5 text-icon-faded hover:text-chip-accent flex"
            >

              <RiVipDiamondLine  className="h-6 w-6 mr-1" aria-hidden="true" />
              <span className="">{character.gemstone}</span>
            </span>


            <a
              href="/messages"
              className="-m-2.5 p-2.5 text-icon-faded hover:text-chip-accent flex"
            >
              <span className="sr-only">View notifications</span>
              <BellIcon className={classNames("h-6 w-6", unreadGame>0 && "text-chip-bg")} aria-hidden="true" />
              {unreadApp>0 &&
                  <span className="ml-1 inline-flex items-center justify-center px-1 py-1 text-xs font-semibold leading-none text-chip-text bg-chip-bg rounded-full">{unreadApp}</span>
              }
            </a>

            {/* Separator */}
            <div
              className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
              aria-hidden="true"
            />

            {/* Profile dropdown */}
            <Menu as="div" className="relative">
              <Menu.Button className="-m-1.5 flex items-center p-1.5">
                <span className="sr-only">Open user menu</span>
                <img
                  className="h-8 rounded-full "
                  src={"assets/game/characters/avatar_placeholder.png"}
                  alt=""
                />
                <span className="hidden lg:flex lg:items-center">
                  <ChevronDownIcon
                    className="ml-2 h-5 w-5 text-icon-faded hover:text-chip-accent"
                    aria-hidden="true"
                  />
                </span>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-modal-bg py-2 shadow-lg ring-1 ring-text-bg/5 focus:outline-none">
                  {userNavigation.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <a
                          href={item.href}
                            onClick={()=> item.signout && logout()}
                          className={classNames(
                            active ? "text-chip-accent" : "",
                            "block px-3 py-1 text-sm leading-6 text-text-bg",
                          )}
                        >
                          {item.name}
                        </a>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
}
