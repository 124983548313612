import Layout from "../../../Layout";
import Content from "../../../Content";
import Backlog from "./Backlog";
import { useState } from "react";
import { classNames } from "../../../../helpers";
import AllTasks from "./AllTasks";

const tabs = [
  { name: "All tasks", ref: "0" },
  { name: "Backlog", ref: "1" },
];
export default function TaskBacklog() {
  const [activeTab, setActiveTab] = useState("0");

  return (
    <Layout>
      <Content>
        <div>
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            <select
              id="tabs"
              name="tabs"
              className="block w-full rounded-md border-text-bg-inverse py-2 pl-3 pr-10 text-base focus:border-chip-bg focus:outline-none focus:ring-chip-bg sm:text-sm"
              defaultValue={tabs.find((tab) => tab.ref === activeTab)}
              onChange={(tab) => setActiveTab(tab.target.value)}
            >
              {tabs.map((tab) => (
                <option value={tab.ref}>{tab.name}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block ">
            <div className="border-b border-text-bg-inverse">
              <nav className="ml-32 -mb-px flex space-x-8" aria-label="Tabs">
                {tabs.map((tab) => (
                  <button
                    key={tab.name}
                    className={classNames(
                      tab.ref === activeTab
                        ? "border-chip-bg text-chip-bg font-semibold"
                        : "border-transparent text-text-bg hover:border-chip-accent hover:text-chip-accent",
                      "flex whitespace-nowrap border-b-2 py-4 text-sm font-medium",
                    )}
                    onClick={() => setActiveTab(tab.ref)}
                    aria-current={tab.ref === activeTab ? "page" : undefined}
                  >
                    {tab.name}
                  </button>
                ))}
              </nav>
            </div>
          </div>

          {activeTab === "0" && <AllTasks />}
          {activeTab === "1" && <Backlog />}
        </div>
      </Content>
    </Layout>
  );
}
