import {toastOptions} from "../../../../Constants";
import {addDays, format, isAfter, isBefore, isSameDay, isTomorrow, subDays} from "date-fns";
import {useSupabaseClient} from "@supabase/auth-helpers-react";
import React, {useEffect, useState} from "react";
import {
  apiGetTasks,
  apiMassDeleteTask, apiMassUpdateScheduled,
  apiUpdateImportantTask,
  apiUpdateUrgentTask
} from "../../../../../App/api/tasksApi";
import {toast} from "react-toastify";
import {LuAlarmClock, LuChevronsUpDown, LuPlus, LuX} from "react-icons/lu";
import TaskDetail from "../Tasks view/TaskDetail";
import TaskCreate from "../Tasks view/TaskCreate";
import {classNames} from "../../../../helpers";
import {TbExclamationMark} from "react-icons/tb";

export default function AllTasks() {
  const supabase = useSupabaseClient()
  const [tasks, setTasks] = useState([]);
  const [search, setSearch] = useState('');
  const [createdFilter, setCreatedFilter] = useState('all');
  const [scheduledFilter, setScheduledFilter] = useState('all');
  const [dueFilter,setDueFilter] = useState('all');
  const [importanceFilter, setImportanceFilter] = useState('all');
  const [urgencyFilter, setUrgencyFilter] = useState('all');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTask, setModalTask] = useState({});
  const [updateMade, setUpdateMade] = useState(false)
  const [createTaskModalOpen, setCreateTaskModalOpen] = useState(false);
  const [sort,setSort] = useState('none')
  const [sortDirection,setSortDirection] = useState('desc')
  const [selectedTasks, setSelectedTasks] = useState([])

  function toggleSelectedTasks(task){
    if(selectedTasks.includes(task)){
      setSelectedTasks(selectedTasks.filter((t)=>t !== task))
    }
    else {
      setSelectedTasks([...selectedTasks,task])
    }
  }
  async function scheduleSelectedToday(){
    apiMassUpdateScheduled(supabase,selectedTasks).then(()=>setUpdateMade(!updateMade))
  }
  async function deleteSelected(){
    if (window.confirm('Are you sure you want to delete these tasks?')) {
      apiMassDeleteTask(supabase,selectedTasks).then(()=>setUpdateMade(!updateMade))
    }
  }
  async function getTasks(){
    var tasks = await apiGetTasks(supabase)
    setTasks(tasks)
  }
  function updateSort(newsort){
    if(newsort === sort){
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    }
    else {
      setSortDirection('desc')
      setSort(newsort)
    }
  }
  function customSort(a,b){
    if(sort === "none") return 0

    if(sort === 'importance'){
      if(sortDirection === 'asc'){
        return a.important - b.important
      }
      else {
        return b.important - a.important
      }
    }
    if(sort === 'urgence'){
      if(sortDirection === 'asc'){
        return a.urgent - b.urgent
      }
      else {
        return b.urgent - a.urgent
      }
    }
    if(sort === 'due'){
      if(sortDirection === 'asc'){
        return new Date(a.due) - new Date(b.due)
      }
      else {
        if(!a.due) return 1
        if(!b.due) return -1
        var aDue = new Date(a.due)
        var bDue = new Date(b.due)
        var today = new Date()
        if(isAfter(a.due,today)) return 1
        if(isAfter(b.due,today)) return -1
        return new Date(b.due) - new Date(a.due)
      }
    }
    if(sort === 'created'){
      if(sortDirection === 'asc'){
        return new Date(a.created_at) - new Date(b.created_at)
      }
      else {
        return new Date(b.created_at) - new Date(a.created_at)
      }
    }
    if(sort === 'scheduled'){
      var aScheduled = new Date(a.scheduled)
      var bScheduled = new Date(b.scheduled)
      if(a.scheduled_time){
        var split = a.scheduled_time.split(':')
        aScheduled.setHours(Number(split[0]))
        aScheduled.setMinutes(Number(split[1]))
      }
      if(b.scheduled_time){
          var split = b.scheduled_time.split(':')
          bScheduled.setHours(Number(split[0]))
          bScheduled.setMinutes(Number(split[1]))
      }

      if(sortDirection === 'asc'){
        return aScheduled - bScheduled
      }
      else {
        return bScheduled - aScheduled
      }
    }
  }
  function getScheduledTime(task){
    if(!task.scheduled) return ''
    var scheduled = new Date(task.scheduled)
    if(task.scheduled_time){
      var split = task.scheduled_time.split(':')
      scheduled.setHours(Number(split[0]))
      scheduled.setMinutes(Number(split[1]))
      return format(scheduled, "dd/MM/yyyy HH:mm")
    }
    return format(scheduled, "dd/MM/yyyy")
  }
  const closeModal = (updated,deleted) => {
    setModalOpen(false)
    setModalTask({})
    if(updated) {
      setUpdateMade(!updateMade)
    }
    if(deleted){
      toast.success('Task deleted succesfully', toastOptions)
      setUpdateMade(!updateMade)
    }
  }
  const openModal = (task) => {
    setModalTask(task);
    setModalOpen(true)
  }
  const openCreateTaskModal = () => {
    setCreateTaskModalOpen(true)
  }
  const closeCreateTaskModal = (created = false) => {
    setCreateTaskModalOpen(false)
    if(created){
      setUpdateMade(!updateMade)
    }
  }
  async function toggleTaskUrgence(task){
    task.urgent = !task.urgent
    apiUpdateUrgentTask(supabase,task).then(()=>setUpdateMade(!updateMade))
    setSelectedTasks([])
  }
  async function toggleTaskImportance(task){
    task.important = !task.important
    apiUpdateImportantTask(supabase,task).then(()=>setUpdateMade(!updateMade))
    setSelectedTasks([])
  }
  useEffect(() => {
    getTasks()
  }, [updateMade])
  return (
      <div className="flex p-10 overflow-x-auto">
        <div className="bg-card-bg text-text-bg mt-10 flex-1 relative shadow-md sm:rounded-lg overflow-hidden">
          <div className="border-b  border-disabled-text mx-4">
            <div className="flex items-center justify-between space-x-4 pt-3">
              <div className="flex-1 flex items-center space-x-3">
                <h5 className=" font-semibold">All tasks</h5>
              </div>
            </div>
            <div className="flex flex-col-reverse md:flex-row items-center justify-between md:space-x-4 py-3">
              <div className="w-full flex flex-col space-y-3 md:space-y-0 md:flex-row md:items-center">
                <label
                    htmlFor="default-search"
                    className="text-sm font-medium text-text-selected-day sr-only "
                >
                  Search
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                        aria-hidden="true"
                        className="w-4 h-4 text-icon-faded "
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </div>
                  <input
                      type="search"
                      id="backlog-search"
                      onChange={(e)=>setSearch(e.target.value)}
                      value={search}
                      className="block w-full p-2 pl-10 text-sm text-text-selected-day border border-transparent shadow rounded-lg bg-input-bg focus:ring-chip-bg focus:border-chip-accent  "
                      placeholder="Search..."
                      required=""
                  />
                  <button onClick={()=>setSearch('')}
                          className="text-chip-text absolute right-0 bottom-0 top-0 bg-chip-bg hover:bg-chip-accent focus:ring-4 focus:outline-none focus:ring-chip-ring font-medium rounded-r-lg text-sm px-4 py-2"
                  >
                    <LuX className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex items-center space-x-4">
                  <div className="ml-6 gap-2 flex items-center">
                    <span className="text-sm text-text-bg">Task importance</span>
                    <select name="importanceFilter" defaultValue={importanceFilter} onChange={(e)=>setImportanceFilter(e.target.value)}
                            className="  w-32 p-2 bg-input-bg rounded-lg shadow focus:outline-none border-transparent focus:border-transparent focus:ring-0"
                    >
                      <option value="all">Include all</option>
                      <option value="important">Important tasks</option>
                      <option value="trivial">Unimportant tasks</option>
                    </select>
                  </div>
                  <div className="ml-6 gap-2 flex items-center">
                    <span className="text-sm text-text-bg">Task urgency</span>
                    <select name="urgencyFilter" defaultValue={urgencyFilter} onChange={(e)=>setUrgencyFilter(e.target.value)}
                            className="  w-32 p-2 bg-input-bg rounded-lg shadow focus:outline-none border-transparent focus:border-transparent focus:ring-0"
                    >
                      <option value="all">Include all</option>
                      <option value="urgent">Urgent tasks</option>
                      <option value="trivial">Non-urgent tasks</option>
                    </select>
                  </div>
                  <div className="ml-6 gap-2 flex items-center">
                    <span className="text-sm text-text-bg">Due</span>
                    <select name="dueFilter" defaultValue={dueFilter} onChange={(e)=>setDueFilter(e.target.value)}
                            className="  w-32 p-2 bg-input-bg rounded-lg shadow focus:outline-none border-transparent focus:border-transparent focus:ring-0"
                    >
                      <option value="all">Include all</option>
                      <option value="expired">Duedate expired</option>
                      <option value="day">Due tomorrow</option>
                      <option value="week">Due this week</option>
                      <option value="month">Due this month</option>
                    </select>
                  </div>
                  <div className="ml-6 gap-2 flex items-center">
                    <span className="text-sm text-text-bg">Scheduled</span>
                    <select name="scheduledFilter" defaultValue={scheduledFilter} onChange={(e)=>setScheduledFilter(e.target.value)}
                            className="  w-32 p-2 bg-input-bg rounded-lg shadow focus:outline-none border-transparent focus:border-transparent focus:ring-0"
                    >
                      <option value="all">Include all</option>
                      <option value="today">Today</option>
                      <option value="day">Tomorrow</option>
                      <option value="week">This week</option>
                      <option value="month">This month</option>
                    </select>
                  </div>
                  <div className="ml-6 gap-2 flex items-center">
                    <span className="text-sm text-text-bg">Created</span>
                    <select name="createdFilter" defaultValue={createdFilter} onChange={(e)=>setCreatedFilter(e.target.value)}
                            className="  w-32 p-2 bg-input-bg rounded-lg shadow focus:outline-none border-transparent focus:border-transparent focus:ring-0"
                    >
                      <option value="all">Everytime</option>
                      <option value="year">This year</option>
                      <option value="month">This month</option>
                      <option value="week">This week</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-auto flex flex-col md:flex-row mb-3 md:mb-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                <button
                    onClick={()=>openCreateTaskModal()}
                    type="button"
                    className="flex items-center justify-center text-chip-text bg-chip-bg hover:bg-chip-accent focus:ring-4 focus:ring-chip-ring font-medium rounded-lg text-sm px-4 py-2 focus:outline-none "
                >
                  <LuPlus className="h-5 w-5" aria-hidden="true" />
                  Add task
                </button>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-text-bg ">
              <thead className="text-xs text-text-bg uppercase bg-input-bg/20">
              <tr>
                <th scope="col" className="p-4">
                  <div className="flex items-center">
                    <input
                        id="checkbox-all"
                        type="checkbox"
                        className="w-4 h-4 text-chip-bg bg-card-bg rounded border-chip-accent focus:ring-chip-ring focus:ring-2 "
                    />
                    <label htmlFor="checkbox-all" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </th>
                <th scope="col" className="px-4 py-3 min-w-[14rem]">Task</th>
                <th scope="col" className="px-4 py-3 min-w-[10rem]">Category{/*<LuChevronsUpDown className="h-4 w-4 ml-1 inline-block" onClick={()=>updateSort("category")}/> */}</th>
                <th scope="col" className="px-4 py-3 min-w-[6rem]">Scheduled<LuChevronsUpDown className="h-4 w-4 ml-1 inline-block" onClick={()=>updateSort("scheduled")}/></th>
                <th scope="col" className="px-4 py-3 min-w-[2rem]">Importance<LuChevronsUpDown className="h-4 w-4 ml-1 inline-block" onClick={()=>updateSort("importance")} /></th>
                <th scope="col" className="px-4 py-3 min-w-[2rem]">Urgence<LuChevronsUpDown className="h-4 w-4 ml-1 inline-block" onClick={()=>updateSort("urgence")} /></th>
                <th scope="col" className="px-4 py-3 min-w-[6rem]">Due<LuChevronsUpDown className="h-4 w-4 ml-1 inline-block" onClick={()=>updateSort("due")}/></th>
                <th scope="col" className="px-4 py-3 min-w-[6rem]">Created<LuChevronsUpDown className="h-4 w-4 ml-1 inline-block" onClick={()=>updateSort("created")}/></th>
                <th scope="col" className="px-4 py-3 min-w-[7rem]">Estimated time</th>
              </tr>
              </thead>
              <tbody>
              {tasks.filter((task)=> {
                var filterOK = true
                if(search !== ''){
                  filterOK = task.title.includes(search)
                }
                if(createdFilter !== 'all' && filterOK){
                  var created = new Date(task.created_at)
                  var date = new Date()
                  if(createdFilter === 'year'){
                    filterOK = created.getFullYear() === date.getFullYear()
                  }
                  if(createdFilter === 'month'){
                    filterOK = created.getMonth() === date.getMonth()
                  }
                  if(createdFilter === 'week'){
                    var lastWeek = subDays(date,7)
                    filterOK = isAfter(created,lastWeek)
                  }
                }
                if(dueFilter !== 'all' && filterOK){
                  var due = new Date(task.due)
                  var date = new Date()
                  if(!task.due){
                    filterOK = false
                  }
                  if(dueFilter === 'expired' && filterOK){
                    filterOK = isAfter(date,due)
                  }
                  if(dueFilter === 'day' && filterOK){
                    var tomorrow = addDays(date,2)
                    filterOK = isBefore(due,tomorrow)
                  }
                  if(dueFilter === 'week' && filterOK){
                    var tomorrow = addDays(date,8)
                    filterOK = isBefore(due,tomorrow)
                  }
                  if(dueFilter === 'month' && filterOK){
                    filterOK = isBefore(due,date) || due.getMonth() === date.getMonth()
                  }
                }
                if(importanceFilter !== 'all' && filterOK){
                  if(importanceFilter === 'important'){
                    filterOK = task.important === true
                  }
                  if(importanceFilter === 'trivial'){
                    filterOK = task.important === false || !task.important
                  }
                }
                if(urgencyFilter !== 'all' && filterOK){
                  if(urgencyFilter === 'urgent'){
                    filterOK = task.urgent === true
                  }
                  if(urgencyFilter === 'trivial'){
                    filterOK = task.urgent === false || !task.urgent
                  }
                }
                if(scheduledFilter !== 'all' && filterOK){
                  if(!task.scheduled)filterOK = false
                    var scheduled = new Date(task.scheduled)
                    var date = new Date()

                    if(scheduledFilter === 'today' && filterOK){
                        filterOK = isSameDay(scheduled,date) && !task.done
                    }
                    if(scheduledFilter === 'day' && filterOK){
                        var tomorrow = addDays(date,2)
                        filterOK = isTomorrow(scheduled) && !task.done
                    }
                    if(scheduledFilter === 'week' && filterOK){
                        var week = addDays(date,8)
                        filterOK = isBefore(scheduled,week) && !task.done
                    }
                    if(scheduledFilter === 'month' && filterOK){
                        filterOK = (isBefore(scheduled,date) || scheduled.getMonth() === date.getMonth()) && !task.done
                    }
                }
                return filterOK
              }).sort((a,b)=>customSort(a,b)).map((task,index) =>
                  (
                      <tr className="border-b border-disabled-text bg-card-bg text-text-bg hover:bg-card-hover hover:text-text-selected-day cursor-pointer" >
                        <td className="px-4 py-3 w-4">
                          <div className="flex items-center">
                            <input
                                id={"checkbox-table-search-1-task"+task.id}
                                type="checkbox"
                                onClick={()=>toggleSelectedTasks(task.id)}
                                checked = {selectedTasks.includes(task.id)}
                                className="w-4 h-4 text-chip-bg bg-input-bg rounded border-disabled-text focus:ring-chip-ring focus:ring-2"
                            />
                            <label htmlFor={"checkbox-table-search-1-task"+task.id} className="sr-only">checkbox</label>
                          </div>
                        </td>
                        <th scope="row" className="px-4 py-3 font-medium  whitespace-nowrap flex items-center" onClick={() => openModal(task)}>
                          <span>{task.title}</span>
                        </th>
                        <td className="px-4 py-3 font-medium  whitespace-nowrap" onClick={() => openModal(task)}>{task.category}</td>
                        <td className="px-4 py-3 font-medium  whitespace-nowrap" onClick={() => openModal(task)}>{getScheduledTime(task)}</td>
                        <td className="px-4 py-3 font-medium  whitespace-nowrap ">
                          <TbExclamationMark onClick={()=>toggleTaskImportance(task)}
                                             className={classNames(task.important ? "text-color-danger" : "text-text-bg", "cursor-pointer h-5 w-5 shrink-0")}
                                             aria-hidden="true"
                          />
                        </td>

                        <td className="px-4 py-3 font-medium  whitespace-nowrap ">
                          <LuAlarmClock onClick={()=>toggleTaskUrgence(task)}
                                        className={classNames(task.urgent ? "text-color-danger" : "text-text-bg", "cursor-pointer h-5 w-5 shrink-0")}
                                        aria-hidden="true"
                          />
                        </td>
                        <td className="px-4 py-3 font-medium  whitespace-nowrap " onClick={() => openModal(task)}>
                          {task.due &&
                              <div className="flex">
                                {isAfter(new Date(),new Date(task.due)) &&
                                    <TbExclamationMark className="h-5 w-5 shrink-0 text-color-danger" aria-hidden="true" />
                                }
                                <span className={classNames(isAfter(new Date(),subDays(new Date(task.due),5)) && "text-color-danger", isAfter(new Date(),subDays(new Date(task.due),3)) && "font-bold")}>
                              {format(new Date(task.due), "dd/MM/yyyy")}
                            </span>
                              </div>

                          }
                        </td>
                        <td className="px-4 py-3 font-medium  whitespace-nowrap " onClick={() => openModal(task)}>
                          {format(new Date(task.created_at), "dd/MM/yyyy")}
                        </td>
                        <td className="px-4 py-3 font-medium  whitespace-nowrap " onClick={() => openModal(task)}>
                          {task.timeEstimate!==null && task.timeEstimate !=="00:00:00" && task.timeEstimate}
                        </td>
                      </tr>

                  ))}
              </tbody>
            </table>
          </div>
          <div
              className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 px-4 pt-3 pb-4"
              aria-label="Table navigation"
          >
            <div className="text-xs flex items-center space-x-5">
              <button
                  onClick={()=>scheduleSelectedToday()}
                  type="button"
                  className="py-2 px-3 flex items-center text-xs font-medium text-center text-chip-text bg-chip-bg rounded-lg hover:bg-chip-accent focus:ring-4 focus:outline-none focus:ring-chip-ring "
              >
                Move to today
              </button>
            </div>
            <div className="flex items-center space-x-4">
              <button
                  onClick={()=>deleteSelected()}
                  type="button"
                  className="py-1.5 flex items-center text-sm font-medium text-center text-color-danger rounded-lg hover:text-chip-accent focus:ring-4 focus:outline-none focus:ring-chip-ring"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
        {modalOpen && <TaskDetail task={modalTask} refe={"none"} closeTaskModal={closeModal}/>}
        {createTaskModalOpen && <TaskCreate day={null} closeModal={closeCreateTaskModal}/>}
      </div>
  );
}
