import {createContext, useEffect, useState} from "react";
import {useSupabaseClient} from "@supabase/auth-helpers-react";

import {toast} from "react-toastify";
import {toastOptions} from "../../../components/Constants";
import {apiCreateNotification, apiGetNotifications} from "../../api/NotificationApi";
import {
    apiAddCompanion,
    apiGetCompanions,
    apiGetCompanionType,
    apiGetEggs, apiPlayWithCompanion,
    apiRemoveEgg
} from "../../api/Companions/CompanionAPI";
import {intervalToDuration, isAfter} from "date-fns";
import {getCompanionName} from "../../../components/helpers";
import {decideVariant} from "../LoottableUtils";

export const CompanionContext = createContext();

export const CompanionContextProvider = ({ children }) => {
    const [companions,setCompanions] = useState([])
    const [eggs,setEggs] = useState([])
    const supabase = useSupabaseClient()
    const [companionsUpdated, setCompanionsUpdated] = useState(false)
    const [previousUpdateState , setPreviousUpdateState] = useState(false)
    const [eggHatched, setEggHatched] = useState(false)
    const [isLoaded,setIsLoaded] = useState(false)


    async function playWith(companion){
        await apiPlayWithCompanion(supabase,companion.id,companion.experience)
    }
    async function hatchEgg(egg){
        var species = egg.species
        console.log("species",species)
        var type = await apiGetCompanionType(supabase,species)
        console.log("type",type)
        var variant = decideVariant(type.variants)

        var companion = {
            type: species,
            variant: variant,
            experience: 0,
            name: "",
            buddy:false
        }
        await apiAddCompanion(supabase,companion)
        var notification = {
            title: "Egg hatched",
            content: "Your "+getCompanionName(species)+" egg has hatched into a " + variant + " "+getCompanionName(species),
            icon: "companion",
            type: "game"
        }
        await apiCreateNotification(supabase,notification)
        await apiRemoveEgg(supabase,egg.id)
    }


    useEffect(()=> {

        async function checkHatch(egg){
            var endTime = new Date(egg.hatchdate)
            var t = egg.hatchtime.split(":")
            endTime.setHours(t[0])
            endTime.setMinutes(t[1])
            if(isAfter(new Date(),endTime)){
                await hatchEgg(egg)
                setEggHatched(true)
            }
            return false
        }
        async function fetch(){
            const q = await apiGetCompanions(supabase)
            const e = await apiGetEggs(supabase)
            setCompanions(q)
            setEggs(e)
            e.map((egg)=> {
                checkHatch(egg)
            })
        }
        if(!isLoaded || companionsUpdated !== previousUpdateState || eggHatched){
            fetch()
            setIsLoaded(true)
            setPreviousUpdateState(companionsUpdated)
            setEggHatched(false)
        }

    },[isLoaded,companionsUpdated,eggHatched])
    return (
        <CompanionContext.Provider
            value={{
               companions,setCompanions,eggs,setEggs,companionsUpdated,setCompanionsUpdated,playWith
            }}
        >
            {children}
        </CompanionContext.Provider>
    );
};
