import {LuAlarmClock, LuCalendar, LuCheckCircle, LuClock} from "react-icons/lu";

import Estimated_time_fragment from "../../../../fragments/Estimated_time_fragment";
import {classNames, getMobname} from "../../../../helpers";
import {useContext, useState} from "react";
import {TbExclamationMark} from "react-icons/tb";
import {format} from "date-fns";
import {useSupabaseClient} from "@supabase/auth-helpers-react";
import Scheduled_time_fragment from "../../../../fragments/Scheduled_time_fragment";
import {useSelector} from "react-redux";
import {apiUpdateImportantTask, apiUpdateStatusTask, apiUpdateUrgentTask} from "../../../../../App/api/tasksApi";

import {battleVictory, calcDamage} from "../../../../../App/utils/CombatUtils";
import {apiEndFight, apiUpdateMobHealth} from "../../../../../App/api/Quest/QuestApi";
import {CombatContext} from "../../../../../App/utils/Contexts/CombatContext";
import {QuestContext} from "../../../../../App/utils/Contexts/QuestContext";
import {toastOptions} from "../../../../Constants";
import {toast} from "react-toastify";
import {LevelContext} from "../../../../../App/utils/Contexts/LevelContext";
import {NotificationContext} from "../../../../../App/utils/Contexts/NotificationContext";

export default function Task({task, openTaskModal}) {
    const [done, setDone] = useState(task.done)
    const [important, setImportant] = useState(task.important)
    const [urgent, setUrgent] = useState(task.urgent)
    const supabase = useSupabaseClient();
    const settings = useSelector(state => state.settings).settings
    const timeformat = settings.timeformat
    const {combat,hasActiveCombat,setUpdated,updated} = useContext(CombatContext)
    const {character} = useContext(LevelContext)
    const {updateQuests} = useContext(QuestContext)
    const {addExp} = useContext(LevelContext)
    const {addGameNotification} = useContext(NotificationContext)

    async function toggleDone(){
        setDone(!done)
        task.done = !task.done

        if(task.done && !task.completion_exp_given){
            await addExp(10)
            task.completion_exp_given = true
        }
        if(hasActiveCombat && task.done && !task.combat_damage_dealt){
            if(combat.weakness === "completion"){
                var damage = calcDamage({strength:character.strength,wisdom:character.wisdom,intelligence:character.intelligence})
                var cHealth = combat.current_health - damage
                if(cHealth <= 0){
                    var looti = await battleVictory(supabase,combat,character)
                    updateQuests("kill",combat.mob_name)
                    toast.success("You've defeated the "+ getMobname(combat.mob_name),toastOptions)
                    var items = []
                    looti.map((item)=>{
                        items.push({item:item.item.id,amount:item.amount})
                    })

                    var notification = {
                        title: "Battle won!",
                        content: "You've won the battle against the "+getMobname(combat.mob_name)+ "and received the following rewards",
                        linked_items: JSON.stringify(items),
                        icon:"combat"
                    }
                    addGameNotification(notification)
                    await apiEndFight(supabase,combat.id)
                    await addExp(20)
                }
                else{
                    console.log(cHealth)
                    console.log(combat)
                     await apiUpdateMobHealth(supabase,combat.id,cHealth)
                    task.combat_damage_dealt = true
                }
                setUpdated(!updated)
            }
        }
        apiUpdateStatusTask(supabase,task)
    }
    function toggleImportant(){
        setImportant(!important)
        task.important = !task.important
        apiUpdateImportantTask(supabase,task)
    }
    function toggleUrgent(){
        setUrgent(!urgent)
        task.urgent = !task.urgent

        apiUpdateUrgentTask(supabase,task)
    }
  return (
    <div className="flex flex-col justify-start text-text-bg bg-card-bg px-2.5 rounded-2xl shadow-md ring-1 ring-inset ring-card-ring overflow-x-hidden my-5 p-2 min-h-20 cursor-pointer"  >
      <div   className="task-header flex-none flex justify-between py-2"  >
        <span onClick={()=>openTaskModal(task,'none')} className="flex flex-1">{task.title}</span>
          <div className="flex items-center">
              <LuClock onClick={()=>openTaskModal(task,'actual')}
                  className="text-icon-faded/80 h-6 w-6 shrink-0 pr-1"
                  aria-hidden="true" />
              <Estimated_time_fragment key={"time_es"+task.id} time_estimate={task.timeEstimate} time_actual={task.timeActual} actual_set={(task.timeActual !== null && task.timeActual !== "00:00:00")} showEstimate={false}/>

          </div>
      </div>
        <div onClick={()=>openTaskModal(task,'none')} className="flex flex-none py-2 text-sm">
            <span>{task.category}</span>
        </div>
      <div className="flex task-footer flex-none py-2 text-sm">
        <button
          type="button"
          className="-m-2.5 px-3 z-50"
          onClick={() => toggleDone()}
        >
            <LuCheckCircle
                className={classNames(done ? "text-icon-task-done" : "text-icon-faded/80", "h-6 w-6 shrink-0 hover:text-icon-task-done")}
              aria-hidden="true"
            />
          <span className="sr-only">Task completed</span>
        </button>
          <button
              type="button"
              className="-m-2.5 px-3 z-50"
              onClick={() => toggleImportant()}
          >
              <TbExclamationMark
                  className={classNames(important ? "text-color-danger" : "text-icon-faded/80", "h-6 w-6 shrink-0 hover:text-color-danger")}
                  aria-hidden="true"
              />
              <span className="sr-only">Important task</span>
          </button>
          <button
              type="button"
              className="-m-2.5 px-3 z-50"
              onClick={() => toggleUrgent()}
          >
              <LuAlarmClock
                  className={classNames(urgent ? "text-color-danger" : "text-icon-faded/80", "h-6 w-6 shrink-0 hover:text-color-danger")}
                  aria-hidden="true"
              />
              <span className="sr-only">Urgent task</span>
          </button>
          <div className="flex items-center px-3">
              {task.due &&
                  <>
                      <LuCalendar
                          className="text-icon-faded/80 h-6 w-6 shrink-0 pr-1"
                          aria-hidden="true" />
                      <span className="text-icon-faded">{format(new Date(task.due),"dd/MM/yyyy")}</span>
                  </>
              }

          </div>
          <div className="flex-1 flex fillerDiv justify-end" onClick={()=>openTaskModal(task,'none')} >
              {task.scheduled_time && task.scheduled_time !== "00:00:00" && <Scheduled_time_fragment timeformat={timeformat} time={task.scheduled_time}/>}
          </div>
      </div>
    </div>
  );
}
