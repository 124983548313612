import React, {useState} from "react";

import ReactDOM from "react-dom";
import {useSupabaseClient} from '@supabase/auth-helpers-react'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {joinUrl} from "../../../../helpers";
import {toastOptions} from "../../../../Constants";
const base_url = "https://www.taskz.io";
function Registration_form() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [loading, setLoading] = useState(false)
  const supabase = useSupabaseClient()
    const [agreement, setAgreement] = useState(false)




  function toggleAgreement(){
    setAgreement(!agreement)
  }
  async function signUpWithEmail() {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if(!agreement){
      toast.error("Please agree to the terms and conditions and the privacy policy",toastOptions)

    }
    else  if(email === '' || password === '' || passwordConfirm === ''){
      toast.error('Please fill in all the required fields',toastOptions)
    }
    else  if(password !== passwordConfirm){
      toast.error('The passwords do not match',toastOptions)
    }
    else if(!email.match(validRegex)){
      toast.error('Please enter a valid email address',toastOptions)
    }
    else{
      setLoading(true)


      const {
        data: { session },
        error,
      } = await supabase.auth.signUp({
        email: email,
        password: password,
      })

      if (error) toast.error(error.message)
      if (!session) toast.success('Please check your inbox for email verification!',toastOptions)
      setLoading(false)
    }
  }

  async function signInWithGoogle(){
    if(!agreement){
      toast.error("Please agree to the terms and conditions and the privacy policy",toastOptions)
      return;
    }
    else{
      setLoading(true)
      const { error} =await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          scopes: process.env.REACT_APP_GOOGLE_CALENDAR_SCOPES,
          queryParams: {
            prompt: 'consent',
            access_type: 'offline',
          },
        }})
      if (error) toast.error(error.message)
      setLoading(false)
    }

  }


  return (
    <div className="mt-32 bg-modal-bg rounded-2xl min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 sm:mx-auto sm:w-full max-w-lg">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
            className="h-10 mx-auto w-auto"
            src="Icon_32x32.png"
            alt="Taskz"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-text-important">
          Create an account
        </h2>
      </div>


      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" action="#" method="POST">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-text-bg"
            >
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
                required
                className="block w-full rounded-md border-0 py-1.5 text-text-important shadow-sm ring-1 bg-input-bg ring-inset ring-card-ring placeholder:text-text-bg/50 focus:ring-inset focus:ring-chip-bg sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-text-bg"
              >
                Password
              </label>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
                required
                className="block w-full rounded-md border-0 py-1.5 text-text-important shadow-sm ring-1 bg-input-bg ring-inset ring-card-ring placeholder:text-text-bg/50 focus:ring-2 focus:ring-inset focus:ring-chip-bg sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="confirm_password"
                className="block text-sm font-medium leading-6 text-text-bg"
              >
                Confirm your password
              </label>
            </div>
            <div className="mt-2">
              <input
                id="confirm_password"
                name="confirm_password"
                type="password"
                autoComplete="current-password"
                onChange={(e) => setPasswordConfirm(e.target.value)}
                required
                className="block w-full rounded-md border-0 py-1.5 text-text-important shadow-sm ring-1 bg-input-bg ring-inset ring-card-ring placeholder:text-text-bg/50 focus:ring-2 focus:ring-inset focus:ring-chip-bg sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="flex">

            <div className="mt-2">
              <input
                  id="agreement"
                  name="agreement"
                  type="checkbox"
                  onChange={(e) => toggleAgreement()}
                  required
                  className="w-5 h-5 block rounded-md border-0 text-text-important shadow-sm ring-1 bg-input-bg ring-inset ring-card-ring placeholder:text-text-bg/50 focus:ring-2 focus:ring-inset focus:ring-chip-bg sm:text-sm sm:leading-6"
              />
            </div>
            <div className=" ml-2 flex items-center justify-between">
              <label
                  htmlFor="confirm_password"
                  className="block text-sm font-medium leading-6 text-text-bg"
              >
                I have read and agree to the <a href={joinUrl(base_url, '/termsofservice')}>terms and conditions</a> and the <a href={joinUrl(base_url, '/privacy')}>privacy policy</a>
              </label>
            </div>
          </div>
          <ToastContainer />
          <div>
            <button
                title="Sign up"
                disabled={loading}
                onClick={() => signUpWithEmail()}
                className="flex w-full justify-center rounded-md bg-chip-bg px-3 py-1.5 text-sm font-semibold leading-6 text-chip-text shadow-sm hover:bg-chip-accent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-chip-bg"
            >Sign up</button>

          </div>
        </form>
        <div className="relative mt-10">
          <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
          >
            <div className="w-full border-t border-text-bg-inverse" />
          </div>
          <div className="relative mt-10">
            <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
            >
              <div className="w-full border-t border-text-bg/60" />
            </div>
            <div className="relative flex justify-center text-sm font-medium leading-6">
              <span className="bg-modal-bg px-6 text-text-bg">
                Or continue with
              </span>
            </div>
          </div>
        </div>
        <div>
          <div className="mt-6 grid grid-cols-1 gap-4">
            <button
                onClick={()=>signInWithGoogle()}
                className="flex items-center justify-center gap-3 rounded-md px-3 py-2 text-sm font-semibold bg-input-bg text-text-important shadow-sm ring-1 ring-inset ring-text-bg/60 hover:bg-chip-bg hover:text-chip-text focus-visible:ring-transparent"
            >
              <svg className="h-5 w-5" aria-hidden="true" viewBox="0 0 24 24">
                <path
                    d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                    fill="#EA4335"
                />
                <path
                    d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                    fill="#4285F4"
                />
                <path
                    d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                    fill="#FBBC05"
                />
                <path
                    d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                    fill="#34A853"
                />
              </svg>
              <span className="text-sm font-semibold leading-6">Google</span>
            </button>


          </div>

        </div>
      </div>
      <p className="mt-10 text-center text-sm text-text-bg">
        Already a member?{" "}
        <a
          href="/login"
          className="font-semibold leading-6 text-chip-bg hover:text-chip-accent"
        >
          Sign in
        </a>
      </p>
    </div>
  );
}

export default Registration_form;
