import {useEffect, useState} from "react";
import {useSupabaseClient} from "@supabase/auth-helpers-react";
import {apiGetStackables} from "../../../../App/api/Inventory/InventoryApi";
import Layout from "../../Layout";
import Content from "../../Content";


export default function Backpack(){
    const [stackables,setStackables] = useState([])
    const supabase = useSupabaseClient()
    const [inventoryUpdated, setInventoryUpdated] = useState(false)
    useEffect(()=> {
        async function fetchStackables(){
            const q = await apiGetStackables(supabase)
            setStackables(q)
            console.log(q)
        }
        fetchStackables()

    },[inventoryUpdated])
    return (
        <Layout>
            <Content>
                <div className="column-container p-8 lg:p-10 w-full h-full">
                    {stackables.length !== 0 &&
                        <div className="grid grid-cols-2 items-start gap-4 md:grid-cols-4 lg:grid-cols-8 lg:gap-8 w-full h-full">
                            {stackables.map(stackable => (
                                <div className="bg-modal-bg rounded-lg flex flex-col justify-center items-center p-4">
                                    <img src={"assets/game/items/"+stackable.item_name+".png"} alt="item" className="w-20 h-20"/>
                                    <span className="text-text-bg font-semibold text-lg ml-2 mr-4">{stackable.item_name.replace("_"," ")}</span>
                                    <span className="text-text-bg text-sm ml-2 mr-4">Quantity: {stackable.count}</span>
                                </div>
                            ))}
                        </div>

                    }

                    {stackables.length === 0 &&
                        <div className="bg-modal-bg rounded-lg flex flex-1 justify-center mt-10">
                            <span className="text-text-bg font-semibold text-lg ml-2 mr-4">No items in your backpack</span>
                        </div>
                    }

                </div>
            </Content>
        </Layout>
    )
}
