import React from "react";

import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Tasks from "./components/layout/Content/Main_page/Tasks view/Tasks";
import Login_page from "./components/layout/Content/Main_page/Accounts/Login_page";
import Registration_page from "./components/layout/Content/Main_page/Accounts/Registration_page";
import TaskBacklog from "./components/layout/Content/Main_page/TaskListView/TaskBacklog";
import Settings from "./components/Settings/Settings";
import NotificationOverview from "./components/layout/Content/Main_page/Notifications/NotificationOverview";
import Quests from "./components/layout/Content/Main_page/Quests/Quests";
import Companions from "./components/layout/Content/Main_page/Companions/Companions";
import {ErrorBoundary} from "react-error-boundary";
import {Provider} from "react-redux";
import {persistor, store} from "./App/store";
import {
    DraggingContextProvider,
    SelectedDayContextProvider,
    SidebarContextProvider
} from "./components/Contexts";
import {Theme} from "./App/theme-provider";
import {PersistGate} from "redux-persist/integration/react";
import ProtectedRoute from "./App/lib/ProtectedRoute";
import UnauthenticatedRoute from "./App/lib/UnauthenticatedRoute";
import Callback from "./App/lib/callback/Callback";
import {SessionContextProvider} from '@supabase/auth-helpers-react'
import {supabase} from "./App/lib/supabase.ts";
import Calendar from "./components/layout/Content/Main_page/Calendar view/Calendar";
import Dailyplan from "./components/layout/Content/Plan/Daily/Dailyplan";
import {toast, ToastContainer} from "react-toastify";
import Dailyreview from "./components/layout/Content/Plan/Daily/Dailyreview";
import Town from "./components/layout/Content/Town/Town";
import Backpack from "./components/layout/Content/Inventory/Backpack";
import Combat from "./components/layout/Content/Main_page/Combat/Combat";
import Passwordrecovery from "./App/lib/callback/Passwordrecovery";
import Bugs from "./components/layout/Content/Bugs";
import {toastOptions} from "./components/Constants";

function App() {
  return (
<ErrorBoundary fallbackRender={fallbackRender} onReset={(details) => {}}>
    <SessionContextProvider supabaseClient={supabase}>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <SelectedDayContextProvider>
                    <DraggingContextProvider>
                        <SidebarContextProvider>
                            <Theme>
                                <Router>
                                    <Routes>
                                        <Route path="/plan"  element={<ProtectedRoute><Tasks /></ProtectedRoute>} />
                                        <Route path="/calendar" element={<ProtectedRoute><Calendar /></ProtectedRoute>} />
                                        <Route path="/tasks" element={<ProtectedRoute><TaskBacklog /></ProtectedRoute>} />
                                        <Route path="/messages" element={<ProtectedRoute><NotificationOverview /></ProtectedRoute>} />
                                        <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
                                        <Route path="/quests" element={<ProtectedRoute><Quests /></ProtectedRoute>} />
                                        <Route path="/companions" element={<ProtectedRoute><Companions /></ProtectedRoute>} />
                                        <Route path="/planday" element={<ProtectedRoute><Dailyplan/></ProtectedRoute>} />
                                        <Route path="/reviewday" element={<ProtectedRoute><Dailyreview/></ProtectedRoute>} />
                                        <Route path="/town" element={<ProtectedRoute><Town /></ProtectedRoute>} />
                                        <Route path="/inventory" element={<ProtectedRoute><Backpack /></ProtectedRoute>} />
                                        <Route path="/combat" element={<ProtectedRoute><Combat /></ProtectedRoute>} />
                                        <Route path="/bugs" element={<ProtectedRoute><Bugs /></ProtectedRoute>} />
                                        <Route path="/login" element={<UnauthenticatedRoute><Login_page /></UnauthenticatedRoute>} />
                                        <Route path="/register" element={<UnauthenticatedRoute><Registration_page /></UnauthenticatedRoute>} />
                                        <Route path="/auth/callback" element={<Callback />} />
                                        <Route path="/resetpassword" element={<Passwordrecovery />} />
                                        <Route exact path="*" element={<ProtectedRoute><Tasks /></ProtectedRoute>} />
                                    </Routes>
                                </Router>
                                <ToastContainer />
                            </Theme>
                        </SidebarContextProvider>
                    </DraggingContextProvider>
                </SelectedDayContextProvider>
            </PersistGate>
        </Provider>
    </SessionContextProvider>
</ErrorBoundary>
  );
}

export default App;

function fallbackRender({ error, resetErrorBoundary }) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.
    toast.error('An error occurred: ' + error.message,toastOptions)
    resetErrorBoundary()
}
