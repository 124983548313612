
import {ToastContainer} from "react-toastify";
import React from "react";

export default function Registration_access({setCode}){


    return (

        <div className="mt-32 bg-modal-bg rounded-2xl min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 sm:mx-auto sm:w-full max-w-lg">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img
                    className="h-10 mx-auto w-auto"
                    src="Icon_32x32.png"
                    alt="Taskz"
                />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-text-important">
                   Taskz is currently in a closed alpha stage. Please enter the code you received in your mailbox to access the registration form.
                </h2>
            </div>


            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" action="#" method="POST">
                    <div>
                        <label
                            htmlFor="code"
                            className="block text-sm font-medium leading-6 text-text-bg"
                        >
                            Code
                        </label>
                        <div className="mt-2">
                            <input
                                id="code"
                                name="code"
                                type="text"
                                onChange={(e) => setCode(e.target.value)}
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-text-important shadow-sm ring-1 bg-input-bg ring-inset ring-card-ring placeholder:text-text-bg/50 focus:ring-inset focus:ring-chip-bg sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>


                    <ToastContainer />
                    <div>

                    </div>
                </form>
                <div className="relative mt-10">
                    <div
                        className="absolute inset-0 flex items-center"
                        aria-hidden="true"
                    >
                        <div className="w-full border-t border-text-bg-inverse" />
                    </div>
                    <div className="relative mt-10">
                        <div
                            className="absolute inset-0 flex items-center"
                            aria-hidden="true"
                        >
                            <div className="w-full border-t border-text-bg/60" />
                        </div>
                        <div className="relative flex justify-center text-sm font-medium leading-6">
              <span className="bg-modal-bg px-6 text-text-bg">
                Or continue with
              </span>
                        </div>
                    </div>
                </div>
                <div>


                </div>
            </div>
            <p className="mt-10 text-center text-sm text-text-bg">
                Already a member?{" "}
                <a
                    href="/login"
                    className="font-semibold leading-6 text-chip-bg hover:text-chip-accent"
                >
                    Sign in
                </a>
            </p>
        </div>
    )
}
