import React, {Fragment, useEffect, useState} from 'react'
import {Dialog, Menu, Transition} from '@headlessui/react'
import {LuClock, LuMoreHorizontal} from "react-icons/lu";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {format, isAfter, isDate} from "date-fns";
import {checkToken, deletegCalEvent, setTime, updategCalEvent} from "../../../../../App/utils/CalendarUtils";
import {useSession} from "@supabase/auth-helpers-react";
import {useSelector} from "react-redux";
import {toastOptions} from "../../../../Constants";

export default function EventDetail({event,closeModal}){
    const session =useSession()
    const [title, setTitle] = useState(event.summary)
    const [description, setDescription] = useState(event.description)

    const from = (event.from !== undefined? new Date(event.from): new Date(event.start.dateTime))
    const to = (event.to !== undefined? new Date(event.to): new Date(event.end.dateTime))

    const [dateStart, setDateStart] = useState(format(from,'yyyy-MM-dd'))
    const [dateEnd,setDateEnd] = useState(format(to,'yyyy-MM-dd'))

    const [hoursStart,setHoursStart] = useState(from.getHours())
    const [minutesStart,setMinutesStart] = useState(from.getMinutes());
    const [hoursEnd,setHoursEnd] = useState(to.getHours());
    const [minutesEnd,setMinutesEnd] = useState(to.getMinutes());
    const settings = useSelector(state => state.settings).settings
    const timeformat = settings.timeformat
    const [timeSelectorStart, setTimeSelectorStart] = useState((timeformat!=="HH"? hoursStart >= 12 ? "PM" : "AM" : ""))
    const [timeSelectorEnd, setTimeSelectorEnd] = useState((timeformat!=="HH"? hoursEnd >= 12 ? "PM" : "AM" : ""))

    async function validateForm() {
        var continuepost = true
        if (title === '') {
            toast("Title is required")
            continuepost = false
            return
        }
        if (dateStart === null || isDate(new Date(dateStart)) === false) {
            toast("Date is not valid")
            continuepost = false
            return
        }
        if(dateEnd === null || isDate(new Date(dateEnd)) === false){
            setDateEnd(dateStart)
        }
        var start = setTime(dateStart,hoursStart,minutesStart,0)
        var end= setTime(dateEnd,hoursEnd,minutesEnd,0)
        if(timeformat !== "HH"){
            if(timeSelectorStart === "AM"){
                start= setTime(dateStart,hoursStart,minutesStart,0)
            }
            else{
                start =setTime(dateStart,hoursStart+12,minutesStart,0)
            }

            if(timeSelectorEnd === "AM"){
                end =setTime(dateEnd,hoursEnd,minutesEnd,0)
            }
            else{
                end=setTime(dateEnd,hoursEnd+12,minutesEnd,0)
            }
        }

        if(isAfter(start,end)){
            toast.error("End date is before start date",toastOptions)
            continuepost = false
            return
        }


        if(continuepost){
            await checkToken(session)
            var token = session.provider_token
            if(!session.provider_token)return []

            await updategCalEvent('primary', event.id,start, end,title,description, token).then(()=>closeModal(false,true))
        }
    }
    async function deleteEvent(){
        await checkToken(session)
        var token = session.provider_token
        if(!session.provider_token)return []

       await deletegCalEvent('primary', event.id, token).then(()=>closeModal(true,true))
    }
    function handleOnChangeStart(value){
        if(Number.isNaN(value) || value<0) return;
        let newhours = Number(hoursStart) + Math.floor(value/60);
        const newminutes = value - (Math.floor(value/60) * 60);
        setMinutesStart(newminutes);
        if(timeformat !== "HH" && newhours >= 12){
            setTimeSelectorStart("PM")
            if(newhours-12 !== 0){
                newhours = newhours-12
            }
        }
        setHoursStart(newhours);

    }
    function handleOnChangeHourStart(value){
        if(Number.isNaN(value) || value<0) return;
        if(timeformat !== "HH" && value >= 12){
            setTimeSelectorStart("PM")
            if(value-12 !== 0){
                value = value-12
            }
        }
        setHoursStart(value);
    }
    function handleOnChangeEnd(value){
        if(Number.isNaN(value) || value<0) return;
        let newhours = Number(hoursEnd) + Math.floor(value/60);
        const newminutes = value - (Math.floor(value/60) * 60);
        setMinutesEnd(newminutes);
        if(timeformat !== "HH" && newhours >= 12){
            setTimeSelectorEnd("PM")
            if(newhours-12 !== 0){
                newhours = newhours-12
            }
        }
        setHoursEnd(newhours);
    }
    function handleOnChangeHourEnd(value){
        if(Number.isNaN(value) || value<0) return;
        if(timeformat !== "HH" && value >= 12){
            setTimeSelectorEnd("PM")
            if(value-12 !== 0){
                value = value-12
            }
        }
        setHoursEnd(value);
    }
    useEffect(()=>{
        if(timeformat !== "HH"){
            if(hoursStart >= 12 && hoursStart-12 !== 0){
                setHoursStart(hoursStart-12)
            }
            if(hoursEnd >= 12 && hoursEnd-12 !== 0){
                setHoursEnd(hoursEnd-12)
            }
        }
    },[timeformat])
    return (
        <>
            <Transition.Root show={true} as={Fragment}>
                <Dialog as="div" className="relative z-10 " onClose={()=>closeModal()}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-dark-grey bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-card-bg px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div>
                                        <Menu as="div" className="relative flex flex-1 justify-end outline-none">
                                            <Menu.Button className="-m-1.5 flex items-center p-1.5 outline-none">
                                                <span className="sr-only">Open event menu</span>
                                                <LuMoreHorizontal
                                                    className="ml-2 h-5 w-5 text-text-bg hover:text-chip-accent"
                                                    aria-hidden="true"
                                                />
                                            </Menu.Button>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="absolute right-0 z-10 mt-6 w-32 origin-top-right rounded-md bg-modal-menu-bg  py-2 shadow-lg ring-1 ring-text-bg/5 focus:outline-none">
                                                    <Menu.Item key="delete_event">

                                                        <button className="block px-3 py-1 text-sm leading-6 text-text-bg hover:text-color-danger"
                                                                onClick={() =>
                                                                { if (window.confirm('Are you sure you wish to delete this item?'))
                                                                    deleteEvent() } }
                                                        >
                                                            Delete event
                                                        </button>

                                                    </Menu.Item>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                        <div className="mt-3 text-center sm:mt-5">
                                            <div className=" text-text-bg  font-semibold dialog-title items-center flex-1 flex-col justify-between ">
                                                <div className="mt-2">
                                                    <div className="mt-2 flex flex-1 items-center justify-evenly">
                                                        <input
                                                            id="title"
                                                            name="title"
                                                            type="text"
                                                            autoComplete="text"
                                                            placeholder="Event summary"
                                                            value={title}
                                                            onChange={(e) => setTitle(e.target.value)}
                                                            required
                                                            autoFocus={true}
                                                            className="flex-1 mr-10 block rounded-md border-0 py-1.5 text-text-important shadow-sm ring-1 bg-input-bg ring-inset ring-card-ring placeholder:text-text-bg/50 focus:ring-inset focus:ring-chip-bg sm:text-sm sm:leading-6"
                                                        />

                                                    </div>
                                                </div>
                                                <div className="mt-4">
                                                    <div className="mt-2 flex flex-1 items-center justify-between">
                                                        <div className="flex min-w-72 mx-9 items-center justify-between">
                                                            <div className="items-center cursor-pointer min-w-40" >
                                                                <div className="flex  items-center cursor-pointer" >
                                                                    <span>Start date</span>
                                                                    <input
                                                                        id="due"
                                                                        name="due"
                                                                        type="date"
                                                                        placeholder={dateStart}
                                                                        onChange={(e) => setDateStart(e.target.value)}
                                                                        value={dateStart}
                                                                        className="items-center block rounded-md border-0 py-1.5 shadow-sm ring-1 bg-input-bg ring-inset ring-card-ring placeholder:text-text-bg/50 focus:ring-inset focus:ring-chip-bg sm:text-sm sm:leading-6"
                                                                    />
                                                                    <div className="flex items-center justify-center">
                                                                        <LuClock
                                                                            className="h-4 w-4 shrink-0 text-text-bg"
                                                                            aria-hidden="true"
                                                                        />
                                                                        <input
                                                                            id="hours"
                                                                            name="hours"
                                                                            type="text"
                                                                            value={hoursStart}
                                                                            onChange={(e) =>handleOnChangeHourStart(e.target.value)}
                                                                            className="block w-10 text-center px-0 py-1 bg-modal-bg placeholder:text-text-bg/50 border-0 focus:ring-0"
                                                                        />
                                                                        <span className="">:</span>
                                                                        <input
                                                                            id="minutes"
                                                                            name="minutes"
                                                                            type="text"
                                                                            onChange={(e) =>handleOnChangeStart(e.target.value)}
                                                                            value={minutesStart}
                                                                            className="block w-10 text-center px-0 py-1 bg-modal-bg placeholder:text-text-bg/50 border-0 focus:ring-0"
                                                                        />
                                                                        {timeformat !== "HH" &&
                                                                            <select name="timeSelectorStart" defaultValue={timeSelectorStart} onChange={(e)=>setTimeSelectorStart(e.target.value)}
                                                                                    className="block text-center px-0 py-1 bg-modal-bg placeholder:text-text-bg/50 border-0 focus:ring-0"
                                                                            >
                                                                                <option value="AM">AM</option>
                                                                                <option value="PM">PM</option>
                                                                            </select>
                                                                        }
                                                                    </div>

                                                                </div>
                                                                <div className="flex  items-center cursor-pointer " >
                                                                    <span>End date</span>
                                                                    <input
                                                                        id="scheduled"
                                                                        name="scheduled"
                                                                        type="date"
                                                                        placeholder={dateEnd}
                                                                        value={dateEnd}
                                                                        onChange={(e) => setDateEnd(e.target.value)}
                                                                        className="items-center block rounded-md border-0 py-1.5 shadow-sm ring-1 bg-input-bg ring-inset ring-card-ring placeholder:text-text-bg/50 focus:ring-inset focus:ring-chip-bg sm:text-sm sm:leading-6"
                                                                    />
                                                                    <div className="flex items-center justify-center">
                                                                        <LuClock
                                                                            className="h-4 w-4 shrink-0 text-text-bg"
                                                                            aria-hidden="true"
                                                                        />
                                                                        <input
                                                                            id="hoursEnd"
                                                                            name="hoursEnd"
                                                                            type="text"
                                                                            value={hoursEnd}
                                                                            onChange={(e) =>handleOnChangeHourEnd(e.target.value)}
                                                                            className="block w-10 text-center px-0 py-1 bg-modal-bg placeholder:text-text-bg/50 border-0 focus:ring-0"
                                                                        />
                                                                        <span className="">:</span>
                                                                        <input
                                                                            id="minutesEnd"
                                                                            name="minutesEnd"
                                                                            type="text"
                                                                            onChange={(e) =>handleOnChangeEnd(e.target.value)}
                                                                            value={minutesEnd}
                                                                            className="block w-10 text-center px-0 py-1 bg-modal-bg placeholder:text-text-bg/50 border-0 focus:ring-0"
                                                                        />
                                                                        {timeformat !== "HH" &&
                                                                            <select name="timeSelectorEnd" defaultValue={timeSelectorEnd} onChange={(e)=>setTimeSelectorEnd(e.target.value)}
                                                                                    className="block text-center px-0 py-1 bg-modal-bg placeholder:text-text-bg/50 border-0 focus:ring-0"
                                                                            >
                                                                                <option value="AM">AM</option>
                                                                                <option value="PM">PM</option>
                                                                            </select>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-2">
                                                    <input
                                                        id="description"
                                                        name="description"
                                                        type="text"
                                                        autoComplete="description"
                                                        placeholder="description"
                                                        value={description}
                                                        onChange={(e) => setDescription(e.target.value)}
                                                        className="block w-full rounded-md border-0 py-1.5 text-text-important shadow-sm ring-1 bg-input-bg ring-inset ring-card-ring placeholder:text-text-bg/50 focus:ring-inset focus:ring-chip-bg sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                                <div className="mt-4 flex flex-1 justify-center items-center">
                                                    <div>
                                                        <button onClick={()=>validateForm()}
                                                                className="block flex-1 px-3 py-1.5 text-m font-semibold leading-6 bg-chip-bg text-chip-text hover:bg-chip-accent rounded-lg outline-none shadow-md hover:text-chip-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-chip-accent">
                                                            Save event
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3"></div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}
