import Layout from "../../../Layout";
import Content from "../../../Content";
import {useContext, useState} from "react";
import { classNames } from "../../../../helpers";
import Messages from "./Messages";
import Notifications from "./Notifications";
import {NotificationContext} from "../../../../../App/utils/Contexts/NotificationContext";

const tabs = [
  { name: "Messages", ref: "0" },
  { name: "Game notifications", ref: "1" },
];
export default function NotificationOverview() {
  const [activeTab, setActiveTab] = useState("0");
  const {notifications} = useContext(NotificationContext)
  const app = notifications.filter((notification) => notification.type !== 'game')
  const game = notifications.filter((notification) => notification.type === 'game')
  const unreadApp = notifications.filter((notification) => notification.type !== 'game' && notification.archived ===false && notification.status === false).length
  const unreadGame = notifications.filter((notification) => notification.type === 'game' && notification.archived ===false && notification.status===false).length
  return (
    <Layout>
      <Content>
        <div>
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            <select
              id="tabs"
              name="tabs"
              className="block w-full rounded-md border-text-bg-inverse py-2 pl-3 pr-10 text-base focus:border-chip-accent focus:outline-none focus:ring-chip-accent sm:text-sm"
              defaultValue={tabs.find((tab) => tab.ref === activeTab)}
              onChange={(tab) => setActiveTab(tab.target.value)}
            >
              {tabs.map((tab) => {
                {tab.name ==="Messages" && unreadApp > 0  &&
                  <option value={tab.ref}>
                    {tab.name} ({unreadApp})
                  </option>
                }
                {tab.name === "Game notifications" && unreadGame > 0 &&
                  <option value={tab.ref}>
                    {tab.name} ({unreadGame})
                  </option>
                }

              })}
            </select>
          </div>
          <div className="hidden sm:block ">
            <div className="border-b border-text-bg-inverse">
              <nav className="ml-32 -mb-px flex space-x-8" aria-label="Tabs">
                {tabs.map((tab) => (
                  <button
                    key={tab.name}
                    className={classNames(
                      tab.ref === activeTab
                        ? "border-chip-bg text-chip-bg"
                        : "border-transparent text-text-bg-inverse hover:border-chip-accent hover:text-chip-accent",
                      "flex whitespace-nowrap border-b-2 py-4 text-sm font-medium",
                    )}
                    onClick={() => setActiveTab(tab.ref)}
                    aria-current={tab.ref === activeTab ? "page" : undefined}
                  >
                    {tab.name}
                    {tab.name ==="Messages" && unreadApp > 0  &&
                        <span className=" ml-1 inline-flex items-center justify-center px-1 py-1 text-xs font-semibold leading-none text-chip-text bg-chip-bg rounded-full">{unreadApp}</span>

                    }
                    {tab.name ==="Game notifications" && unreadGame > 0  &&
                        <span className=" ml-1 inline-flex items-center justify-center px-1 py-1 text-xs font-semibold leading-none text-chip-text bg-chip-bg rounded-full">{unreadGame}</span>
                    }
                  </button>
                ))}
              </nav>
            </div>
          </div>

          {activeTab === "0" && <Messages notifications={app} />}
          {activeTab === "1" && <Notifications notifications ={game} />}
        </div>
      </Content>
    </Layout>
  );
}
